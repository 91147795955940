import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SchoolListItem } from '../../../app/model/school/school-list';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { DataTable } from '../../../components/data-table'
import FormBuilder from '../../../components/form-builder';
import { SchoolDataGridCols } from './data-grid-cols';
import { toast } from 'react-toastify';
import { GET_SCHOOL_CAS_REPORTS, GET_SCHOOL_CAS_REPORT_FILTER } from '../../../app/service/cas-report.service';
import { GET_SCHOOL_SUBSCRIPTION_REPORTS, GET_SCHOOL_SUBSCRIPTION_REPORT_FILTER } from '../../../app/service/subscription.service';

export const SchooolSubscriptionReportList = () => {
    const [schoolData, setSchoolData] = useState<SchoolListItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [cityData, setCityData] = useState<any>([]);
    const [statesData, setStatesData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>({});
    const schoolForm = useRef<any>({});
    const [userRole, setUserRole] = useState<any>();
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [districtData, setDistrictData] = useState<any>([]);
    const [showListPopup, setShowListPopup] = useState<boolean>(false);
    const [loadingList, setLoadingList] = useState(false);
    const [dataList, setDataList] = useState<any>([]);
    const filteredData = [
        { name: 'School Name', value: 'name_of_school' },
        { name: 'UDISE Number', value: 'udise_code' },
        { name: 'Email ID', value: 'email_id' },
        { name: 'Contact Number', value: 'contact_number' },
        { name: 'Category', value: 'category' },
        { name: 'Curriculum', value: 'curriculum' },
        { name: 'Date of Registration', value: 'date_of_registration' },
        { name: 'Taluka', value: 'taluka' },
        { name: 'Pan Number', value: 'pan_number' },
        { name: 'Address', value: 'address' },
        { name: 'Website Url', value: 'website_url' },
        { name: 'Pincode', value: 'pin_code' },
    ];
    const history = useHistory();

    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');

    useEffect(() => {
        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setStatesData(res.states);
            }
        });
        getSchools();
        setUserRole(userRole);

    }, []);


    function getSchools() {
        getSubscriptionSchoolReport();
    }

    const getSubscriptionSchoolReport = () => {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_SCHOOL_SUBSCRIPTION_REPORTS(activePageNumber, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.data;
                    if (data.length > 0) {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        data.forEach((element: any) => {
                            element.school_name = element.name_of_school;
                            delete element['name_of_school'];
                        });
                        setSchoolData(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }

                }
            }
        });
    }

    const onSearchText = (data: any) => {
        if (data.value.search) {
            history.push(`/dashboard/school?search=${data.value.search}`);
        }
    };

    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/subscription-report/school/list?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_SCHOOL_SUBSCRIPTION_REPORTS(pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.data;
                    if (data.length > 0) {

                        data.forEach((element: any) => {
                            element.school_name = element.name_of_school;
                            delete element['name_of_school'];
                        });
                        setSchoolData(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                    }
                    setLoading(false);
                }
            }
        });

    }

    const handleStateChange = (e: any) => {
        if (e.target.value) {
            const preparedData = statesData.filter((el: any) => el.state === e.target.value);
            setDistrictData(preparedData[0].districts);
        }
    }

    const handleDistrictChange = (e: any) => {
    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };

    function handleSearch() {
        const data = { ...searchData.value };

        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_SCHOOL_SUBSCRIPTION_REPORT_FILTER(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    history.push(`/dashboard/subscription-report/school/list?page=${1}`);
                    setActivePage(1);
                    const data = res.data;
                    data.forEach((element: any) => {
                        element.school_name = element.name_of_school;
                        delete element['name_of_school'];
                    });
                    setSchoolData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }

    function handleRefresh() {
        const data = searchData
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getSchools();
        schoolForm.current.reset();
    }

    const handleFilterChange = (e: any) => {
        const data = { ...searchData.value };
        setSearchData(data);
    }
    const handleList = async () => {
        setShowListPopup(true);
        setLoadingList(true);
        setDataList([]);
        try {
            const res: any = await GET_SCHOOL_SUBSCRIPTION_REPORTS(0, {}, 100000);
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                const data = res.data;
                data.forEach((element: any) => {
                    element.school_name = element.name_of_school;
                    delete element['name_of_school'];
                });
                setDataList(data);
            }
            setLoadingList(false);
        } catch (error) {
            setLoadingList(false);

        }
    }
    return (
        <div>
            <div className="border-top border-primary py-3">
                <div
                    className={`modal fade ${showListPopup ? "show" : "false"}`}
                    style={{ display: showListPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog parent-model-dialog" role="document">
                        <div className="modal-content parent-model">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Membership Reports
                                </h5>
                                <button
                                    type="button"
                                    className="close popup-close cursor-pointer"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setShowListPopup(false)}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body parent-info parent-model">
                                {loadingList && (
                                    <div className="text-center p-5">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                {!loadingList && (
                                    <DataTable TableCols={SchoolDataGridCols}
                                        tableData={dataList} pageNumber={onPageChange}
                                        activePageNumber={0} pageNumbers={[1]} requiredPagination="not"></DataTable>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary cursor-pointer"
                                    data-dismiss="modal"
                                    onClick={() => setShowListPopup(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-3 mt-1">
                    <div className="">
                        <h2>Membership Reports</h2>
                    </div>
                    <div className=' text-end'><button className='btn btn-primary me-1 me-sm-4 f14' onClick={handleList}>View All Reports</button>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-10">
                        <FormBuilder onUpdate={handleInput}>
                            <form ref={schoolForm}>
                                <div className="row">
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="state" id="state" className="form-select ft-14" onChange={(e) => { handleStateChange(e) }}>
                                            <option value="">Select state</option>
                                            {statesData.map((name: any, i: number) => (
                                                <option key={i} value={name.state}>
                                                    {name.state}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="district" id="taluka" className="form-select ft-14" onChange={(e) => { handleDistrictChange(e) }}>
                                            <option value="">Select District</option>
                                            {districtData.map((name: any, i: number) => (
                                                <option key={i} value={name}>
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <input type="text" className="form-control ft-14" placeholder="Custom search" name="search" />
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="search_field" className="form-select ft-14" onChange={(e) => { handleFilterChange(e) }}>
                                            <option value="">Select filter</option>
                                            {filteredData.map((name: any, i: number) => (
                                                <option key={i} value={name.value}>
                                                    {name.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </FormBuilder>
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                        <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                            Clear
                        </button>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <DataTable TableCols={SchoolDataGridCols} tableData={schoolData} searchText={onSearchText}
                    pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
            </div>
        </div >
    )
}

