import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { SchoolListItem } from '../../../app/model/school/school-list';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import FormBuilder from '../../../components/form-builder';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from "react-datepicker";
import appConfig from '../../../config/constant';
import { MultiSelect } from 'react-multi-select-component';
import { DateComponent } from '../../../components/date';
import { BULK_UPLOAD_SCHOOL, DELETE_SCHOOL, GET_SCHOOL, GET_School_Filter, GET_SCHOOL_INSTITUTE_ID, GET_SCHOOL_INSTITUTE_ID_FILTER } from '../../../app/service/school.service';
import { SchoolInFormation } from './schoolinfo';
import { Switch, Route } from 'react-router-dom';
import { returnPaginationRange } from '../../../app/utility/appUtils';
import { DataTable } from '../../../components/data-table';
import { ManageSchoolDataGridCols } from './data-grid-cols';


export const ManageSchool = () => {
  const [statesData, setStatesData] = useState<any>([]);
  const [schoolData, setSchoolData] = useState<SchoolListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState<any>();
  const search = useLocation().search;
  const currentPageNumber: any = new URLSearchParams(search).get('page')
  const history = useHistory();
  const instituteCode: any = localStorage.getItem('organization_code');
  const [searchData, setSearchData] = useState<any>({});
  const schoolForm = useRef<any>({});
  const [districtData, setDistrictData] = useState<any>([]);
  const [talukaData, setTalukaData] = useState<any>([]);
  const userRole = localStorage.getItem("user_role");
  const [preparedSearchData, setPreparedSearchData] = useState<any>({});
  const [allStateBlock, setAllStateBlock] = useState<any>({});
  const usersFormname = useRef<any>({});
  const organizationName = localStorage.getItem("organization_name");
  const [canShowDeletePopup, setCanShowDeletePopup] = useState(false);
  const [selectedData, setSelectedData] = useState<any>({});
  const [pageArray, setPageNumbers] = useState([]);
  let totalPage = pageArray.length

  useEffect(() => {
    if (userRole === "INSTITUTE_ADMIN") {
      for (let j = 0; j < ManageSchoolDataGridCols.length; j++) {
        ManageSchoolDataGridCols[j].isDelete = true;
        ManageSchoolDataGridCols[j].canShowColumn = true;
      }
    }
    LOOK_UP_DATA().then((res: any) => {
      if (res) {
        setStatesData(res.states);
      }

    });

    getSchools();
  }, []);

  const arrayOfPages = returnPaginationRange(pageArray.length, activePage, 10, 1)

  function getSchools() {

    setLoading(true);
    GET_SCHOOL_INSTITUTE_ID(instituteCode, 0, {}).then((res: any) => {
      if (res) {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          const data = res['rows'];
          const pageNumbers = Math.ceil(res.count / 10);
          const pageArray: any = [];
          for (let i = 1; i <= pageNumbers; i++) {
            pageArray.push(i);
          }
          setPageNumbers(pageArray);
          setActivePage(data);
          setActivePage(currentPageNumber ? currentPageNumber : 1);
          const strAscending = [...data].sort((a, b) =>
            a.name_of_school > b.name_of_school ? 1 : -1,
          );
          setSchoolData(data);
          setLoading(false);
        }
      }
    });
  }

  const onPageChange = (data: any) => {
    if (data) {
      history.push(`/institute-dashboard/manage-school/list?page=${data}`);

    }
    setActivePage(data);
    const pageNumber = data - 1;
    setLoading(true);
    GET_SCHOOL_INSTITUTE_ID(instituteCode, pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
      if (res) {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          const data = res.rows;
          setSchoolData(data);
          setLoading(false);
        }
      }
    });
  }

  function onClickPage(number: number) {
    let dots: any = "..."

    if (activePage !== 1 && number === dots) {
      setActivePage(1);
      onPageChange(1);

    } else if (activePage !== pageArray.length && number === dots) {
      setActivePage(totalPage - 1);
      onPageChange(totalPage - 1);
    }
    else {
      setActivePage(number);
      onPageChange(number);
    }
  }
  function onClickPreviousPage() {
    setActivePage(activePage - 1);
    onPageChange(activePage - 1);
  }

  function onClickNextPage() {
    setActivePage(activePage + 1);
    onPageChange(activePage + 1);

  }

  const handleInput = (data: any) => {
    setSearchData(data);
  };

  const handleCustomInput = (data: any) => {
    setSearchData(data);
  };

  const handleStateChange = (e: any) => {
    if (e.target.value) {
      const preparedData = statesData.filter((el: any) => el.state === e.target.value);
      // setCityData(preparedData[0].cities);
      setDistrictData(preparedData[0].districts);
      setAllStateBlock(preparedData[0]?.block);
    }
  }

  const handleDistrictChange = (e: any) => {
    setTalukaData([]);
    onLoadBlocks(e.target.value, allStateBlock);
  }

  const onLoadBlocks = (e: any, blocks: any) => {
    Object.keys(blocks).find(key => {
      if (key === e) {
        setTalukaData(blocks[key]);
        return;
      }
    });
  }

  function handleSearch() {
    const data = { ...searchData.value };

    if (data.searchFilter === 'udise_code') {
      data.udise_code = data.search_name;
    } else if (data.searchFilter === 'contact_number') {
      data.contact_number = data.search_name;
    } else if (data.searchFilter === 'category') {
      data.category = data.search_name;
    } else if (data.searchFilter === 'taluka') {
      data.taluka = data.search_name;
    } else if (data.searchFilter === 'curriculum') {
      data.curriculum = data.search_name;
    } else if (data.searchFilter === 'email_id') {
      data.email_id = data.search_name;
    } else if (data.searchFilter === 'date_of_registration') {
      const date_of_registration = data.search_name;
      data.date_of_registration = getPreapareDate(date_of_registration);
    }

    else if (data.searchFilter === 'address') {
      data.address = data.search_name;
    } else if (data.searchFilter === 'website_url') {
      data.website_url = data.search_name;
    }

    else if (data.searchFilter === 'pin_code') {
      data.pin_code = data.search_name;
    } else if (data.searchFilter === 'search') {
      data.search = data.search_name;
    }
    delete data.searchFilter;
    if (data) {
      setLoading(true);
      for (const key of Object.keys(data)) {
        if (data[key] === "") {
          delete data[key];
        }
      }
      setPreparedSearchData(data);
      history.push(`/institute-dashboard/manage-school/list?page=${1}`);
      setActivePage(1);
      GET_SCHOOL_INSTITUTE_ID_FILTER(instituteCode, 0, data).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          const data = res.rows;
          setSchoolData(data);
          const pageNumbers = Math.ceil(res.count / 10);
          const pageArray: any = [];
          for (let i = 1; i <= pageNumbers; i++) {
            pageArray.push(i);
          }
          setPageNumbers(pageArray);
          setActivePage(data)
          setActivePage(currentPageNumber ? currentPageNumber : 1);
          setLoading(false);
        }
      });
    }
  }
  function getPreapareDate(data: any) {
    if (data) {
      const preparedDate = data.split('-');
      const date = preparedDate[0];
      const month = preparedDate[1];
      const year = preparedDate[2];
      return year + '-' + month + '-' + date + 'T00:00:00Z';
    }
  }
  function handleRefresh() {
    const data = searchData;
    data.value = {};
    setSearchData({ ...data });
    setPreparedSearchData({});
    getSchools();
    schoolForm.current.value = "";
    schoolForm.current.reset();
    usersFormname.current.reset();

  }
  const schoolInfo = (data: any) => {
    localStorage.setItem("openSchoolId", data.id);
    history.push(`/institute-dashboard/manage-school/list/info/${data.id}/teacherslist/${data.school_code}`);

  }
  const onClickDoubbleArrows = (arrow: any) => {
    if (arrow === "&raquo;") {
      setActivePage(totalPage);
    } else {
      setActivePage(1);
    }
  }
  const editSchool = (data: any) => {
    history.push(`/institute-dashboard/manage-school/list/form/${data.id}`)
  }
  const deleteSchool = (data: any) => {
    setLoading(true);
    const id = data.id;
    DELETE_SCHOOL(id).then(res => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setLoading(false);
      } else {
        setLoading(false);
        toast.success("School Deleted Successfully");
        getSchools();
        setCanShowDeletePopup(false);

      }
    });

  }
  const handleDeleteClose = () => {
    setCanShowDeletePopup(false);
  };
  function onClickDelete(item: any) {
    setCanShowDeletePopup(true);
    setSelectedData(item);

  }

  const onDeleteSchool = (data: any) => {

    setLoading(true);
    const id = data.id;
    DELETE_SCHOOL(id).then(res => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setLoading(false);
      } else {
        setLoading(false);
        toast.success("School Deleted Successfully");
        getSchools();

      }
    });
  }
  const onSearchText = (data: any) => {
    if (data.value.search) {
      history.push(`/institute-dashboard/manage-school?search=${data.value.search}`);
    }
  };

  function handleDownloadTemplate() {
    setLoading(true);
    let dataUrl = "/templates/schools_bulk_upload.xls"
    const url = `${appConfig.FILE_UPLOAD_URL}${dataUrl.charAt(0) === "/" ? dataUrl.substring(1) : dataUrl}`;
    window.location.href = url;
    toast.success("Download School Template Successfully");
    setLoading(false);
  }

  function handleUploadBulkUpload(e: any) {
    if (e.target.files && e.target.files[0]) {
      let formData = new FormData();
      formData.append('file', e.target.files[0], e.target.files[0].name);
      formData.append('organization_code', instituteCode);
      setLoading(true);

      BULK_UPLOAD_SCHOOL(formData).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          setLoading(false);
          toast.success("Bulk Upload Successfully");
          getSchools();
        }
      });
    }
  }

  return (
    <div>
      <div
        className={`modal fade ${canShowDeletePopup ? "show" : "false"}`}
        style={{ display: canShowDeletePopup ? "block" : "none" }}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete
              </h5>
              <button
                type="button"
                className="close popup-close cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleDeleteClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row py-3">
              <div className="col-md-12 text-center m">
                <h5>
                  Do you want to delete this{" "}
                  {selectedData?.name_of_school ? selectedData?.name_of_school : ""} record?

                </h5>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary fw-light cursor-pointer"
                onClick={() => deleteSchool(selectedData)}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary cursor-pointer"
                data-dismiss="modal"
                onClick={handleDeleteClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb mx-1">
        <ol className="breadcrumb pb-2 pt-0 ps-1 border-bottom mb-3">
          <li className="breadcrumb-item text-primary pe-2">{organizationName}</li> /
          <li className="breadcrumb-item">Schools</li>
        </ol>
      </nav>
      <div className="accordion mb-3" id="accordionExample">
        <div className="accordion-item filter-section-fileds">
          <div className='d-flex justify-content-between'>
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                <i className="filter-icon"></i> Filter
              </button>
            </h2>
            {userRole === "INSTITUTE_ADMIN" && <Link to={`/institute-dashboard/manage-school/list/info/0`} ><button className='me-2 btn btn-primary mt-2 mb-1'>
              Add School
            </button></Link>}
          </div>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <FormBuilder onUpdate={handleInput}>
                <form ref={schoolForm}>
                  <div className="row">
                    <div className="col-md-6 col-lg-3 mb-3 text-start">
                      <label className="form-label">Search Name</label>
                      <input type="text" className="form-control" placeholder="Search by Name" name="name_of_school" id="name" />
                    </div>
                    <div className="col-md-6 col-lg-3 mb-3 text-start">
                      <label className="form-label">State</label>
                      <select name="state" id="state" className="form-select" onChange={(e) => { handleStateChange(e) }}>
                        <option value="">Search by state</option>
                        {statesData.map((name: any, i: number) => (
                          <option key={i} value={name.state}>
                            {name.state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-3 text-start">
                      <label className="form-label">District</label>
                      <select name="district" id="district" className="form-select " onChange={(e) => { handleDistrictChange(e) }}>
                        <option value="">Search by District</option>
                        {districtData.map((name: any, i: number) => (
                          <option key={i} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-md-6 col-lg-3 mb-3 text-start">
                      <label className="form-label">Taluka</label>
                      <select className="form-select" name="taluka" id="taluka">
                        <option value="">Search by Taluka</option>
                        {talukaData.map((name: any, i: number) => (
                          <option key={i} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-3 align-self-end text-start">
                      <button type="button" className="btn btn-primary mt-1" onClick={() => handleSearch()}>Search</button>
                      <button type="button" className="btn btn-link" onClick={() => handleRefresh()}>Cancel</button>
                    </div>
                  </div>
                </form>
              </FormBuilder>

            </div>
          </div>
        </div>
      </div>

      <div className="mb-3 filter-search">
        <FormBuilder onUpdate={handleCustomInput}>
          <form ref={usersFormname}>
            <input type="text" className="form-control" placeholder="Custom Search / by taluka, district, sate" name="search" />
          </form>
        </FormBuilder>
        <button className="btn btn-primary" type="button" onClick={() => handleSearch()}>Search</button>
      </div>
      {(userRole === "SUPER_ADMIN" || userRole === "INSTITUTE_ADMIN") && <div className='justify-content-end d-flex mb-3'>
        <div>
          <button className="btn btn-sm btn-primary px-4 rounded-12 cursor-pointer" style={{ marginRight: '10px', color: 'white' }} onClick={() => handleDownloadTemplate()}>Download School List Template
          </button>
        </div>
        <div className=" file btn btn-sm btn-primary px-4  rounded-12 d-inline-block" style={{ cursor: 'pointer' }}>Upload School List
          <input type="file" style={{ cursor: 'pointer' }} onChange={(e) => handleUploadBulkUpload(e)} />
        </div>
      </div>}
      {loading &&
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      {!loading && <DataTable TableCols={ManageSchoolDataGridCols} tableData={schoolData} searchText={onSearchText}
        deleteInfo={onDeleteSchool} type={"classic"}
        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
    </div>
  )
}
