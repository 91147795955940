import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { DataTable } from '../../../components/data-table';
import { PaymentDataGridCols } from './data-grid-cols'
import { ToastContainer, toast } from 'react-toastify';
import { GET_PAYMENT } from '../../../app/service/payment-service';
import { GET_USER_USER_CODE } from '../../../app/service/users.service';
import { GET_SCHOOL_BY_SCHOOL_CODE } from '../../../app/service/school.service';
import moment from 'moment';
import appConfig from '../../../config/constant';
import FormBuilder from '../../../components/form-builder';

export const PaymentsList = () => {
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [rolesList, setPaymentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState(false);
    const [schoolData, setSchoolData] = useState<any>({});
    const [usersData, setUsersData] = useState<any>([]);
    const search = useLocation().search;
    let currentPageNumber: any = new URLSearchParams(search).get('page');
    const history = useHistory();
    const [showListPopup, setShowListPopup] = useState<boolean>(false);
    const [loadingList, setLoadingList] = useState(false);
    const [dataList, setDataList] = useState<any>([]);
    const [excelLoading, setExcelLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [dowonLoading, setDowonLoading] = useState<boolean>(false);
    const [schoolName, setSchoolName] = useState<string>("");
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");
    const [searchData, setSearchData] = useState({});
    useEffect(() => {
        getPayments();

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        handlePaymentsCols(JSON.parse(accessObjectsData));
    }, []);

    function getPayments() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_PAYMENT(activePageNumber, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.rows;

                    for (let i = 0; i < data.length; i++) {
                        if (Array.isArray(data[i].user_ids)) {
                            data[i].user_ids_count = data[i].user_ids.length;
                        } else {
                            data[i].user_ids_count = 1
                        }
                        data[i].payment_school_name = data[i].name_of_school;
                        data[i].payment_status = data[i].status === 1 ? 'completed' : 'failed';
                        if (data[i].user_ids.length > 0) {
                            data[i].user_ids = data[i].user_ids.toString();
                        }
                        if (data[i].created_at) {
                            data[i].created_at = moment(data[i].created_at.split("T")[0]).format('DD-MM-yyyy');

                        }
                    }
                    setPaymentList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }


    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/payments?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_PAYMENT(pageNumber, searchData ? searchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    for (let i = 0; i < data.length; i++) {
                        if (Array.isArray(data[i].user_ids)) {
                            data[i].user_ids_count = data[i].user_ids.length;
                        } else {
                            data[i].user_ids_count = 1
                        }
                        data[i].payment_school_name = data[i].name_of_school;
                        data[i].payment_status = data[i].status === 1 ? 'completed' : 'failed';
                        if (data[i].created_at) {
                            data[i].created_at = moment(data[i].created_at.split("T")[0]).format('DD-MM-yyyy');

                        }
                    }
                    setPaymentList(data);
                    setLoading(false);
                }

            }
        });
    }

    const handlePaymentClose = () => {
        setCanShowPaymentPopup(false);
    }

    const getPaymentDetails = (data: any) => {
        setCanShowPaymentPopup(true);
        getSchoolDetails(data);
        getUserDetails(data);

    }

    function getSchoolDetails(item: any) {
        setLoading(true);
        GET_SCHOOL_BY_SCHOOL_CODE(item.school_code).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res.rows.length > 0) {
                    const data = res.rows[0];
                    setSchoolData(data);
                }
                setLoading(false);
            }
        });
    }

    function getUserDetails(item: any) {
        setUsersData([]);
        const userIds = item.user_ids.toString();
        setLoading(true);
        GET_USER_USER_CODE(userIds).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res.rows.length > 0) {
                    const data = res.rows;
                    setUsersData(data);
                }
                setLoading(false);
            }
        });
    }

    const handlePaymentsCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "PAYMENT");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < PaymentDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "PAYMENT-VIEW-MORE" && preparedData[0].child[i].p_read === 1) {
                        if (PaymentDataGridCols[j].title == "Actions") {
                            PaymentDataGridCols[j].viewMore = true;
                            PaymentDataGridCols[j].canShowColumn = true;

                        }

                    }


                }
            }
        }
    }
    const handleList = async () => {
        setShowListPopup(true);
        setLoadingList(true);
        setDataList([]);
        try {
            const res: any = await GET_PAYMENT(0, {}, 100000);
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                const data = res?.rows;
                for (let i = 0; i < data.length; i++) {
                    if (Array.isArray(data[i].user_ids)) {
                        data[i].user_ids_count = data[i].user_ids.length;
                    } else {
                        data[i].user_ids_count = 1
                    }
                    data[i].payment_school_name = data[i].name_of_school;
                    data[i].payment_status = data[i].status === 1 ? 'completed' : 'failed';
                    if (data[i].user_ids.length > 0) {
                        data[i].user_ids = data[i].user_ids.toString();
                    }
                    if (data[i].created_at) {
                        data[i].created_at = moment(data[i].created_at.split("T")[0]).format('DD-MM-yyyy');

                    }
                }

                setDataList(data);
            }
            setLoadingList(false);
        } catch (error) {
            setLoadingList(false);

        }
    }
    const handlerDownloadUsers = () => {
        setDowonLoading(!dowonLoading);
    }
    const onClickExport = async (exel: boolean, pdf: boolean) => {
        const payLoad = {
            download_excel: exel,
            download_pdf: pdf
        }
        setExcelLoading(exel);
        setPdfLoading(pdf);
        try {
            const res: any = await GET_PAYMENT(0, payLoad, 100000);
            if (res) {
                if (res.status === "fail") {
                    toast.error(res.message);
                } else {
                    const data = res;
                    const preUrl = data?.url?.url;
                    if (preUrl) {
                        const url = `${appConfig.FILE_UPLOAD_URL}${preUrl.charAt(0) === "/"
                            ? data.url.substring(1)
                            : preUrl
                            }`;
                        if (pdf) {
                            window.open(url, '_blank');
                        } else {
                            window.location.href = url;
                        }
                        toast.success(res?.message);
                    }
                }
            }
            setExcelLoading(false);
            setPdfLoading(false);
        } catch (error) {
            setExcelLoading(false);
            setPdfLoading(false);
        }

    };
    function handleSearch() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        const payload = {
            school_name: schoolName,
            from_dt: fromDate ? `${fromDate} 00:00:00` : "",
            to_dt: toDate ? `${toDate} 23:59:59` : ""
        }
        if (schoolName || fromDate || toDate) {
            setSearchData(payload);
            setLoading(true);
            GET_PAYMENT(0, payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(1);
                        const data = res.rows;
                        for (let i = 0; i < data.length; i++) {
                            if (Array.isArray(data[i].user_ids)) {
                                data[i].user_ids_count = data[i].user_ids.length;
                            } else {
                                data[i].user_ids_count = 1
                            }
                            data[i].payment_school_name = data[i].name_of_school;
                            data[i].payment_status = data[i].status === 1 ? 'completed' : 'failed';
                            if (data[i].user_ids.length > 0) {
                                data[i].user_ids = data[i].user_ids.toString();
                            }
                            if (data[i].created_at) {
                                data[i].created_at = moment(data[i].created_at.split("T")[0]).format('DD-MM-yyyy');

                            }
                        }
                        setPaymentList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }

    }
    function handleRefresh() {
        setActivePage(1);
        setSearchData({});
        currentPageNumber = 0;
        getPayments();
        setSchoolName("");
        setFromDate("");
        setToDate("");
    }
    return (
        <div className="row border-top border-primary py-3">
            <div
                className={`modal fade ${showListPopup ? "show" : "false"}`}
                style={{ display: showListPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Payments
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setShowListPopup(false)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            {loadingList && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!loadingList && (
                                <DataTable TableCols={PaymentDataGridCols}
                                    tableData={dataList} pageNumber={onPageChange}
                                    activePageNumber={0} pageNumbers={[1]} requiredPagination="not"></DataTable>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={() => setShowListPopup(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${canShowPaymentPopup ? 'show' : 'false'}`} style={{ display: (canShowPaymentPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Details</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handlePaymentClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loading && <div>
                                <h3>School Details</h3>
                                <div className="mt-3 custom-scroll table-responsive">
                                    <table className="table fixed-table-header mb-0">
                                        <thead>
                                            <tr>
                                                <th>School Name</th>
                                                <th>UDISE Number</th>
                                                <th>Contact Number</th>
                                                <th>Official Email</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Date of Registration</th>
                                            </tr>
                                        </thead>
                                        {schoolData && <tbody>
                                            <tr>
                                                <td>{schoolData?.name_of_school}</td>
                                                <td>{schoolData?.udise_code}</td>
                                                <td>{schoolData?.contact_number}</td>
                                                <td>{schoolData?.email_id}</td>
                                                <td>{schoolData?.state}</td>
                                                <td>{schoolData?.city_village}</td>
                                                <td>{schoolData?.date_of_registration}</td>
                                            </tr>
                                        </tbody>}
                                    </table>
                                </div>

                                <h3 className='mt-3'>User Details</h3>
                                <div className="custom-scroll table-responsive">
                                    <table className="table fixed-table-header mb-0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>User Name</th>
                                                <th>Email</th>
                                                <th>Mobile Number</th>
                                                <th>Standard</th>
                                                <th>Division</th>
                                                <th>City</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersData.map((item: any, i: number) => (
                                                <tr key={i}>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.user_name}</td>
                                                    <td>{item?.email_id}</td>
                                                    <td>{item?.mobile_number}</td>
                                                    <td>{item?.standard}</td>
                                                    <td>{item?.division}</td>
                                                    <td>{item?.city_village}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handlePaymentClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mb-3 mt-1">
                <div className="">
                    <h2>Payments</h2>
                </div>
                <div className='export_btn'><button className='btn btn-primary me-1 me-md-4 f14' onClick={handleList}>View All Payments</button>
                    <button
                        className="export_btn_select f14 "
                        onClick={() => handlerDownloadUsers()}
                    >

                        <span>Export Users List
                            <i className='bi bi-chevron-down ps-3'></i></span>
                        {/* } */}
                    </button>
                    {dowonLoading &&
                        <ul className='export_btn_select_drop_list z_100'>
                            <li className='export_btn-list-view' onClick={() => onClickExport(true, false)}>{excelLoading && (
                                <div className="px-3">
                                    <div className="spinner-border " role="status" style={{ height: "20px", width: "20px" }} >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                                {!excelLoading &&
                                    <span>EXCEL</span>}</li>
                            <li className='export_btn-list-view' onClick={() => onClickExport(false, true)}>{pdfLoading && (
                                <div className="px-2">
                                    <div className="spinner-border " role="status" style={{ height: "20px", width: "20px" }} >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                                {!pdfLoading && <span>PDF</span>}</li>
                        </ul>}
                </div>
            </div>
            <div className="row  mb-4 ">
                <div className="col-md-3">
                    <div className="mt-3">
                        <label className="form-label mb-0">School Name</label>
                        <input type="text" id="name" className="form-control ft-14" placeholder="School name" name="school_name" onChange={(e) => setSchoolName(e.target.value)} value={schoolName} />
                    </div>
                </div>
                <div className="col-md-3 ">
                    <label className="form-label mb-0 mt-3">From Date</label>
                    <input type="date" className='form-control' name="expiry_dt" value={fromDate} onChange={(e) => setFromDate(e.target.value)} onKeyDown={(event) => {
                        event.preventDefault();
                    }} />
                </div>
                <div className="col-md-3">
                    <label className="form-label mb-0 mt-3">To Date</label>
                    <input type="date" className='form-control' name="expiry_dt" value={toDate} onChange={(e) => setToDate(e.target.value)} onKeyDown={(event) => {
                        event.preventDefault();
                    }} />
                </div>
                <div className="col-md-3  text-end text-md-start pt-4">
                    <button className="btn btn-primary px-3 cursor-pointer mt-3" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer mt-3" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={PaymentDataGridCols}
                onChange={getPaymentDetails}
                tableData={rolesList}
                activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
        </div>
    )
}
