import React, { useEffect, useState } from "react";

interface TestProps {
    testAreas: any;
    resultInfo: any;
    onClickOnStudents: (studentData: any) => void;
}


const ParikshaSubjectAnalysisComponent: React.FC<TestProps> = (props: TestProps) => {
    const testAreas: any[] = props?.testAreas?.length > 0 ? props?.testAreas : [];
    const [testLoading, setTestLoading] = useState<boolean>(false);
    const resultInfo: any = props?.resultInfo;
    const [resultData, setResultData] = useState<any>([]);
    const [selectedTest, setSelectedTest] = useState("");
    const [activeCardIndex, setActiveCardIndex] = useState<number | null>(null);

    useEffect(() => {

    }, []);


    const handleTestClick = (index: number, testType: any) => {
        setActiveCardIndex(index === activeCardIndex ? null : index);
        setSelectedTest(testType);
        let prepardResult = resultInfo;
        switch (testType) {
            case 'weak':
                setResultData(prepardResult?.weak_area);
                break;
            case 'average':
                setResultData(prepardResult?.average_area);
                break;
            case 'good':
                setResultData(prepardResult?.good_area);
                break;
            case 'best':
                setResultData(prepardResult?.best_area);
                break;
            case 'slowest':
                setResultData(prepardResult?.slowest_area);
                break;
            case 'fastest':
                setResultData(prepardResult?.fastest_area);
                break;
            default:
                setResultData([]);
        }
        setTestLoading(true);
        setTimeout(() => {
            setTestLoading(false);
        }, 500);
    };

    const getTriangleColor = () => {
        // Return the class name for the triangle based on the selected test
        switch (selectedTest) {
            case 'weak':
                return 'triangle-weak'; // Use orange color for allocated
            case 'average':
                return 'triangle-avg'; // Use green color for attempted
            // Add cases for other test types if needed
            case 'good':
                return 'triangle-gd';
            case 'best':
                return 'triangle-bt';
            case 'slowest':
                return 'triangle-sw';
            case 'fastest':
                return 'triangle-ft';
            default:
                return ''; // Default color or class
        }
    }

    const getBtnColor = (type: string) => {
        if (type === "bg") {
            switch (selectedTest) {
                case 'weak':
                    return '#c00000';
                case 'average':
                    return '#f29f05';
                case 'good':
                    return '#80b26b';
                case 'best':
                    return '#b6d93b';
                case 'slowest':
                    return '#2f5597';
                case 'fastest':
                    return '#ff0000';
                default:
                    return '';
            }
        }
        else if (type === "text") {
            switch (selectedTest) {
                case 'weak':
                    return 'Weak Area';
                case 'average':
                    return 'Average Area';
                case 'good':
                    return 'Good Area';
                case 'best':
                    return 'Best Area';
                case 'slowest':
                    return 'Slowest Area';
                case 'fastest':
                    return 'Fastest Area';
                default:
                    return '';
            }
        }
    }
    const getRangeValues = () => {
        switch (selectedTest) {
            case 'weak':
                return ' 50 and below 50';
            case 'average':
                return 'in between 51 and 74';
            case 'good':
                return 'in between 75 and 89';
            case 'best':
                return 'in between 90 to 100';
            default:
                return '';
        }
    }
    const handleStudentData = (data: any) => {
        if (data?.length > 0) {
            props.onClickOnStudents(data);
        }
    };
    return (
        <div className={`mb-5 position-relative test_area text-center  mt-5`}>
            {testAreas?.map((test: any, i: number) => (
                <>
                    <div key={i} className={`mt-4 area_bx  ${selectedTest === test?.name ? 'selected' : ''}`} >
                        <div className="sg_bd cursor-pointer" onClick={(event) => handleTestClick(i, test?.name)}>
                            <img src={test?.img_src} className={` ${test?.bg_color === "ft" ? "analysis_ft_area" : "analysis_area"}`} alt={`${test} area`} />
                        </div>
                        <p className={`mb-0 cursor-pointer f16 ${test?.bg_color}_bg text-white`} onClick={(event) => handleTestClick(i, test?.name)}>{test?.name?.charAt(0).toUpperCase() + test?.name.slice(1)} <br />Area</p>
                        <h5 className={`mt-4 ${test?.bg_color}_text fw-bold f22`} onClick={(event) => handleTestClick(i, test?.name)}>{resultInfo?.[`${test?.name}_area_percentage`]?.toFixed()}%</h5>
                        {(selectedTest === test?.name && activeCardIndex !== null) && <div className={`triangle_analysis ms-3 ${getTriangleColor()}`} ></div>}
                        {(selectedTest === test?.name && activeCardIndex !== null) &&
                            <div className="test_popup-table-container" >
                                <div className="bg-white border border-2 px-3 pb-3  pb-lg-5  px-lg-3  mx-md-3 mb-5">
                                    <div className='text-center mt-5'><button className='test_sm_btn mb-2' style={{ backgroundColor: getBtnColor("bg") }}>{getBtnColor("text")}
                                    </button>
                                        {getRangeValues() && <p className="mb-0">( It includes the topic of the test in which you have scored  {getRangeValues()} )</p>}
                                        {selectedTest === 'slowest' && <p className="mb-0">( It includes of the test in which you have utilize the more time than average time for a question )</p>}
                                        {selectedTest === 'fastest' && <p className="mb-0">( It includes of the test in which you have utilize the less time than average time for a question )</p>}
                                    </div>
                                    {testLoading &&
                                        <div className="text-center p-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                    {!testLoading &&
                                        <div>
                                            <div className="row align-items-center  px-2 ">
                                                <div className="col-md-12 col-12 ps-lg-3">
                                                    <div>
                                                        <div className="mt-3 custom-scroll table-responsive ">
                                                            <table className="table ability-table mb-0">
                                                                <thead>
                                                                    <tr className='ability-topic-header'>
                                                                        <th>Chapter Name</th>
                                                                        <th >Topic Name</th>
                                                                        <th className='text-center'>Number of Students</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {resultData?.map((item: any, i: number) => (
                                                                        <React.Fragment key={i}>
                                                                            <tr>
                                                                                <td >{item?.chapter_name}</td>
                                                                                <td >{item?.topic_name}</td>
                                                                                <td className={`text-center ${item?.students?.length > 0 && "active cursor-pointer"}`} onClick={() => handleStudentData(item?.students)}>{item?.number_of_students}</td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {resultData?.length == 0 && <p className='text-center f16 mt-2'>No Data Available</p>}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>}
                                </div>
                            </div>}
                    </div>
                    {i > 3 && <div className="analysis_vl mt-4 d-none d-lg-block mt-5 "></div>}
                </>))}
        </div>
    )
}
export default ParikshaSubjectAnalysisComponent;