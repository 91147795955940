import { DataTableCol } from "../../../components/data-table/types";

export const PaymentDataGridCols: DataTableCol[] = [
  {
    title: "Transaction Id",
    control: "txn_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Dated",
    control: "created_at",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "School Name",
    control: "payment_school_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "UDISE Number",
    control: "udise_code",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Status",
    control: "payment_status",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "User Count",
    control: "user_ids_count",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Amount",
    control: "amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "payment_info",
    isEdit: false,
    isDelete: false,
    canShowColumn: false,
    viewMore: false,
  },
];
