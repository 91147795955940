import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ADD_UPDATE_SUBSCRIPTION_CONFIGS, GET_STUDENT_SUBSCRIPTION_PRICE_LIST } from "../../../app/service/subscription.service";
import { DataTable } from '../../../components/data-table'
import { SubscriptionGridCols } from "./data-grid-cols";
import FormBuilder from "../../../components/form-builder";

function SubScription() {
    const [loading, setLoading] = useState(false);
    const [subsriptionList, setSubscriptionList] = useState<any>([]);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [showViewDetails, setShowViewDetails] = useState<boolean>(false);
    const [activePage, setActivePage] = useState(1);
    const [pageArray, setPageNumbers] = useState([]);
    const [currentData, setCurrentData] = useState<any>({});
    const search = useLocation().search;
    const subscriptionForm = useRef<any>({});
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [pricePerDay, setPricePerDay] = useState<number | string>('');
    const [multiYears, setMultiYears] = useState<string>('');
    const [annualPrice, setAnnualPrice] = useState<number>(0);
    const [discountPercentage, setDiscountPercentage] = useState<string>('');
    const [discountPricePerDay, setDiscountPricePerDay] = useState<number>(0);
    const [discountAmount, setDiscountAmount] = useState<number>(0);
    const [notes, setNotes] = useState<any>([]);
    const [currentNote, setCurrentNote] = useState<string>("");
    const [isEditing, setIsEditing] = useState<number | null>(null);
    const [newNote, setNewNote] = useState<string>("");
    const [showNotesPopup, setShowNotesPopup] = useState<boolean>(false);
    const [noteIndex, setNoteIndex] = useState<number>(0);
    const numbers = [];
    for (let i = 0; i <= 70; i++) {
        numbers.push(i);
    }
    useEffect(() => {

        getSubscriptionList();
    }, [])

    function getSubscriptionList() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_STUDENT_SUBSCRIPTION_PRICE_LIST("", activePageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res?.rows?.filter((data: any) => data?.code === "ANNUAL_MEMBERSHIP_AMOUNT");
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    data.forEach((element: any) => {
                        element.name = capitalizeWords(element?.name);
                        element.membership_amount = element?.data?.membership_amount;
                        element.price_per_day = (element?.data?.membership_amount / 365);
                        element.notes = element?.data?.notes;
                        element.no_of_years = element?.data?.no_of_years;
                        element.discount_percentage = element?.data?.discount_percentage;
                        element.final_price = element?.data?.membership_amount * (100 - parseInt(element?.data?.discount_percentage)) / 100;
                        element.discount_price_per_day = (element?.final_price / 365);
                    });
                    setSubscriptionList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }


    const onPageChange = (data: any) => {


    }

    const handleInput = (data: any) => {
        data.value = { ...data.value };
    }

    const onEditPrice = (data: any) => {
        setPricePerDay(data?.price_per_day);
        setAnnualPrice(data?.membership_amount);
        setDiscountPercentage(data?.discount_percentage);
        setDiscountPricePerDay(data?.discount_price_per_day);
        setDiscountAmount(data?.final_price);
        setNotes(data?.notes);
        setMultiYears(data?.no_of_years);
        setShowViewDetails(false);
        setCanShowPopup(true);
        setCurrentData(data);
    }
    const viewDetails = (data: any) => {
        setPricePerDay(data?.price_per_day);
        setAnnualPrice(data?.membership_amount);
        setDiscountPercentage(data?.discount_percentage);
        setDiscountPricePerDay(data?.discount_price_per_day);
        setDiscountAmount(data?.final_price);
        setNotes(data?.notes);
        setMultiYears(data?.no_of_years);
        setShowViewDetails(true);
        setCanShowPopup(true);
        setCurrentData(data);
    }
    const handleUpdate = async () => {
        const data = { ...currentData }

        if (annualPrice && (discountPercentage || parseInt(discountPercentage) == 0)) {
            try {
                const payLoad = {
                    name: data?.name,
                    code: data?.code,
                    data: {
                        membership_amount: annualPrice,
                        discount_percentage: discountPercentage,
                        no_of_years: multiYears ? parseInt(multiYears) : 1,
                        notes: notes
                    }
                }
                const res: any = await ADD_UPDATE_SUBSCRIPTION_CONFIGS(payLoad);
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    handleclose();
                }
            } catch (error) {
                console.error('Error fetching parent details:', error);
            }
        }

    }

    const handleclose = () => {
        setShowViewDetails(false);
        setCanShowPopup(false);
        clearStates();
        subscriptionForm.current.reset();
        getSubscriptionList()

    }
    const capitalizeWords = (name: string): string => {
        return name
            .split('_') // Split the string by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ');
    };

    const calculateValues = (price: number, discount: number) => {
        const annual = price * 365;
        setAnnualPrice(annual);

        if (discount > 0) {
            const discountedPricePerDay = price - (price * discount) / 100;
            setDiscountPricePerDay(discountedPricePerDay);

            // const discountAmt = annual - discountedPricePerDay * 365;
            console.log(price);

            const discountAmt = annual * (100 - discount) / 100;

            setDiscountAmount(discountAmt);
        } else {
            setDiscountPricePerDay(0);
            setDiscountAmount(0);
        }
    };

    const handlePricePerDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Ensure only numbers are entered
        setPricePerDay(value);
        if (value) {
            calculateValues(parseFloat(value), parseFloat(discountPercentage || '0'));
        } else {
            setAnnualPrice(0);
            setDiscountPricePerDay(0);
            setDiscountAmount(0);
        }
    };
    const handleMultiYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Ensure only numbers are entered
        setMultiYears(value);
    }

    const handleDiscountChanges = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value; // Get the selected value from the dropdown
        setDiscountPercentage(value);

        if (pricePerDay && value) {
            calculateValues(parseFloat(pricePerDay as string), parseFloat(value));
        } else {
            // setDiscountPricePerDay(parseFloat(pricePerDay as string));
            setDiscountAmount(0);
            setDiscountPricePerDay(0);

        }
    };

    const handleCreate = () => {
        if (newNote.trim() !== "") {
            setNotes([...notes, newNote]);
            setNewNote("");
        }
    };

    // Function to handle the edit button click
    const handleEdit = (index: number) => {
        setIsEditing(index);
        setCurrentNote(notes[index]);
    };

    // Function to handle the save after editing
    const handleSave = (index: number) => {
        const updatedNotes = [...notes];
        updatedNotes[index] = currentNote;
        setNotes(updatedNotes);
        setIsEditing(null);
    };

    // Function to handle note change in the input
    const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNote(e.target.value);
    };

    // Function to handle deleting a note
    const handleDelete = (index: any) => {
        const updatedNotes = notes.filter((_: any, i: number) => i !== index);
        setNotes(updatedNotes);
        cancelNote();
    };
    const clearStates = () => {
        setCurrentData({});
        setPricePerDay("");
        setAnnualPrice(0);
        setDiscountPercentage('');
        setDiscountPricePerDay(0);
        setDiscountAmount(0);
        setNotes([]);
        setCurrentNote('');
        setIsEditing(null);
        setNewNote('');
        setMultiYears('');
    }
    const deleteNote = (index: number) => {
        setNoteIndex(index);
        setShowNotesPopup(true);
    }
    const cancelNote = () => {
        setShowNotesPopup(false);
    }

    return (
        <div>

            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content subscription-payment-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{currentData?.name} {!showViewDetails && "Update"}</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div
                                className={`modal fade ${showNotesPopup ? "show" : "false"}`}
                                style={{ display: showNotesPopup ? "block" : "none" }}
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog " role="document">
                                    <div className="modal-content ">
                                        <div className="modal-body  payment_user_popup_info position-relative">
                                            <button type="button" className="close popup-close position-absolute" data-dismiss="modal" aria-label="Close" onClick={cancelNote} style={{ right: "20px" }}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <div className='payment_user_popup_block '>
                                                <div className='mt-1 d-flex justify-content-center'>
                                                    <div className="text-start ">
                                                        <h5 className='f18 payment_mem_text word_break'>{notes[noteIndex]} </h5>
                                                    </div>
                                                </div>
                                                <div className='start-test mt-2 justify-content-center'>
                                                    <h6>Do you want to delete the note ? </h6>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                                        <button className='btn test-btn' onClick={() => handleDelete(noteIndex)}>Yes</button>
                                                        <button className='btn test-btn' onClick={cancelNote}>No</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormBuilder onUpdate={handleInput}>
                                <form ref={subscriptionForm}>
                                    <div className="row ">
                                        <div className="col-md-4 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Name </label>
                                                <input className="form-control form-control-lg f18" type="text" name="name" disabled={true} defaultValue={currentData?.name} style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Price per day <span className="f16 text-danger ps-1">*</span></label>
                                                <input className="form-control form-control-lg" type="text" name="price_per_day" value={pricePerDay} onChange={handlePricePerDayChange} disabled={showViewDetails} style={{ backgroundColor: showViewDetails ? 'hsl(0,0%,96.5%)' : "" }} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Annual Price </label>
                                                <input className="form-control form-control-lg" type="text" name="membership_amount" disabled={true} value={annualPrice} style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Discount (%) <span className="f16 text-danger ps-1">*</span></label>
                                                <select className="form-select form-select-lg" name="discount_percentage" value={discountPercentage} onChange={handleDiscountChanges} disabled={showViewDetails} style={{ backgroundColor: showViewDetails ? 'hsl(0,0%,96.5%)' : "" }} >
                                                    <option value="">Select the percentage</option>
                                                    {numbers?.map((name: any, i: number) => (
                                                        <option key={i} value={name}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Discount Price per day </label>
                                                <input className="form-control form-control-lg" type="text" value={discountPricePerDay?.toFixed(1)} readOnly disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0"> Discounted Membership amount</label>
                                                <input className="form-control form-control-lg" type="text" value={discountAmount?.toFixed(0)} readOnly disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <div>
                                                    <label className="form-label mb-1"> Notes
                                                    </label>
                                                    {!showViewDetails && <div className="d-flex">
                                                        <textarea className="form-control form-control-lg f16" placeholder="Please enter note" id="notes" name="notes" rows={2} cols={30} onChange={(e) => setNewNote(e.target.value)} value={newNote} disabled={showViewDetails} style={{ backgroundColor: showViewDetails ? 'hsl(0,0%,96.5%)' : "" }} />
                                                        <div>
                                                            <button className="btn btn-primary px-2 py-1 f16 mt-3 ms-2 f18" type="button" onClick={handleCreate}>+ </button>
                                                        </div>
                                                    </div>}
                                                </div>
                                                <ol className="ps-3 mt-3">
                                                    {notes?.map((note: any, index: number) => (
                                                        <li key={index}>
                                                            {isEditing === index ? (
                                                                <div className="d-flex">
                                                                    <textarea
                                                                        className="form-control form-control-lg f16"
                                                                        rows={2} cols={30}
                                                                        value={currentNote}
                                                                        onChange={(e: any) => handleNoteChange(e)}
                                                                    />
                                                                    <div>
                                                                        <button className="btn btn-primary px-2 py-1 f16 mt-3 ms-2 f14" type="button" onClick={() => handleSave(index)}>Save</button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="my-1">
                                                                    {note}
                                                                    {!showViewDetails && <>
                                                                        <i className="bi bi-pencil-square cursor-pointer edit-icon p-2 f16" onClick={() => handleEdit(index)}></i>
                                                                        <i className="bi  bi-trash cursor-pointer edit-icon p-2" onClick={() => deleteNote(index)}></i>
                                                                    </>}
                                                                </div>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="col-md-4 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0 mt-1">Year Range ( Multi-year option) </label>
                                                <input className="form-control form-control-lg f20" type="text" name="price_per_day" placeholder="Enter years" value={multiYears} onChange={handleMultiYearChange} disabled={showViewDetails} style={{ backgroundColor: showViewDetails ? 'hsl(0,0%,96.5%)' : "" }} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </FormBuilder>
                        </div>

                        <div className="modal-footer">
                            {!showViewDetails && <button type="button" className="btn btn-primary cursor-pointer" onClick={handleUpdate}>Update</button>}
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>{showViewDetails ? "Close" : "Cancel"}</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={SubscriptionGridCols} tableData={subsriptionList}
                        editInfo={onEditPrice}
                        onChange={viewDetails}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}
        </div>
    )
}
export default SubScription;