import React, { useEffect, useState } from "react";
import appConfig from '../../../config/constant';
import MNSLOGO from '../../../institute-dashboard-assets/images/mns_logo.png'
import ABHYAASLOGO from '../../../institute-dashboard-assets/images/Abyas-logo.svg'
import PARIKSHALOGO from '../../../institute-dashboard-assets/images/Pariksha-logo.svg'
import USERIMG from '../../../institute-dashboard-assets/images/student-img.svg'
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_USER_USER_CODE } from "../../../app/service/users.service";
import { GET_MODULES_BY_SCHOOL_ID } from "../../../app/service/module.service";

function TeacherDashboardView() {
    const [loading, setLoading] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<any>({});
    const UserCode = localStorage.getItem("user_code");
    const history = useHistory();
    const [moduleData, setModuleData] = useState<any>([
        {
            module_code: "MATHS",
        },
        {
            module_code: "ABHYAAS",
        },
        {
            module_code: "PARIKSHA",
        }
    ]);
    useEffect(() => {
        localStorage.setItem("modules_list", JSON.stringify([]));
        fetchAllData();
    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            const userDetails = await getUserData();
            await getModules(userDetails?.institute_school_code);
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    };
    const getUserData = async () => {
        setLoading(true);
        try {
            const response: any = await GET_USER_USER_CODE(UserCode);
            if (response.status === 'fail') {
                toast.error(response.message);
                throw new Error(response.message);
            } else {
                const data = response.rows[0];
                localStorage.setItem("teacher-info", JSON.stringify(data));
                setUserInfo(data);
                return data;

            }
        } catch (error) {
        }

    }
    const getModules = async (schoolCode: any) => {
        try {
            const response: any = await GET_MODULES_BY_SCHOOL_ID(schoolCode)
            if (response.status === 'fail') {
                toast.error(response.message);
                throw new Error(response.message);
            } else {
                const data = response?.rows?.filter((ele: any) => (ele?.module_code !== "CAS-PARENT"));
                localStorage.setItem("modules_list", JSON.stringify(data));
                moduleData?.map((item: any) => {
                    data?.map((ele: any) => {
                        if (ele?.module_code === item?.module_code) {
                            item.module_uuid = ele?.module_uuid
                        }
                    })
                });
            }
        } catch (error) {
        }
    }
    const onClickModule = (selectModule: any) => {
        if (selectModule?.module_uuid) {
            localStorage.setItem("teacher_moduleData", JSON.stringify(selectModule));
            if (selectModule?.module_code === "ABHYAAS") {
                history.push(`/teacher-dashboard/view/abhyaas/teacher-initiatives`);
            } else if (selectModule?.module_code === "PARIKSHA") {
                history.push(`/teacher-dashboard/view/pariksha/teacher-initiatives`);

            }
        }

    }
    const getStandard = (standard: string): number | undefined => {
        switch (standard) {
            case "SIX":
                return 6;
            case "SEVEN":
                return 7;
            case "EIGHT":
                return 8;
            case "NINE":
                return 9;
            case "TEN":
                return 10;
            case "ELEVEN":
                return 11;
            case "TWELVE":
                return 12;
            default:
                return undefined;
        }
    };
    const capitalizeWords = (str: string): string => {
        return str
            .split(' ') // Split the string into an array of words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
            .join(' '); // Join the array back into a string
    };
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item"><span>Dashboard</span></li>
                    </ol>
                </nav>
                <div className="border-bottom pb-3">
                    <div className="d-md-flex sc-profile-details">
                        <div className="mb-3 mb-md-0">
                            <div className="sc-profile-img py-0">
                                <img
                                    src={userInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${userInfo.profile_img.charAt(0) === "/" ? userInfo.profile_img.substring(1) : userInfo.profile_img}` : USERIMG}
                                    alt=""
                                    className="pr-logo"
                                />
                            </div>
                        </div>
                        <div className="sc-profile-text ps-md-4">
                            <h2 className="mt-0 mb-2">{userInfo?.name && userInfo?.name?.charAt(0).toUpperCase() + userInfo?.name?.slice(1)}</h2>
                            <p className="m-0 sc-profile-contact">
                                <span>{userInfo?.mobile_number}</span> {userInfo?.email_id && <span> | </span>} <span>{userInfo?.email_id} </span>
                            </p>
                            <p className="m-0 sc-profile-addrs">
                                {userInfo?.teacher_type === "CLASS_TEACHER" && <span className="f16"> <span>Class Teacher : </span>{userInfo?.class_section_details?.standard}<sup>th</sup>  - <span>{userInfo?.class_section_details?.section}</span></span>}
                            </p>
                            <p className="m-0 sc-profile-addrs">
                                <span className="f16"> <span>Subject Teacher : </span>
                                    {userInfo?.teaching_standards?.map((standard: any, i: number) => (
                                        <>
                                            <span key={i}> {getStandard(standard?.label)}<sup>th</sup> </span> -
                                            {standard?.divisions?.map((division: any, j: number) => (
                                                <span key={j}>{division?.value}{j < standard?.divisions?.length - 1 && ","}</span>
                                            ))}
                                            <span>( {standard?.subjects?.map((subjects: any, z: number) => (
                                                <span key={z}>{subjects?.value && capitalizeWords(subjects?.value)}{z < standard?.subjects.length - 1 && ","} </span>
                                            ))})</span>
                                            <span >{i < userInfo?.teaching_standards?.length - 1 && ","} </span>
                                        </>
                                    ))}
                                    {/* (  <span>{userInfo?.teaching_subjects?.map((subjects: any, z: number) => (
                                        <span key={z}>{subjects?.value}{z < userInfo.teaching_subjects.length - 1 && ","} </span>
                                    ))}</span>) */}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="row mt-5">
                    {moduleData?.map((data: any, i: number) => {
                        return (<div className={`col-12 col-md-6 col-lg-3 mb-3 `} key={i} onClick={() => onClickModule(data)}>
                            <div className={`module-card h-100 d-flex justify-content-between  ${data?.module_uuid ? "cursor-pointer " : " bg-light text-secondary border-secondary"} ${(data?.module_code === "MATHS" || data?.module_code === "PARIKSHA") && "card-two"} ${data?.module_code === "ABHYAAS" && "card-three"}`}>
                                <div>
                                    {(data.module_code === "CAS" || data.module_code === "MATHS") && <h2 className={`module-title mb-0`}>{(data.module_code === "CAS") ? data?.module_code : data?.module_code?.charAt(0).toUpperCase() + data?.module_code?.slice(1).toLowerCase()}</h2>}
                                    {data.module_code === "ABHYAAS" && <h2 className={`module-title mb-0`}>Abhyaas</h2>}
                                    {data?.module_code === "PARIKSHA" && <h2 className={`module-title mb-0`}>Parikshā</h2>}
                                    {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}
                                    {data.module_code === "MATHS" && <h5 className="mb-3">गणित</h5>}
                                    {data.module_code === "ABHYAAS" && <h5 className="mb-3">अभ्यास</h5>}
                                    {data?.module_code === "PARIKSHA" && <h5 className="mb-3">परीक्षा</h5>}
                                </div>
                                <div>
                                    {data.module_code === "MATHS" && <img className="module_icons" src={MNSLOGO} alt="" />}
                                    {data.module_code === "ABHYAAS" && <img className="module_icons" src={ABHYAASLOGO} alt="" />}
                                    {data.module_code === "PARIKSHA" && <img className="module_icons" src={PARIKSHALOGO} alt="" />}
                                </div>
                            </div>
                        </div>)
                    })}
                </div> */}
                <div className="row mt-5">
                    <div className="col-12 ">
                        <div className="module_list_cards d-flex justify-content-center justify-content-md-start">
                            {moduleData?.map((data: any, i: number) => {
                                return (
                                    <div className={`module_card_view d-flex justify-content-between ${data?.module_uuid ? "cursor-pointer " : " bg-light text-secondary border-secondary"} ${(data?.module_code === "MATHS" || data?.module_code === "PARIKSHA") && "cardTwo "} ${data?.module_code === "ABHYAAS" && "cardThree"}`} onClick={() => onClickModule(data)}>
                                        <div>
                                            {(data.module_code === "CAS" || data.module_code === "MATHS") && <h2 className={`module-title mb-0`}>{(data.module_code === "CAS") ? data?.module_code : data?.module_code?.charAt(0).toUpperCase() + data?.module_code?.slice(1).toLowerCase()}</h2>}
                                            {data.module_code === "ABHYAAS" && <h2 className={`module-title mb-0`}>Abhyaas</h2>}
                                            {data?.module_code === "PARIKSHA" && <h2 className={`module-title mb-0`}>Parikshā</h2>}
                                            {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}
                                            {data.module_code === "MATHS" && <h5 className="mb-3">गणित</h5>}
                                            {data.module_code === "ABHYAAS" && <h5 className="mb-3">अभ्यास</h5>}
                                            {data?.module_code === "PARIKSHA" && <h5 className="mb-3">परीक्षा</h5>}
                                        </div>
                                        <div>
                                            {data.module_code === "MATHS" && <img className="module_icons" src={MNSLOGO} alt="" />}
                                            {data.module_code === "ABHYAAS" && <img className="module_icons" src={ABHYAASLOGO} alt="" />}
                                            {data.module_code === "PARIKSHA" && <img className="module_icons" src={PARIKSHALOGO} alt="" />}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>}

        </div>
    )

}
export default TeacherDashboardView;