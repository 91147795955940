import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_USER_USER_CODE } from "../../../../app/service/users.service";
import { GET_SUBJECT_BY_UUID } from "../../../../app/service/abhyas-subject.service";
import AVERAGEAREA from "../../../../institute-dashboard-assets/images/Average area.gif"
import WEAKAREA from "../../../../institute-dashboard-assets/images/Weak area.gif"
import GOODAREA from "../../../../institute-dashboard-assets/images/Good area.gif"
import BESTAREA from "../../../../institute-dashboard-assets/images/Best area.gif"
import LOWAREA from "../../../../institute-dashboard-assets/images/Lowest area.gif"
import FASTAREA from "../../../../institute-dashboard-assets/images/Fastest area.gif"
import ParikshaTestAnalysisComponent from "../../../../components/pariksha/test-analysis";
const TeacherViewStudentParikshaTestAnalysis = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [resultInfo, setResultInfo] = useState<any>({});
    const [subjectData, setSubjectData] = useState<any>({});
    let { moduleUUID, userCode, schoolCode, teacherType } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, teacherType: string }>();
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    const testInfo: any = JSON.parse(localStorage.getItem("pariksha_test_info") || '{}');
    const testData: any = JSON.parse(localStorage.getItem("result-test-data") || '{}');
    const selectTest = localStorage.getItem("select_test");
    const history = useHistory();
    const [studentInfo, setStudentInfo] = useState<any>({});

    const testAreas: any = [
        {
            name: "weak",
            img_src: WEAKAREA,
            bg_color: "wek"
        },
        {
            name: "average",
            img_src: AVERAGEAREA,
            bg_color: "avg"
        },
        {
            name: "good",
            img_src: GOODAREA,
            bg_color: "gd"
        },
        {
            name: "best",
            img_src: BESTAREA,
            bg_color: "bst"
        },
        {
            name: "slowest",
            img_src: LOWAREA,
            bg_color: "sw"

        },
        {
            name: "fastest",
            img_src: FASTAREA,
            bg_color: "ft"

        }
    ]
    useEffect(() => {

        getUserDetails();
        setLoading(true);
        getSubjectInfo();
        setResultInfo(testData);
        setTimeout(() => {
            setLoading(false);
        }, 500);

    }, []);

    function getUserDetails() {
        GET_USER_USER_CODE(userCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.rows[0];
                localStorage.setItem("cc_student_info", JSON.stringify(data));
                setStudentInfo(data);
            }
        });
    }
    const getSubjectInfo = () => {
        GET_SUBJECT_BY_UUID(selectUuids?.subject_uuid).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res.rows.length > 0) {
                    setSubjectData(res.rows[0]);
                }
            }
        });
    }

    const handleBack = () => {
        history.push(`/teacher-dashboard/view/pariksha/summary/${moduleUUID}/${userCode}/${schoolCode}/${testInfo?.board_uuid}/${testInfo?.medium_uuid}/${testInfo?.standard_uuid}/${testInfo?.subject_uuid}/${teacherType}`);
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/teacher-dashboard/view"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to="/teacher-dashboard/view/pariksha/teacher-initiatives">Pariksha</Link></li> /
                            <li className="breadcrumb-item "><Link to={`/teacher-dashboard/view/pariksha/summary/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}/${teacherType}`}><span>{subjectData?.name?.charAt(0).toUpperCase() + subjectData?.name?.slice(1).toLowerCase()}</span></Link></li> /
                            <li className="breadcrumb-item "><span>{selectTest} tests</span></li> /
                            <li className="breadcrumb-item "><span>Analysis</span></li>
                        </ol>
                    </nav>
                    <div className="text-center">
                        <h5 className="mb-0 f16 md_dashboard_txt"><span className="text-inprogess ">{studentInfo?.name?.toUpperCase()}'S</span> PARIKSHA DASHBOARD</h5>
                    </div>
                    <div className='text-end me-5 mb-1'>
                        <button className='btn btn-primary px-4 f14 py-1' onClick={handleBack}>Back</button>
                    </div>
                    <h5 className="text-center mt-3">Test Analysis :{resultInfo?.exam_type && <span className='text-black'> {resultInfo?.exam_type?.split('_')
                        .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                        .join(' ')}   {(resultInfo?.exam_type === "CHAPTER_WISE_TEST") && <span>({resultInfo?.test_details?._chapter?.name})</span>}</span>}</h5>

                    <ParikshaTestAnalysisComponent testAreas={testAreas} resultInfo={resultInfo} />
                </div>}
        </div>
    )
}
export default TeacherViewStudentParikshaTestAnalysis;