import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_SCHOOL_MATHS_REPORTS = (
  pageNumber: number,
  data: any,
  limit?: number
): Promise<any> => {
  return API.get(
    `${apiUrl}/school/module/maths/report?limit=${
      limit ? limit : 10
    }&page=${pageNumber}`,
    { params: data }
  );
};

export const GET_STUDENT_MATHS_REPORTS_BY_SCHOOL_CODE = (
  schoolCode: any,
  pageNumber: number,
  data: any,
  limit?: number
): Promise<any> => {
  return API.get(
    `${apiUrl}/user/module/maths/report?institute_school_code=${schoolCode}&limit=${
      limit ? limit : 10
    }&page=${pageNumber}&fields=user_code,name,email_id,standard,division,mobile_number`,
    { params: data }
  );
};

export const GET_SCHOOL_MATHS_REPORT_FILTER = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/school/module/maths/report?limit=${10}&page=${0}`, {
    params: data,
  });
};

export const GET_STUDENT_MATHS_SEARCH_REPORTS_BY_SCHOOL_CODE = (
  schoolCode: any,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/user/module/maths/report?institute_school_code=${schoolCode}&limit=${10}&page=${0}&fields=user_code,name,email_id,standard,division,mobile_number`,
    { params: data }
  );
};
