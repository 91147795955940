import React, { useState } from "react";

interface Props {
    userInfo: any;

}

const UserTrialTickerMessage: React.FC<Props> = (props: Props) => {
    const [isPaused, setIsPaused] = useState(false);
    const [userInfo, setUserInfo] = useState<any>(props.userInfo);
    const getTrialStatus = () => {
        if (userInfo?.account_type === "TRIAL" && userInfo?.trial_start_dt && userInfo?.trial_end_dt && userInfo?.subscription_status !== "SUBSCRIBED") {
            const trialStartDate = new Date(userInfo?.trial_start_dt);
            const trialEndDate = new Date(userInfo?.trial_end_dt);
            const currentDate = new Date();
            const endEqualsCurrent = currentDate?.toDateString() === trialEndDate?.toDateString();
            const statusDate = currentDate >= trialStartDate && (endEqualsCurrent || currentDate <= trialEndDate);
            if (statusDate) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };
    const getDateCount = () => {
        const trialStartDate = new Date(userInfo?.trial_start_dt);
        const trialEndDate = new Date(userInfo?.trial_end_dt);
        const timeDifference = trialEndDate.getTime() - trialStartDate.getTime();
        return Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;
    }
    const formatDates = (isoString: string): string => {
        const date = new Date(isoString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };
    return (
        <div>
            {getTrialStatus() && <div className="marquee " >
                <span className={isPaused ? 'paused' : ''}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>Your {getDateCount()} {getDateCount() > 1 ? "Days" : "Day"}  trial validity will expire on  {formatDates(userInfo?.trial_end_dt)}</span>
            </div>}
        </div>
    )

}
export default UserTrialTickerMessage;