import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import LogoImg from "../../assets/images/iamsamarth-Logo-Final.svg";
import { toast } from 'react-toastify';
import { StudentsDashBoardRoutes } from "../../routes/student-dashboard/student-dashboard";
// import ScrollToTop from "../../components/scrollTop";
import MOLECULERFORMULA from "../../institute-dashboard-assets/images/molecular-formula.png";
import MATHEMATICS from "../../institute-dashboard-assets/images/mathematics (1).png";
import HEATINGFLASK from "../../institute-dashboard-assets/images/heating-flask.png";
import BOTANYEXPERIMENT from "../../institute-dashboard-assets/images/botany-experiment.png";
import MATHTEST from "../../institute-dashboard-assets/images/maths-test.png"
import MATHHOMEWORK from "../../institute-dashboard-assets/images/maths-homework.png"
import { GET_MODULES_BY_USER_CODES } from "../../app/service/module.service";
import { GET_USER_USER_CODE } from "../../app/service/users.service";
import { GET_MNS_STUDENT_STATUS } from "../../app/service/mns-service";


function StudentDashboardView() {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const history = useHistory();
    const UserCode = localStorage.getItem("user_code");
    const userName = localStorage.getItem("user_name");
    const userRole = localStorage.getItem("user_role");
    const [toggleIcon, setToggleIcon] = useState<any>("nav_toggler");
    const [header, setHeader] = useState<any>("header");
    const [body, setBody] = useState<any>("inside-bg");
    const [nav, setNav] = useState<string>("l-navbar");
    const [icon, setIcon] = useState<string>("bx bx-menus");
    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split("/");
    const [moduleData, setModuleData] = useState<any>([]);
    const [headerName, setHeaderName] = useState<string>("");
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [mnsStatus, setMnsStatus] = useState<boolean>(false);
    const [logotTooltip, setLogoutTooltip] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState(false);
    let studentSchoolCode: any = "";
    const headerMapping: { [key: string]: string } = {
        pariksha: "Pariksha",
        abhyas: "Abhyaas",
        mns: "Maths",
        "test-success": "Maths",
        mla: "Maths",
        "mla-two": "Maths",
        maths: "Maths",
        "cas-test": "CAS",
        "cas-test-instructions": "CAS",
        "cas-success": "CAS",
        reports: "CAS",
    };
    useEffect(() => {
        fetchAllData();
    }, [])
    useEffect(() => {
        getHeaderName();
        // window.scrollTo()
        // window.scrollTo(0, 0);

    }, [splitLocation]);

    const fetchAllData = async () => {
        try {
            const userData: any = await getUserDetails();
            await getModulesByUserCode(UserCode, userData);

        } catch (error) {

        }

    };

    const getModulesByUserCode = async (userCode: any, studentInfo: any) => {
        const res: any = await GET_MODULES_BY_USER_CODES(userCode);
        if (res) {
            if (res.status === "fail") {
                toast.error(res.message);
            } else {

                const data = res?.filter((el: any) => el.module_code !== 'CAS-PARENT' && studentInfo?.subscription_status === "SUBSCRIBED");
                if (Array.isArray(data)) {
                    if (studentInfo?.subscription_status === "SUBSCRIBED") {
                        setModuleData(data);
                    } else if (getTrialStatus(studentInfo)) {
                        const modulesArray = studentInfo?.modules?.split(',');
                        const filteredModules: any = data.filter((el: any) =>
                            modulesArray?.includes(el?.module_code)
                        );
                        setModuleData(filteredModules);
                    } else {
                        setModuleData([]);
                    }
                }
                setModuleData(data);
                let mnsData = data?.find((ele: any) => ele?.module_code === "MATHS");
                if (mnsData) {
                    getMNSStudentStatus(mnsData, studentInfo?.institute_school_code);
                }
            }
        }
    };
    const getUserDetails = async () => {
        try {
            const response: any = await GET_USER_USER_CODE(UserCode)
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                studentSchoolCode = data?.institute_school_code;
                return data;
            }
        } catch (error) {

        }
    }
    const getHeaderName = () => {
        const locationKey = splitLocation[3];
        setHeaderName(headerMapping[locationKey] || "");
    }
    const navToggle = (): void => {
        toggleIcon === "nav_toggler" ? setToggleIcon("nav_toggler toggle") : setToggleIcon("nav_toggler");
        body === "inside-bg" ? setBody("body-pd") : setBody("inside-bg");
        header === "header" ? setHeader("header body-pd") : setHeader("header");
        nav === "l-navbar" ? setNav("l-navbar show") : setNav("l-navbar");
        icon === "bx bx-menus" ? setIcon("bx bx-x") : setIcon("bx bx-menus");

    }



    const handleclose = () => {
        setCanShowPopup(false);
    };

    const handleSubmit = () => {
        setCanShowPopup(false);
        history.push("/home");
        localStorage.clear();
    };

    function onClickHome() {
        setCanShowPopup(true);
    }
    const getNavLink = (moduleData: any) => {
        let moduleCode = moduleData?.module_code;
        switch (moduleCode) {
            case "MATHS":
                if (mnsStatus) {
                    return `/student-dashboard/list/mns/view/${moduleData?.module_uuid}/${moduleData?.user_code}/${studentInfo?.institute_school_code}`;
                } else {
                    return `/student-dashboard/list/mns/test-level/${moduleData.module_uuid}/${moduleData.user_code}/${studentInfo?.institute_school_code}`;
                }
            case "ABHYAAS":
                return `/student-dashboard/list/abhyas/test-level-status/${moduleData.uuid}/${moduleData?.user_details?.user_code}/${studentInfo?.institute_school_code}`;
            case "PARIKSHA":
                return `/student-dashboard/list/pariksha/test-initiative/${moduleData.uuid}/${moduleData?.user_details?.user_code}/${studentInfo?.institute_school_code}`;
            case "CAS":
                return `/student-dashboard/list/cas-test-instructions/${moduleData.user_details['user_code']}/${moduleData.module_code}`;
            default:
                return "";
        }
    }
    function getMNSStudentStatus(data: any, schoolCode: any) {

        let payload = {
            module_uuid: data?.uuid,
            school_code: schoolCode,
            user_code: data?.user_details['user_code']
        }
        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    let status = Array.isArray(data.assesment_test) ? data.assesment_test.length > 0 : Object.keys(data.assesment_test).length !== 0;
                    if (status) {
                        setMnsStatus(true);
                    }
                }
            }
        });
    }
    const getIcons = (moduleCode: string) => {
        switch (moduleCode) {
            case "CAS":
                return "far fa-file-alt";
            case "MATHS":
                return "fa fa-book";
            case "ABHYAAS":
                return "bi bi-book";
            // return "fa fa-fw fa-leanpub";
            case "PARIKSHA":
                return "fa fa-fw fa-building-o";
            default:
                return "";

        }
    }
    const formatDates = (isoString: string): string => {
        const date = new Date(isoString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };
    const getDateCount = () => {
        const trialStartDate = new Date(studentInfo?.trial_start_dt);
        const trialEndDate = new Date(studentInfo?.trial_end_dt);
        const timeDifference = trialEndDate.getTime() - trialStartDate.getTime();
        return Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;
    }
    const getTrialStatus = (studentInfo: any) => {
        if (studentInfo?.account_type === "TRIAL" && studentInfo?.trial_start_dt && studentInfo?.trial_end_dt && studentInfo?.subscription_status !== "SUBSCRIBED") {
            const trialStartDate = new Date(studentInfo?.trial_start_dt);
            const trialEndDate = new Date(studentInfo?.trial_end_dt);
            const currentDate = new Date();
            const endEqualsCurrent = currentDate?.toDateString() === trialEndDate?.toDateString();
            const statusDate = currentDate >= trialStartDate && (endEqualsCurrent || currentDate <= trialEndDate);
            if (statusDate) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };
    return (
        <div>
            {/* <ScrollToTop /> */}

            <body className={body}>
                <div
                    className={`modal fade ${canShowPopup ? "show" : "false"}`}
                    style={{ display: canShowPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Are You Sure Want To Logout
                                </h5>
                                <button
                                    type="button"
                                    className="close popup-close cursor-pointer"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleclose}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary cursor-pointer"
                                    onClick={handleSubmit}
                                >
                                    Yes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary cursor-pointer"
                                    data-dismiss="modal"
                                    onClick={handleclose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`py-2 ${getTrialStatus(studentInfo) && "position-relative"} `}>
                    <header className={`${header}`} id="header">
                        <div className="header_toggle" onClick={navToggle}> <i className={icon} id="header-toggle"></i> </div>
                        <span className="nav_logo mb-0 d-lg-none"><i className="nav_logo-icon"></i></span>
                        <div className="me-md-auto ms-md-3 d-none d-lg-block"> <span className="f18 fw-light">Student Dashboard</span>{headerName && <span><span className="ms-2 me-1">/</span> {headerName}</span>} </div>
                        <div className="position-relative">
                            <div className="d-none d-lg-flex align-items-center ">
                                <span className="text-end user-name pe-3">
                                    {userName}
                                    <br />
                                    <small>{userRole}</small>
                                </span>
                                <a className="mx-3 logout-icon cursor-pointer" onMouseEnter={() => setLogoutTooltip(true)}
                                    onMouseLeave={() => setLogoutTooltip(false)}
                                    onClick={() => {
                                        onClickHome();
                                    }}>Sign out</a>
                            </div>
                            {logotTooltip && <p className="mb-0 position-absolute bg-white  border border-secondary px-1 f14 rounded-2 " style={{ right: "5px" }}>Logout</p>}
                        </div>

                    </header>
                    {getTrialStatus(studentInfo) && <div className="marquee mb-2 position-absolute" style={{ top: "57px", width: "100%" }}>
                        <span className={isPaused ? 'paused' : ''}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>Your {getDateCount()} {getDateCount() > 1 ? "Days" : "Day"}  trial validity will expire on  {formatDates(studentInfo?.trial_end_dt)}</span>
                    </div>}
                    <div className={nav} id="nav-bar">
                        <nav className="navinstitute">
                            <div> <a className="nav_logo mb-3"> <i className="nav_logo-icon"></i> </a>
                                <div className={`nw_nav srl_bar ${nav !== "l-navbar" && "show"} `}>
                                    <div className="nav_list">
                                        <NavLink to='/student-dashboard/list' className="nav_link ps-3 py-1"> <i className="dashboard-icon nav-icon"></i><span className="nav_name">Dashboard</span></NavLink>
                                        {moduleData.length > 0 && moduleData?.map((item: any, i: number) => (
                                            <NavLink to={`${getNavLink(item)}`} className="nav_link ps-3 py-2 " key={i}> <i className={`${getIcons(item?.module_code)} ${item?.module_code === "PARIKSHA" ? "f14" : "sd_icons"}`}></i><span className="nav_name f14">{item?.module_code}</span></NavLink>
                                        ))}

                                        <NavLink to='/student-dashboard/profile/view' className="nav_link ps-3 py-2"> <i className="profile-icon nav-icon"></i><span className="nav_name">Profile</span></NavLink>
                                        {/* <NavLink to='/student-dashboard/settings/view' className="nav_link"> <i className="fa fa-gear f22"></i><span className="nav_name">Settings</span></NavLink> */}

                                    </div>
                                    <div className="d-lg-none nav_link ps-3 py-2" onClick={() => { onClickHome(); }}>
                                        <i className="bx bx-log-out nav-icon "></i>
                                        <span className="nav_name  ">SignOut</span>
                                    </div>
                                    <div className={`${moduleData.length > 2 ? "mt-4 pt-2" : "mt-5"} ${moduleData.length == 0 && "pt-4"} moleculer ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons" src={MOLECULERFORMULA} alt="" />
                                    </div>
                                    <div className={`mathematics ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons my-2" src={MATHEMATICS} alt="" />
                                    </div>
                                    <div className={`moleculer ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons  my-2" src={HEATINGFLASK} alt="" />
                                    </div>
                                    <div className={`mathematics ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons  my-2" src={BOTANYEXPERIMENT} alt="" />
                                    </div>
                                    <div className={`moleculer ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons  my-2" src={MATHTEST} alt="" />
                                    </div>
                                    <div className={`${moduleData.length > 2 ? "pb-2" : "pb-5"}  mathematics ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons  my-2" src={MATHHOMEWORK} alt="" />
                                    </div>

                                </div>
                            </div>

                        </nav>
                    </div>
                    <div className="content-body main-title">
                        <div className="container-fluid">
                            <div className="" id="scroller">

                                <StudentsDashBoardRoutes />
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </div>
    );
}
export default StudentDashboardView;
