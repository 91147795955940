import React from 'react'
import { Route, Switch } from 'react-router'
import { CASQuestionsList } from '../pages/dashboard/modules/cas-questions'
import { ModuleFormPage } from '../pages/dashboard/modules/form'
import { MLAOneInFo } from '../pages/dashboard/modules/mla-one/mla-one-info'
import { MLATwoInFo } from '../pages/dashboard/modules/mla-two/mla-two-info'
import { ModulesMNS } from '../pages/dashboard/modules/mns'
import { AbhyasInfo } from '../pages/dashboard/modules/abhyas/abhyas-info'
import { ParikshaTestInfoList } from '../pages/dashboard/modules/pariksha/test-info-list'

const NestedModuleList = () => {
    return (
        <Switch>
            <Route path="/dashboard/module/info/form/:code/:id" component={ModuleFormPage}></Route>
            <Route path="/dashboard/module/info/questions/:code/:id" component={CASQuestionsList}></Route>
            <Route path="/dashboard/module/info/MATHS/:code/:id" component={ModulesMNS}></Route>
            <Route path="/dashboard/module/info/MLA-1/:code/:id/:name" component={MLAOneInFo}></Route>
            <Route path="/dashboard/module/info/MLA-2/:code/:id/:name" component={MLATwoInFo}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id" component={AbhyasInfo}></Route>
            <Route path="/dashboard/module/info/PARIKSHA/:code/:id/test-info-list" component={ParikshaTestInfoList}></Route>
        </Switch>
    )
}

export default NestedModuleList