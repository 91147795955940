import React, { useEffect, useState } from "react";

interface TestProps {
    testAreas: any;
    resultInfo: any;
    viewAbyhyaasModule?: () => void;
    dashboard?: string;
}


const ParikshaTestAnalysisComponent: React.FC<TestProps> = (props: TestProps) => {
    const testAreas: any[] = props?.testAreas?.length > 0 ? props?.testAreas : [];
    const [testLoading, setTestLoading] = useState<boolean>(false);
    const resultInfo: any = props?.resultInfo;
    const [resultData, setResultData] = useState<any>([]);
    const [selectedTest, setSelectedTest] = useState("");
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [attempted, setAttempted] = useState<number>(0);
    const [totalCorrect, setTotalCorrect] = useState<number>(0);
    const [totalWrong, setTotalWrong] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [totalWorksheets, setTotalWorksheets] = useState<number>(0);
    const [activeCardIndex, setActiveCardIndex] = useState<number | null>(null);

    useEffect(() => {

    }, []);


    const handleTestClick = (index: number, testType: any) => {
        setActiveCardIndex(index === activeCardIndex ? null : index);
        setSelectedTest(testType);
        let prepardResult = resultInfo?.result_analysis;
        switch (testType) {
            case 'weak':
                setResultData(prepardResult?.weak_area);
                totalCountData(prepardResult?.weak_area);
                break;
            case 'average':
                setResultData(prepardResult?.average_area);
                totalCountData(prepardResult?.average_area);
                break;
            case 'good':
                setResultData(prepardResult?.good_area);
                totalCountData(prepardResult?.good_area);
                break;
            case 'best':
                setResultData(prepardResult?.best_area);
                totalCountData(prepardResult?.best_area);
                break;
            case 'slowest':
                setResultData(prepardResult?.slowest_area);
                totalCountData(prepardResult?.slowest_area);
                break;
            case 'fastest':
                setResultData(prepardResult?.fastest_area);
                totalCountData(prepardResult?.fastest_area);
                break;
            default:
                setResultData([]);
        }
        setTestLoading(true);
        setTimeout(() => {
            setTestLoading(false);
        }, 500);
    };
    const totalCountData = (sum: any) => {
        let totalQuestion = 0;
        let totalCorrect = 0;
        let totalWrong = 0;
        let totalWorksheets = 0;
        let score = 0;
        let attempted = 0;
        for (let i = 0; i < sum?.length; i++) {
            totalQuestion += sum[i]?.questions;
            totalCorrect += sum[i]?.correct;
            totalWrong += sum[i]?.wrong;
            totalWorksheets += sum[i]?.worksheets;
            score += sum[i]?.percentage;
            attempted += sum[i]?.attempted;
        }
        setTotalQuestions(totalQuestion);
        setTotalWrong(totalWrong);
        setTotalCorrect(totalCorrect);
        setTotalWorksheets(totalWorksheets);
        setAttempted(attempted);
        let preparedScore = (score / parseInt(sum?.length))
        setScore(preparedScore);
    }
    const getTriangleColor = () => {
        // Return the class name for the triangle based on the selected test
        switch (selectedTest) {
            case 'weak':
                return 'triangle-weak'; // Use orange color for allocated
            case 'average':
                return 'triangle-avg'; // Use green color for attempted
            // Add cases for other test types if needed
            case 'good':
                return 'triangle-gd';
            case 'best':
                return 'triangle-bt';
            case 'slowest':
                return 'triangle-sw';
            case 'fastest':
                return 'triangle-ft';
            default:
                return ''; // Default color or class
        }
    }

    const getBtnColor = (type: string) => {
        if (type === "bg") {
            switch (selectedTest) {
                case 'weak':
                    return '#c00000';
                case 'average':
                    return '#f29f05';
                case 'good':
                    return '#80b26b';
                case 'best':
                    return '#b6d93b';
                case 'slowest':
                    return '#2f5597';
                case 'fastest':
                    return '#ff0000';
                default:
                    return '';
            }
        }
        else if (type === "text") {
            switch (selectedTest) {
                case 'weak':
                    return 'Weak Area';
                case 'average':
                    return 'Average Area';
                case 'good':
                    return 'Good Area';
                case 'best':
                    return 'Best Area';
                case 'slowest':
                    return 'Slowest Area';
                case 'fastest':
                    return 'Fastest Area';
                default:
                    return '';
            }
        }
    }
    const getRangeValues = () => {
        switch (selectedTest) {
            case 'weak':
                return ' 50 and below 50';
            case 'average':
                return 'in between 51 and 74';
            case 'good':
                return 'in between 75 and 89';
            case 'best':
                return 'in between 90 to 100';
            default:
                return '';
        }
    }
    const getWorksheetCount = (resultData: any) => {
        let preData = resultData?.every((item: any) => item?.worksheets == 0)
        return preData
    }
    const viewAbyhyaasModule = () => {
        if (props?.viewAbyhyaasModule) {
            props?.viewAbyhyaasModule();
        }
    }
    return (
        <div className={`mb-5 position-relative test_area text-center  mt-4`}>
            {testAreas?.map((test: any, i: number) => (
                <>
                    <div key={i} className={`mt-4 area_bx cursor-pointer ${selectedTest === test?.name ? 'selected' : ''}`} >
                        <div className="sg_bd" onClick={(event) => handleTestClick(i, test?.name)}>
                            <img src={test?.img_src} className={` ${test?.bg_color === "ft" ? "analysis_ft_area" : "analysis_area"}`} alt={`${test} area`} />
                        </div>
                        <p className={`mb-0 f16 ${test?.bg_color}_bg text-white`} onClick={(event) => handleTestClick(i, test?.name)}>{test?.name?.charAt(0).toUpperCase() + test?.name.slice(1)} <br />Area</p>
                        <h5 className={`mt-4 ${test?.bg_color}_text fw-bold f22`} onClick={(event) => handleTestClick(i, test?.name)}>{resultInfo?.result_analysis?.[`${test?.name}_area_percentage`]?.toFixed()}%</h5>
                        {(selectedTest === test?.name && activeCardIndex !== null) && <div className={`triangle_analysis ms-3 ${getTriangleColor()}`} ></div>}
                        {(selectedTest === test?.name && activeCardIndex !== null) &&
                            <div className="test_popup-table-container" >
                                <div className="bg-white border border-2 px-3 pb-3  pb-lg-5  px-lg-3  mx-md-3 mb-5">
                                    <div className='text-center mt-5'><button className='test_sm_btn mb-2' style={{ backgroundColor: getBtnColor("bg") }}>{getBtnColor("text")}
                                    </button>
                                        {getRangeValues() && <p className="mb-0">( It includes the topic of the test in which you have scored  {getRangeValues()} )</p>}
                                        {selectedTest === 'slowest' && <p className="mb-0">( It includes of the test in which you have utilize the more time than average time for a question )</p>}
                                        {selectedTest === 'fastest' && <p className="mb-0">( It includes of the test in which you have utilize the less time than average time for a question )</p>}
                                    </div>
                                    {testLoading &&
                                        <div className="text-center p-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                    {!testLoading &&
                                        <div>
                                            <div className="row align-items-center  px-2 ">
                                                <div className="col-md-12 col-12 ps-lg-3">
                                                    <div>
                                                        <div className="mt-3 custom-scroll table-responsive ">
                                                            <table className="table ability-table mb-0">
                                                                <thead>
                                                                    <tr className='ability-topic-header'>
                                                                        <th>Topic Name</th>
                                                                        <th className='text-center'>Questions</th>
                                                                        <th className='text-center'>Attempted</th>
                                                                        <th className='text-center'>Correct</th>
                                                                        <th className='text-center'>Wrong</th>
                                                                        <th className='text-center'>Score %</th>
                                                                        <th className='text-center'>Wroksheets</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {resultData?.map((item: any, i: number) => (
                                                                        <React.Fragment key={i}>
                                                                            <tr>
                                                                                <td className={`${item?.worksheets == 0 && "text-completed"}`}>{item?.topic_name}</td>
                                                                                <td className='text-center'>{item?.questions}</td>
                                                                                <td className='text-center'>{item?.attempted}</td>
                                                                                <td className='text-center'>{item?.correct}</td>
                                                                                <td className='text-center'>{item?.wrong}</td>
                                                                                <td className='text-center'>{item?.percentage?.toFixed(0)}</td>
                                                                                <td className='text-center'>{item?.worksheets}</td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    ))}
                                                                    {resultData?.length > 0 && <tr className='border-bottom border-white' >
                                                                        <td colSpan={1} className="text-center text-black fw-bold" scope="col">Total</td>
                                                                        <td className='text-black fw-bold text-center'>{totalQuestions}</td>
                                                                        <td className='text-black fw-bold text-center'>{attempted}</td>
                                                                        <td className='text-black fw-bold text-center'>{totalCorrect}</td>
                                                                        <td className='text-black fw-bold text-center'>{totalWrong}</td>
                                                                        <td className='text-black fw-bold text-center'>{score?.toFixed(0)}</td>
                                                                        <td className='text-black fw-bold text-center'>{(totalWorksheets == 0 || totalWorksheets > 0) && totalWorksheets}</td>
                                                                    </tr>}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {resultData?.length == 0 && <p className='text-center f16 mt-2'>No Data Available</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            {props?.dashboard && <div>
                                                {(resultData?.length > 0 && !getWorksheetCount(resultData)) && <div className="text-end">
                                                    <button className='btn btn-primary f14 mt-4' onClick={viewAbyhyaasModule}>GO FOR ABHYAAS</button>
                                                </div>}
                                            </div>}
                                        </div>}
                                </div>
                            </div>}
                    </div>
                    {i == 3 && <div className="analysis_vl mt-4 d-none d-lg-block mt-5 "></div>}
                </>))}
        </div>
    )
}
export default ParikshaTestAnalysisComponent;