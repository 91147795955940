import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { GET_PARIKSHA_STUDENTS_GRAPH_DATA, GET_PARIKSHA_STUDENTS_SUBJECT_ANALYSIS, GET_STUDENTS_BY_CLASS_TEACHERS } from "../../../../app/service/teacher-dashboard-abhyaas";
import { toast } from "react-toastify";
import AVERAGEAREA from "../../../../institute-dashboard-assets/images/Average area.gif"
import WEAKAREA from "../../../../institute-dashboard-assets/images/Weak area.gif"
import GOODAREA from "../../../../institute-dashboard-assets/images/Good area.gif"
import BESTAREA from "../../../../institute-dashboard-assets/images/Best area.gif"
import ParikshaWorksheetColumnChart from "../../../../components/graphs-charts/pariksha-worksheet-chart";
import ParikshaWorksheetPieChart from "../../../../components/graphs-charts/pariksha-worksheet-pie-chart";
import ParikshaSubjectAnalysisComponent from "../../../../components/pariksha/subject-analysis";
const ParikshaTeacherSubjectReport = () => {
    const subjectInfo: any = JSON.parse(localStorage.getItem("selected_subject_info") || '{}');
    const teacherInfo: any = JSON.parse(localStorage.getItem("teacher-info") || '{}');
    const moduleData: any = JSON.parse(localStorage.getItem("teacher_moduleData") || '{}');
    const [studentsInfo, setSudentsInfo] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [graphSubjects, setGrapgSubjects] = useState<any>([]);
    const [studentsAnalysis, setSudentsAnalysis] = useState<any>({});
    const [subjectsPercent, setSubjectsPercent] = useState<any>([]);
    const [pieChartDataInfo, setPiechartDataInfo] = useState<any>({});
    const history = useHistory();
    const testAreas: any = [
        {
            name: "weak",
            img_src: WEAKAREA,
            bg_color: "wek",
        },
        {
            name: "average",
            img_src: AVERAGEAREA,
            bg_color: "avg",

        },
        {
            name: "good",
            img_src: GOODAREA,
            bg_color: "gd",

        },
        {
            name: "best",
            img_src: BESTAREA,
            bg_color: "bst",

        },

    ];
    const colors = [
        '#156082',
        '#E97132',
        ' #A5A5A5',
        '#00B050', // Green
        '#0070C0', // Blue
        '#F5B7B1', // Light Pink
        '#7D3F98', // Purple
        '#48C9B0', // Teal
        '#F64607', // Red-Orange
        '#F4D03F'  // Gold
    ];
    useEffect(() => {
        fetchData();

    }, []);
    const fetchData = async () => {
        setLoading(true);
        try {
            const studentDetails = await getStudents();
            await getGraphData(studentDetails);
            await getPieChartData(studentDetails?.total_students[0]?.subjects[0]);
            await handleSubject(studentDetails?.total_students[0]?.subjects[0], 0);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }

    };

    const getStudents = async () => {
        try {
            let Payload = {
                teacher_type: "SUBJECT_TEACHER",
                user_code: teacherInfo?.user_code,
                module_uuid: moduleData?.module_uuid,
                subject: subjectInfo?.subject,
                standard: subjectInfo?.standard,
                division: subjectInfo?.division
            }
            const response: any = await GET_STUDENTS_BY_CLASS_TEACHERS(Payload);
            if (response.status === 'fail') {
                toast.error(response?.err);
                setLoading(false);
            } else {
                setSudentsInfo(response);
                return response;

            }
        } catch (error) {

        }
    }
    const getGraphData = async (studentData: any) => {
        const subjectsListArray: any = studentData?.total_students[0]?.subjects?.map((subject: any) => subject.uuid);
        try {
            let Payload = {
                standard: teacherInfo?.class_section_details?.standard,
                section: teacherInfo?.class_section_details?.section,
                school_code: teacherInfo?.institute_school_code,
                module_uuid: moduleData?.module_uuid,
                subjects: subjectsListArray
            }
            const response: any = await GET_PARIKSHA_STUDENTS_GRAPH_DATA(Payload);
            if (response.status === 'fail') {
                toast.error(response?.err);
            } else {
                const percentageKeys = Object.keys(response);
                const subjects = Object.keys(response[percentageKeys[0]]); // Extract subject names from the first percentage key

                let dataArray = subjects.map((subject: string) => {
                    // Collect data for each subject across all percentage ranges
                    const dataValues = percentageKeys.map((key: string) => response[key][subject]);

                    return {
                        name: subject, // Subject name, e.g., English, Maths, Science
                        data: dataValues, // Array of values for this subject across percentage ranges
                        color: colors[subjects.indexOf(subject) % colors.length] // Dynamic color based on index
                    };
                });
                setGrapgSubjects(dataArray);
                setSubjectsPercent(Object.keys(response));
            }
        } catch (error) {

        }
    }
    const getPieChartData = async (sub: any) => {
        let Payload = {
            teacher_type: "SUBJECT_TEACHER",
            user_code: teacherInfo?.user_code,
            module_uuid: moduleData?.module_uuid,
            subject: subjectInfo?.subject,
            standard: subjectInfo?.standard,
            division: subjectInfo?.division,
            subject_uuid: sub?.uuid ? sub?.uuid : "",
            chart_type: "subject",
            pass_fail: true
        }
        if (Payload?.subject_uuid) {
            try {
                const response: any = await GET_STUDENTS_BY_CLASS_TEACHERS(Payload);
                if (response.status === 'fail') {
                    toast.error(response?.err);
                } else {
                    setPiechartDataInfo(response);
                }
            }
            catch (error) {

            }
        }

    }
    const handleSubject = async (sub: any, subIndex: number) => {
        setSudentsAnalysis({});

        let payLoad = {
            module_uuid: moduleData?.module_uuid,
            school_code: teacherInfo?.institute_school_code,
            teacher_type: "SUBJECT_TEACHER",
            teacher_uuid: teacherInfo?.user_code,
            subject_uuid: sub?.uuid,
            standard: subjectInfo?.standard,
            division: subjectInfo?.division
        }
        try {
            const response: any = await GET_PARIKSHA_STUDENTS_SUBJECT_ANALYSIS(payLoad);
            if (response.status === 'fail') {
                toast.error(response?.err);

            } else {
                setSudentsAnalysis(response);
            }
        } catch (error) {

        }

    }

    const handleBack = () => {
        history.push(`/teacher-dashboard/view/pariksha/subjects-list`)
    }

    const onClickOnStudents = (students: any) => {
        if (students?.length > 0) {
            localStorage.setItem("subject_student_List", JSON.stringify(studentsInfo?.active_students));
            history.push(`/teacher-dashboard/view/pariksha/subject-students`);
        }
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/teacher-dashboard/view"><span className='pe-1'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to="/teacher-dashboard/view/pariksha/teacher-initiatives">Pariksha</Link></li> /
                        <li className="breadcrumb-item ">Dashboard of Subject Teacher</li>
                    </ol>
                </nav>
                <div className="text-end me-md-5 me-3">
                    <button className='btn btn-primary px-4 f14 py-1' onClick={handleBack}>Back</button>
                </div>
                <h4 className="mb-0 f18 text-center mt-1">Class : {subjectInfo?.standard}th | Division : {subjectInfo?.division} | Subject : {subjectInfo?.subject?.toUpperCase()}</h4>
                <div className="class_report_cards">
                    <div className="class_card_view cardOne cursor-pointer" onClick={() => onClickOnStudents(studentsInfo?.total_students_count)}>
                        <h3>{studentsInfo?.total_students_count ? studentsInfo?.total_students_count : "0"}</h3>
                        <p className="mb-1">No Of Students</p>
                    </div>
                    <div className="class_card_view cardTwo cursor-pointer" onClick={() => onClickOnStudents(studentsInfo?.active_students_count)}>
                        <h3>{studentsInfo?.active_students_count ? studentsInfo?.active_students_count : "0"}</h3>
                        <p className="mb-1">No Of Active Students</p>
                    </div>
                    <div className="class_card_view cardThree">
                        <h3>10</h3>
                        <p className="mb-1">No. Of Test Conducted</p>
                    </div>
                </div>
                <div className="row  mt-4 mb-3 mx-2 mx-lg-0">
                    <div className="col-12  col-lg-8 ab_graph_chart px-4 pt-2" >
                        <ParikshaWorksheetColumnChart subjectsPercent={subjectsPercent} graphSubjects={graphSubjects} />
                    </div>
                    <div className="col-12  col-lg-4 " >
                        <ParikshaWorksheetPieChart
                            title="Overall Tests Result"
                            data={[
                                {
                                    name: 'Students Passed the Tests', y: pieChartDataInfo?.pass_percentage ? pieChartDataInfo?.pass_percentage : 0, color: '#4472C4'
                                },
                                { name: 'Students Failed the Tests', y: pieChartDataInfo?.fail_percentage ? pieChartDataInfo?.fail_percentage : 0, color: '#ED7D31' },
                            ]}
                        />
                    </div>
                </div>
                <h5 className="f18 text-center text-dark mt-4">{subjectInfo?.subject?.toUpperCase()} Analysis</h5>
                <div>
                    <ParikshaSubjectAnalysisComponent testAreas={testAreas} resultInfo={studentsAnalysis} onClickOnStudents={onClickOnStudents} />
                </div>
            </div>
            }
        </div>
    )
}
export default ParikshaTeacherSubjectReport;