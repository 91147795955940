import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { DataTable } from '../../../../components/data-table'
import { StudentsDataGridCols } from './data-grid-cols'
import { toast } from 'react-toastify';
import FormBuilder from '../../../../components/form-builder';
import { GET_STUDENT_MATHS_REPORTS_BY_SCHOOL_CODE, GET_STUDENT_MATHS_SEARCH_REPORTS_BY_SCHOOL_CODE } from '../../../../app/service/maths-report.service';
import appConfig from '../../../../config/constant';

export const StudentMathsReportList = () => {
    let { id } = useParams<{ id: string }>();
    const schoolId = id;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [studentList, setStudentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState<any>({});
    const usersForm = useRef<any>({});
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [excelLoading, setExcelLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [dowonLoading, setDowonLoading] = useState<boolean>(false);
    const [showUsersPopup, setShowUsersPopup] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [usersList, setUsersList] = useState<any>([]);
    const filteredData = [
        { name: 'Email ID', value: 'email_id' },
        { name: 'Mobile Number', value: 'mobile_number' },
        { name: 'Whatsapp Number', value: 'whatsapp_number' },
        { name: 'State', value: 'state' },
        { name: 'City', value: 'city_village' },
        { name: 'Taluka', value: 'taluka' },
        { name: 'Gender', value: 'gender' },
        { name: 'Pan Number', value: 'pan_number' },
        { name: 'Address', value: 'address' },
        { name: 'Blood Group', value: 'blood_group' },
        { name: 'District', value: 'district' },
        { name: 'Date of Birth', value: 'date_of_birth' },
        { name: 'Pincode', value: 'pin_code' },
        { name: 'User Name', value: 'user_name' },
    ];

    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');

    const history = useHistory();


    useEffect(() => {
        getStudents();
    }, []);

    function getStudents() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_STUDENT_MATHS_REPORTS_BY_SCHOOL_CODE(schoolId, activePageNumber, {}).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setActivePage(currentPageNumber ? currentPageNumber : 1);
                const data = res.data;
                setStudentList(data);
                const pageNumbers = Math.ceil(res.count / 10);
                const pageArray: any = [];
                for (let i = 1; i <= pageNumbers; i++) {
                    pageArray.push(i);
                }
                setPageNumbers(pageArray);
                setLoading(false);
            }
        });
    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };

    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/maths-report/school/info/${schoolId}/studentlist?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        GET_STUDENT_MATHS_REPORTS_BY_SCHOOL_CODE(schoolId, pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                const data = res.data;
                setStudentList(data);
            }
        });
    }

    function handleSearch() {
        const data = { ...searchData.value };
        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_STUDENT_MATHS_SEARCH_REPORTS_BY_SCHOOL_CODE(schoolId, data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    history.push(`/dashboard/maths-report/school/info/${schoolId}/studentlist?page=${1}`);
                    setActivePage(1);
                    const data = res.data;
                    setStudentList(data);
                    const pageNumbers = Math.ceil(10 / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }

    function handleRefresh() {
        const data = searchData
        data.value = {};
        setSearchData({ ...data });
        getStudents();
        setPreparedSearchData({});
        usersForm.current.reset();
    }

    const handleFilterChange = (e: any) => {
        const data = { ...searchData.value };
        setSearchData(data);
    }

    const handleBack = () => {
        history.push('/dashboard/maths-report/school/list');
    }
    const onClickDownloadUsers = async (exel: boolean, pdf: boolean) => {
        const payLoad = {
            download_excel: exel,
            download_pdf: pdf
        }
        setExcelLoading(exel);
        setPdfLoading(pdf);
        try {
            const res: any = await GET_STUDENT_MATHS_REPORTS_BY_SCHOOL_CODE(schoolId, 0, payLoad, 100000);
            if (res) {
                if (res.status === "fail") {
                    toast.error(res.message);
                } else {
                    const data = res;
                    const preUrl = data?.url?.url;
                    if (preUrl) {
                        const url = `${appConfig.FILE_UPLOAD_URL}${preUrl.charAt(0) === "/"
                            ? data.url.substring(1)
                            : preUrl
                            }`;
                        if (pdf) {
                            window.open(url, '_blank');  // Open PDF in a new tab
                        } else {
                            window.location.href = url;  // Download Excel file in the same tab
                        }
                        // window.location.href = url;
                        toast.success(res?.message);
                    }
                }
            }
            setExcelLoading(false);
            setPdfLoading(false);
        } catch (error) {
            setExcelLoading(false);
            setPdfLoading(false);
        }

    };
    const handleList = async () => {
        setShowUsersPopup(true);
        setLoadingUsers(true);
        try {
            const res: any = await GET_STUDENT_MATHS_REPORTS_BY_SCHOOL_CODE(schoolId, 0, {}, 100000)
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                const data = res.data;
                setUsersList(data);
            }
            setLoadingUsers(false);
        } catch (error) {
            setLoadingUsers(false);

        }
    }
    const handlerDownloadUsers = () => {
        setDowonLoading(!dowonLoading);
    }
    return (
        <div className="row border-top border-primary py-3">
            <div
                className={`modal fade ${showUsersPopup ? "show" : "false"}`}
                style={{ display: showUsersPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Students
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setShowUsersPopup(false)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            {loadingUsers && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!loadingUsers && (
                                <DataTable TableCols={StudentsDataGridCols}
                                    tableData={usersList} pageNumber={onPageChange}
                                    activePageNumber={0} pageNumbers={[1]} requiredPagination="not"></DataTable>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={() => setShowUsersPopup(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-md-flex justify-content-md-between">
                <div>
                    <h2>Students</h2>
                </div>
                <div>
                    {studentList?.length > 0 && <div className='export_btn me-2 me-sm-4'>
                        <button className='btn btn-primary me-1 me-sm-4 f14' onClick={handleList}>View All Users</button>
                        <button
                            className="export_btn_select f14 "
                            onClick={() => handlerDownloadUsers()}
                        >
                            <span>Export Users List
                                <i className='bi bi-chevron-down ps-3'></i></span>
                        </button>
                        {dowonLoading &&
                            <ul className='export_btn_select_drop_list z_100'>
                                <li className='export_btn-list-view' onClick={() => onClickDownloadUsers(true, false)}>{excelLoading && (
                                    <div className="px-3">
                                        <div className="spinner-border " role="status" style={{ height: "20px", width: "20px" }} >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                    {!excelLoading &&
                                        <span>EXCEL</span>}</li>
                                <li className='export_btn-list-view' onClick={() => onClickDownloadUsers(false, true)}>{pdfLoading && (
                                    <div className="px-2">
                                        <div className="spinner-border " role="status" style={{ height: "20px", width: "20px" }} >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                    {!pdfLoading && <span>PDF</span>}</li>
                            </ul>}
                    </div>}

                    <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                </div>
            </div>
            <div className="row" style={{ marginBottom: '15px', marginTop: '25px' }}>
                <div className="col-md-10">
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={usersForm}>
                            <div className="row">
                                <div className="col-md-3">
                                    <input type="text" className="form-control ft-14" placeholder="Search name" name="name" />
                                </div>
                                <div className="col-md-3">
                                    <select className="form-select ft-14" name="standard">
                                        <option value="">Select standard</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select className="form-select  ft-14" name="division">
                                        <option value="">Select division</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="E">E</option>
                                    </select>
                                </div>
                                <div className='col-md-3'></div>
                                <div className="col-md-3 mt-2">
                                    <input type="text" className="form-control ft-14" placeholder="Custom search" name="search" />
                                </div>
                                <div className="col-md-3 mt-2">
                                    <select name="search_field" className="form-select ft-14" onChange={(e) => { handleFilterChange(e) }}>
                                        <option value="">Select filter</option>
                                        {filteredData.map((name: any, i: number) => (
                                            <option key={i} value={name.value}>
                                                {name.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-2 mt-5">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={StudentsDataGridCols}
                tableData={studentList} pageNumber={onPageChange}
                activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
        </div>
    )
}