import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import UserPaymentsSubscription from "../../../components/payments/user";
import { UI_BASE_URL } from "../../../config/constant";


const StudentPaymentView: React.FC = () => {
    let { userCode } = useParams<{ userCode: any }>();
    let userRole: any = localStorage.getItem("user_role");
    let user_code: any = localStorage.getItem("user_code");
    const userCodes: any = (userRole === "STUDENT") ? user_code : userCode;

    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb pb-2 pt-0 px-0 border-bottom">
                    <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                    <li className="breadcrumb-item "><span>Payments</span></li>
                </ol>
            </nav>
            <div className="mt-5 mx-3 mx-md-0">
                <UserPaymentsSubscription UserCode={userCodes} redirectUrl={`${UI_BASE_URL}/student-dashboard/list/${userCodes}/payments`} cancelUrl={`${UI_BASE_URL}/student-dashboard/list/${userCodes}/payments`} naviGateUrl={`/student-dashboard/list`} />
            </div>
        </div>
    )

}
export default StudentPaymentView;