import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CREATE_SCHOOL, CREATE_SCHOOL_USER, GET_SCHOOL_BY_ID, UPDATE_SCHOOL, UPLOAD_LOGO } from '../../../app/service/school.service';
import FormBuilder from '../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { PopupData } from '../../../redux/app-utility/model/popup-data';
import { AppUtilityActions } from '../../../redux/app-utility/action';
import { GET_INSTITUTE, GET_INSTITUTE_BY_INSTITUTE_ID } from '../../../app/service/institute.service';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';
import appConfig from '../../../config/constant';
import TimeKeeper from 'react-timekeeper';
import errorMessages from '../../../components/error-messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { DateComponent } from '../../../components/date';
import { GET_STUDENT_SUBSCRIPTION_PRICE_LIST } from '../../../app/service/subscription.service';
import ProfileImageCrop from '../../../components/image-crop/crop';

interface Props {
    updatePopup?: (data: PopupData) => void;
    schoolId: (value: any) => void;
}

const SchoolFormPage: React.FC<any> = (props: Props) => {
    const [schoolData, setSchoolData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [instituteData, setInstituteData] = useState<any>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [schoolValidationErrors, setSchoolValidationErrors] = useState<FormControlError[]>([]);
    const [schoolLogo, setSchoolLogo] = useState<any>();
    const [currentSchoolData, setCurrentSchoolData] = useState<any>({});
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [isFormEdit, setIsFormEdit] = useState(false);
    const [cityData, setCityData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [clusterNumber, setClusterNumber] = useState(false);
    const [udiseNumber, setUdiseNumber] = useState(false);
    const [contactNumber, setContactNumber] = useState(false);
    const [pincodeNumber, setPincodeNumber] = useState(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [createDate, setCreateDate] = useState(moment().format('YYYY-MM-DD'));
    const [gstNumber, setGstNumber] = useState(false);
    const [checkedStatus, setCheckedStatus] = useState<any>();
    const [mediumSelected, setMediumSelected] = useState([]);
    const [email, setEmail] = useState(false);
    const [startTime, setStartTime] = useState('08:30am');
    const [endTime, setEndTime] = useState('03:30pm');
    const [startShowTime, setStartShowTime] = useState(false);
    const [endShowTime, setEndShowTime] = useState(false);
    const [faxNumber, setFaxNumber] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const [instituteInfo, setInstituteInfo] = useState<any>({});
    const [canShowOrganization, setCanShowOrganization] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [nameError, setNameError] = useState('');
    const [shortformNameError, setShortformNameError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [blockError, setBlockError] = useState('');
    const [isRegistrationDateError, setIsRegistrationDateError] = useState<any>({});
    const [allStateBlock, setAllStateBlock] = useState<any>({});
    const [blockData, setBlockData] = useState<any>([]);
    const [registrationNumberError, setRegistrationNumberError] = useState(false);
    const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
    const [amountError, setAmountError] = useState('');
    const [subscriptionPrice, setSubscriptionPrice] = useState('');
    const [disabled, setDisabled] = useState(true);
    const mediumType = [
        { label: "Assamese", value: "Assamese" },
        { label: "Bengali", value: "Bengali" },
        { label: "Bodo", value: "Bodo" },
        { label: "Dogri", value: "Dogri" },
        { label: "English", value: "English" },
        { label: "Gujarati ", value: "Gujarati" },
        { label: "Hindi", value: "Hindi" },
        { label: "Kannada", value: "Kannada" },
        { label: "Kashmiri", value: "Kashmiri" },
        { label: "Konkani", value: "Konkani" },
        { label: "Maithili", value: "Maithili" },
        { label: "Malayalam", value: "Malayalam" },
        { label: "Manipuri ", value: "Manipuri" },
        { label: "Marathi", value: "Marathi" },
        { label: "Nepali", value: "Nepali" },
        { label: "Odia", value: "Odia" },
        { label: "Punjabi", value: "Punjabi" },
        { label: "Sanskrit", value: "Sanskrit" },
        { label: "Santali", value: "Santali" },
        { label: "Sindhi ", value: "Sindhi" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu ", value: "Telugu" },
        { label: "Urdu", value: "Urdu" }
    ];


    let { id } = useParams<{ id: string }>();
    const schoolId = Number(id) || 0;
    const history = useHistory();
    let createValidations: any = [
        new FormField('organization_code', [FormValidators.REQUIRED]),
        new FormField('name_of_school', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('udise_code', [FormValidators.REQUIRED]),
        new FormField('contact_number', [FormValidators.REQUIRED])

    ]
    let updateValidations: any = [
        new FormField('organization_code', [FormValidators.REQUIRED]),
        new FormField('short_name', []),
        new FormField('name_of_school', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('contact_number', [FormValidators.REQUIRED]),
        new FormField('udise_code', [FormValidators.REQUIRED]),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('taluka', []),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('logo_url', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),

    ]
    const [formValidations, setFormValidations] = useState<any>();


    useEffect(() => {
        if (schoolId > 0) {
            setFormValidations(updateValidations);
        } else {
            setFormValidations(createValidations);
        }
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        if (userRole === 'CHAIRMAN' || userRole === 'GENERAL_SECRETARY' || userRole === 'INSTITUTE_ADMIN') {
            const instituteCode = localStorage.getItem('organization_code');
            getInstituteById(instituteCode);
            setCanShowOrganization(true);
        }

        setLoading(true);
        GET_INSTITUTE().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                const data = res.rows;
                setInstituteData(data);
                setLoading(false);

            }
        });


        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setStatesData(res.states);
                if (schoolId > 0) {
                    setIsFormEdit(true);
                    GET_SCHOOL_BY_ID(schoolId).then((response: any) => {
                        if (response.status === 'fail') {
                            toast.error(response.message);
                        } else {
                            const data = response.rows[0];
                            getStatesData(res.states, data);
                            setCheckedStatus(data.cas_report_view);
                            setCurrentSchoolData(data);
                            if (data.medium) {
                                setMediumSelected(data.medium);
                            }
                            if (data.logo_url) {
                                setSchoolLogo(data.logo_url);
                            }

                            if (data.timings) {
                                setStartTime(data.timings.split("-")[0]);
                                setEndTime(data.timings.split("-")[1]);
                            }
                            if (data.date_of_registration) {
                                const date_of_registration: any = new Date(data.date_of_registration);
                                setTodayDate(date_of_registration);
                            }
                            if (data.created_at) {
                                const created_at: any = new Date(data.created_at);
                                setCreateDate(created_at);
                            }

                            if (data.access) {
                                setCheckedUserMechanismStatus(data.access);
                            }
                        }
                    });
                }
            }
        });


        getSubscriptionList()

    }, []);


    function getInstituteById(schoolCode: any) {
        GET_INSTITUTE_BY_INSTITUTE_ID(schoolCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res.rows.length > 0) {
                    setInstituteInfo(res.rows[0]);

                }
            }
        });
    }

    function getStatesData(states: any, data: any) {
        if (states.length > 0) {
            const preparedData = states.filter((el: any) => el.state === data.state);
            setDistrictData(preparedData[0].districts);
            setCityData(preparedData[0].cities);
            onLoadBlocks(data.district, preparedData[0]?.block);
        }
    }

    const handleInput = (data: any) => {
        data.value = { ...currentSchoolData, ...data.value };
        setSchoolData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setSchoolValidationErrors(errors);
    };

    const handleclose = () => {
        setCanShowPopup(false);
        setCurrentSchoolData(schoolData);
    }

    const handleSave = () => {
        createSchool();
    }

    function createSchool() {
        setLoading(true);
        const data = schoolData;
        delete data[''];
        CREATE_SCHOOL(data).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
                setCurrentSchoolData(data);
                setCanShowPopup(false);
            } else {
                toast.success("School Created Successfully");
                setLoading(false);
                setTimeout(() => {
                    props.schoolId(res.school_code);
                }, 1000);
                setCanShowPopup(false);
            }
        });
    }

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const schoolsData = schoolData.value ? { ...schoolData.value } : { ...currentSchoolData };
        const errors: FormControlError[] = FormValidator(formValidations, schoolsData);
        delete schoolsData.day;
        delete schoolsData.month;
        delete schoolsData.year;
        setSchoolValidationErrors(errors);
        Object.keys(schoolsData).forEach(function (key) {
            if (typeof schoolsData[key] === 'string') {
                schoolsData[key] = schoolsData[key].trim();
            }
        });
        if (errors.length < 1 && !email && !clusterNumber && !udiseNumber && !contactNumber && !pincodeNumber && !gstNumber && !faxNumber
            && !registrationNumberError
            && onChangeName(schoolsData.name_of_school)
            && onChangeAddress(schoolsData.address)
        ) {

            schoolsData.date_of_registration = todayDate ? todayDate : null;
            schoolsData.medium = [];
            schoolsData.cas_report_view = 0;
            schoolsData.timings = '';
            schoolsData.subscription_price = subscriptionPrice;
            schoolsData.category = '';
            schoolsData.city_village = '';
            schoolsData.cluster_code = '';
            schoolsData.curriculum = '';
            schoolsData.fax_number = '';
            schoolsData.gst_number = '';
            schoolsData.logo_url = '';
            schoolsData.registration_number = '';
            schoolsData.taluka = '';
            schoolsData.website_url = '';
            if (canShowOrganization) {
                schoolsData.organization_code = instituteInfo?.organization_code
            }
            setSchoolData(schoolsData);
            setCanShowPopup(true);
        } else {
            if (!nameError && !onChangeName(schoolsData.name_of_school)) {
                setNameError('Please enter department name');
            }
            if (!addressError && !onChangeAddress(schoolsData.address)) {
                setAddressError('Please enter address');
            }
        }
    }

    const handleUpdate = () => {
        setIsFormSubmitted(true);
        const schoolsData = schoolData.value ? { ...schoolData.value } : { ...currentSchoolData };
        schoolsData.id = schoolId;
        schoolsData.logo_url = schoolLogo;
        schoolsData.medium = mediumSelected;
        schoolsData.cas_report_view = checkedStatus;
        schoolsData.date_of_registration = todayDate;
        schoolsData.timings = `${startTime}-${endTime}`;
        delete schoolsData[''];
        delete schoolsData.org_display_name;
        delete schoolsData.org_short_name;
        delete schoolsData.name_of_organization;
        delete schoolsData.updated_at;
        delete schoolsData.created_at;
        delete schoolsData.status;
        delete schoolsData.contact_person;
        delete schoolsData.day;
        delete schoolsData.month;
        delete schoolsData.year;
        delete schoolsData.access;

        schoolsData.contact_number = schoolsData.contact_number.toString();
        schoolsData.access = checkedUserMechanismStatus;
        if (schoolsData.subscription_price === "") {
            schoolsData.subscription_price = null;
        }
        if (canShowOrganization) {
            schoolsData.organization_code = instituteInfo?.organization_code;
        }
        setLoading(true);
        const errors: FormControlError[] = FormValidator(formValidations, schoolsData);
        setSchoolValidationErrors(errors);
        Object.keys(schoolsData).forEach(function (key) {
            if (typeof schoolsData[key] === 'string') {
                schoolsData[key] = schoolsData[key].trim();
            }
        });
        if (errors.length < 1 && !email && !clusterNumber && !udiseNumber && !contactNumber && !pincodeNumber && !gstNumber && !faxNumber
            && !registrationNumberError
            && onChangeName(schoolsData.name_of_school)
            && onChangeAddress(schoolsData.address) && mediumSelected.length > 0
        ) {
            UPDATE_SCHOOL(schoolsData).then(res => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("School Updated Successfully");
                    setLoading(false);
                    if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
                        history.push('/institute-dashboard/manage-school/list');
                    }
                    else {
                        history.push('/dashboard/school/list');
                    }
                }
            });
        } else {
            if (!nameError && !onChangeName(schoolsData.name_of_school)) {
                setNameError('Please enter department name');
            }

            if (!addressError && !onChangeAddress(schoolsData.address)) {
                setAddressError('Please enter address');
            }
            setLoading(false);
        }
    }


    const getInputValid = (control: string) => {
        const value = GetControlIsValid(schoolValidationErrors, control);
        return value;
    }

    const handleChange = (e: any) => {
        const data = { ...schoolData.value };
        data.organization_code = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.organization_code = e.target.value;
        }
    }

    const handleStateChange = (e: any) => {
        setBlockData([]);
        const data = { ...schoolData.value };
        data.state = e.target.value;
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);
        setCityData(preparedData[0].cities);
        setAllStateBlock(preparedData[0]?.block);
        if (currentSchoolData) {
            currentSchoolData.state = e.target.value;
        }
    }

    const handleDistrictChange = (e: any) => {
        setBlockData([]);
        const data = { ...schoolData.value };
        data.district = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.district = e.target.value;
        }
        onLoadBlocks(e.target.value, allStateBlock);
    }

    const onLoadBlocks = (e: any, blocks: any) => {
        Object.keys(blocks).find(key => {
            if (key === e) {
                setBlockData(blocks[key]);
                return;
            }
        });
    }

    const handleBlockChange = (e: any) => {
        const data = { ...schoolData.value };
        data.taluka = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.taluka = e.target.value;
        }
    }

    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }

    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setSchoolLogo(res.url);
                setImageLoader(false);
            }
        });
    }

    const handleContactChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9]{10,11}$/;

        if ((re.test(e.target.value))) {
            data.contact_number = e.target.value;
            setContactNumber(false);
        } else {

            data.contact_number = e.target.value;
            setContactNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.contact_number = e.target.value;
        }
    }

    const handlePinCodeChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
            data.pin_code = e.target.value;
            setPincodeNumber(false);
        } else {
            data.pin_code = e.target.value;
            setPincodeNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.pin_code = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleUDISEChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 11) {
            data.udise_code = e.target.value;
            setUdiseNumber(false);
        } else {
            data.udise_code = e.target.value;
            setUdiseNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.udise_code = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleClusterChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 9) {
            data.cluster_code = e.target.value;
            setClusterNumber(false);
        } else {
            data.cluster_code = e.target.value;
            setClusterNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.cluster_code = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleGSTChange = (e: any) => {
        const data = { ...schoolData.value };

        if ((/^[a-z0-9]+$/.test(e.target.value) || e.target.value.length < 15)) {
            setGstNumber(true);
        } else {
            data.gst_number = e.target.value;
            setGstNumber(false);
        }

        if (currentSchoolData) {
            currentSchoolData.gst_number = e.target.value;
        }

    }

    const handleReportStatusChange = (e: any) => {

        const status = e.target.checked === true ? 1 : 0;
        setCheckedStatus(status);

        if (currentSchoolData) {
            const status = e.target.checked === true ? 1 : 0;
            currentSchoolData.cas_report_view = e.target.checked === true ? 1 : 0;
            setCheckedStatus(status);

        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentSchoolData) {
            currentSchoolData.email_id = e.target.value;
        }
    }

    const handleCirriculumChange = (e: any) => {
        const data = { ...schoolData.value };
        data.curriculum = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.curriculum = e.target.value;
        }
    }

    const handleCategoryChange = (e: any) => {
        const data = { ...schoolData.value };
        data.category = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.category = e.target.value;
        }
    }

    const handleFaxNumberChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9\b]+$/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.fax_number = e.target.value;
            setFaxNumber(false);
        } else {
            data.fax_number = e.target.value;
            setFaxNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.fax_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const onDateChange = (dates: any) => {
        setTodayDate(todayDate);
        if (dates.error) {
            setIsRegistrationDateError(dates);
        } else {
            setTodayDate(dates.date);
        }
    };

    function onTodayDate(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name of institution');
        }
    }


    const onChangeShortformName = (event: any) => {
        setShortformNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setShortformNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setShortformNameError('Please enter short form name');
        }
    }

    const onChangeAddress = (event: any) => {
        setAddressError('');
        // const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        const reg = /^[a-zA-Z0-9 ,:/|;.+=_\\-]*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setAddressError('Address can`t contain spaces and special characters');
            } else {
                return true;
            }
        } else {
            setAddressError('Please enter address');
        }
    }

    const onChangeBlock = (event: any) => {
        setBlockError('');
        if (event) {
            if (event.trim().length <= 2) {
                setBlockError('Block minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setBlockError('Block maximum 256 characters');

            } else {
                return true;
            }
        } else {
            setBlockError('Please enter Block');
        }
    }

    const onChangeRegistrationNumber = (e: any) => {
        const data = { ...schoolData.value };
        const reg = /^[0-9]{1,15}$/;
        if ((e.target.value === '' || reg.test(e.target.value))) {
            data.registration_number = e.target.value;
            setRegistrationNumberError(false);
        } else {
            data.registration_number = e.target.value;
            setRegistrationNumberError(true);
        }

        if (currentSchoolData) {
            currentSchoolData.registration_number = e.target.value;
        }
    }

    const handleUserMechanism = (e: any) => {
        if (currentSchoolData) {
            const status = e.target.checked === true ? "ENABLED" : "DISABLED";
            currentSchoolData.access = status;
            setCheckedUserMechanismStatus(status);
        }
    }
    const onChangeAmount = (e: any) => {

        setAmountError('');
        const reg = /^[0-9]*$/;
        if (e !== 0 || e !== '') {
            if (!reg.test(e)) {
                setAmountError('Amount only numbers');
            } else {

                return true;
            }
        } else {
            setAmountError('Please enter amount');
        }

    }
    function getSubscriptionList() {
        setLoading(true);
        GET_STUDENT_SUBSCRIPTION_PRICE_LIST("SUBSCRIPTION_PRICE", 0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setSubscriptionPrice(data[0].data.subscription_price)
                    setLoading(false);
                }
            }
        });
    }
    function editSubscriptionPrice() {
        // let edit = true;
        setDisabled(!disabled)

    }

    return (
        <div className="row border-top border-primary py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Alert</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                “Kindly recheck details on the form. Once saved, they cannot be changed later”
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Back</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {!loading && <div>
                <FormBuilder onUpdate={handleInput}>
                    <form>
                        <div className="row custom-form">
                            <div className="row" style={{ paddingLeft: '15px', fontWeight: 'bold', fontSize: '18px' }}>
                                School
                            </div>
                            <hr />
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Name of Institute</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    {!canShowOrganization && <select name="organization_code" className="form-select form-select-lg mb-3" value={currentSchoolData?.organization_code} onChange={(e) => { handleChange(e) }}>
                                        <option value="">Select the institute</option>
                                        {instituteData.map((name: any, i: number) => (
                                            <option key={i} value={name.organization_code}>
                                                {name.name_of_organization}
                                            </option>
                                        ))}
                                    </select>
                                    }

                                    {canShowOrganization && <select name="organization_code" className="form-select form-select-lg mb-3" value={currentSchoolData?.organization_code} onChange={(e) => { handleChange(e) }}>
                                        <option value="">Select the institute</option>
                                        <option value={instituteInfo?.organization_code}>{instituteInfo?.name_of_organization}</option>

                                    </select>
                                    }

                                    {isFormSubmitted && !getInputValid('organization_code') && <p className="text-danger">Please select name of education society /trust /parent institution</p>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Name of School </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="name_of_school" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentSchoolData?.name_of_school} placeholder="Please enter name of institution" />
                                    {nameError && <p className="text-danger">{nameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Short form Name</label>
                                    <input className="form-control form-control-lg" type="text" name="short_name" onChange={(e) => onChangeShortformName(e.target.value)} defaultValue={currentSchoolData?.short_name} placeholder="Please enter short form of name" />
                                    {shortformNameError && <p className="text-danger">{shortformNameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Address</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="address" onChange={(e) => onChangeAddress(e.target.value)} defaultValue={currentSchoolData?.address} placeholder="Please enter  address" />
                                    {addressError && <p className="text-danger">{addressError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Contact Number (Mobile)</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="contact_number" maxLength={10} value={currentSchoolData?.contact_number} onChange={(e) => { handleContactChange(e) }} placeholder="Please enter mobile number" />
                                    {contactNumber && <p className="text-danger">Length of contact number is 10 digits, Enter proper value</p>}
                                    {!contactNumber && isFormSubmitted && !getInputValid('contact_number') && <p className="text-danger">Please enter contact number</p>}
                                </div>
                            </div>
                            {isFormEdit && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Date of Registration</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    {currentSchoolData.date_of_registration &&
                                        <DateComponent onDate={onDateChange} idDisable={true} yearDiff={0} defaultData={currentSchoolData.date_of_registration}></DateComponent>}
                                    {!currentSchoolData.date_of_registration && <DateComponent idDisable={true} defaultData={todayDate} onDate={onDateChange} yearDiff={0}></DateComponent>}
                                </div>
                            </div>}

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">State</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select name="state" className="form-select form-select-lg mb-3" value={currentSchoolData?.state} onChange={(e) => { handleStateChange(e) }}>
                                        <option value="">Select state</option>
                                        {statesData.map((name: any, i: number) => (
                                            <option key={i} value={name.state}>
                                                {name.state}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('state') && <p className="text-danger">Please select state</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">District</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select name="district" className="form-select form-select-lg mb-3" value={currentSchoolData?.district} onChange={(e) => { handleDistrictChange(e) }}>
                                        <option value="">Select district</option>
                                        {districtData.map((name: any, i: number) => (
                                            <option key={i} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('district') && <p className="text-danger">Please select district</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Block</label>
                                    <select name="taluka" className="form-select form-select-lg mb-3" value={currentSchoolData?.taluka} onChange={(e) => { handleBlockChange(e) }}>
                                        <option value="">Select block</option>
                                        {blockData.map((name: any, i: number) => (
                                            <option key={i} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('taluka') && <p className="text-danger">Please select block</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">City/Village (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentSchoolData?.city_village} placeholder="Please enter village" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">PIN Code</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="pin_code" maxLength={6} value={currentSchoolData?.pin_code} onChange={(e) => { handlePinCodeChange(e) }} placeholder="Please enter PIN code" />
                                    {pincodeNumber && <p className="text-danger">{errorMessages.pincode}</p>}
                                    {isFormSubmitted && !getInputValid('pin_code') && <p className="text-danger">Please enter pin code</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Email ID</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="email_id" value={currentSchoolData?.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                    {email && <p className="text-danger">{errorMessages.email}</p>}
                                    {isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                </div>
                            </div>
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">Fax Number (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="fax_number" maxLength={10} value={currentSchoolData?.fax_number} onChange={(e) => { handleFaxNumberChange(e) }} placeholder="Please enter fax number" />
                                    {faxNumber && <p className="text-danger">The length of the FAX number is 10 digits, Enter a proper value</p>}
                                </div>
                            </div>}

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">UDISE Code</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="udise_code" maxLength={11} value={currentSchoolData?.udise_code} onChange={(e) => { handleUDISEChange(e) }} placeholder="Please enter UDISE code" />
                                    {udiseNumber && <p className="text-danger">{errorMessages.UDISE_number}</p>}
                                    {isFormSubmitted && !getInputValid('udise_code') && <p className="text-danger">Please enter UDISE Code</p>}
                                </div>
                            </div>
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-3">School Timings</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <br />
                                    {startShowTime &&
                                        <div className='timings-view'>
                                            <TimeKeeper
                                                time={startTime}
                                                onChange={(newTime) => setStartTime(newTime.formatted12)}
                                                onDoneClick={() => setStartShowTime(false)}
                                                switchToMinuteOnHourSelect
                                            />
                                        </div>
                                    }
                                    {!startShowTime &&
                                        <input className="form-control time-range cursor-pointer" type="text" placeholder={startTime} onClick={() => setStartShowTime(true)} />
                                    }
                                    {endShowTime &&
                                        <div className='timings-view'>
                                            <TimeKeeper
                                                time={endTime}
                                                onChange={(newTime) => setEndTime(newTime.formatted12)}
                                                onDoneClick={() => setEndShowTime(false)}
                                                switchToMinuteOnHourSelect
                                            />
                                        </div>
                                    }
                                    {!endShowTime &&
                                        <input className="form-control time-range cursor-pointer" type="text" placeholder={endTime} onClick={() => setEndShowTime(true)} />
                                    }
                                </div>
                            </div>}
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Cluster Code</label>
                                    <input className="form-control form-control-lg" type="text" name="cluster_code" maxLength={9} value={currentSchoolData?.cluster_code} onChange={(e) => { handleClusterChange(e) }} placeholder="Please enter cluster code" />
                                    {clusterNumber && <p className="text-danger">Please Enter Numbers only</p>}
                                    {isFormSubmitted && !getInputValid('cluster_code') && <p className="text-danger">Please enter cluster code</p>}
                                </div>
                            </div>}
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">Curriculum </label>
                                    <select className="form-select form-select-lg mb-3" name="curriculum" value={currentSchoolData.curriculum} onChange={(e) => { handleCirriculumChange(e) }}>
                                        <option value="">Select curriculum</option>
                                        <option value="SSC">SSC</option>
                                        <option value="ICSE">ICSE</option>
                                        <option value="CBSE">CBSE</option>
                                    </select>
                                </div>
                            </div>}
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Category</label>
                                    <select className="form-select form-select-lg mb-3" name="category" value={currentSchoolData.category} onChange={(e) => { handleCategoryChange(e) }}>
                                        <option value="">Select category</option>
                                        <option value="Government">Government</option>
                                        <option value="Private">Private</option>
                                        <option value="Aided">Aided</option>
                                        <option value="Non-aided">Non-aided</option>
                                    </select>
                                </div>
                            </div>}
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Medium of Instruction</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <MultiSelect
                                        options={mediumType}
                                        value={mediumSelected}
                                        onChange={setMediumSelected}
                                        labelledBy="Select"
                                    />
                                    {(isFormSubmitted && mediumSelected?.length == 0) && <p className="text-danger">Please select medium of instruction</p>}
                                    {/* {isFormSubmitted && !getInputValid('medium') && <p className="text-danger">Please select medium of instruction</p>} */}
                                </div>
                            </div>}
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Registration Number (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="registration_number" defaultValue={currentSchoolData?.registration_number} onChange={(e) => onChangeRegistrationNumber(e)} placeholder="Please enter registration number" />
                                    {registrationNumberError && <p className="text-danger">Registration number must be numbers and min 1 and maximum 15 characters</p>}
                                </div>
                            </div>}
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Website Address (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="website_url" defaultValue={currentSchoolData?.website_url} placeholder="Please enter website url" />
                                </div>
                            </div>}
                            {schoolId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">GST Number (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="gst_number" maxLength={15} onChange={(e) => { handleGSTChange(e) }} value={currentSchoolData?.gst_number} placeholder="Please enter GST number" />
                                    {gstNumber && <p className="text-danger">The length of the GST number is 15 digits, Enter a proper value</p>}
                                </div>
                            </div>}
                            {(schoolId > 0 && userRole === "SUPER_ADMIN") && <div className='col-md-4'>
                                <label className="form-label mb-0">Membership Price</label>
                                <input className="form-control form-control-lg no-spinner" type="number" name="subscription_price" onChange={(e) => onChangeAmount(e.target.value)} defaultValue={currentSchoolData?.subscription_price} placeholder="Please enter amount" disabled={disabled} style={{ backgroundColor: disabled ? 'hsl(0,0%,96.5%)' : 'white' }} />
                                <span className="eye-icon cursor-pointer">
                                    <i className="fa fa-pencil" style={{ fontSize: "20px" }} onClick={editSubscriptionPrice}></i>
                                </span>
                                {amountError && <p className="text-danger">{amountError}</p>}
                            </div>}
                            {schoolId > 0 &&
                                <div className="col-md-4 col-12">
                                    <div className="mb-4 d-flex justify-content-center">
                                        <ProfileImageCrop uploadLogo={uploadLogo}
                                            required={true}
                                            profileImg={schoolLogo}
                                            imageLoader={imageLoader}
                                            profileImageDelete={setSchoolLogo}
                                            errorMsg={`${isFormSubmitted && !schoolLogo ? "Please Upload Logo" : ""}`}
                                            uploadBtnText={"Upload Logo"}
                                        />
                                    </div>
                                </div>
                            }

                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    {!isFormEdit && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Save & Next</a>}
                    {isFormEdit && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleUpdate}>Update</a>}
                    {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/dashboard/school/list">Cancel</Link>}
                    {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") &&
                        <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/institute-dashboard/manage-school/list">Cancel</Link>
                    }
                </div>
            </div>}
        </div >
    )
}

const mapStateToProps = (state: any) => {
    return {
        UtilityRed: state.AppUtilityReducer
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updatePopup: (data: PopupData) => dispatch(AppUtilityActions.updatePopup(data))
    }
}
//  export default SchoolFormPage;
export default connect(mapStateToProps, mapDispatchToProps)(SchoolFormPage);