import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AVERAGEAREA from "../../../../institute-dashboard-assets/images/Average area.gif"
import WEAKAREA from "../../../../institute-dashboard-assets/images/Weak area.gif"
import GOODAREA from "../../../../institute-dashboard-assets/images/Good area.gif"
import BESTAREA from "../../../../institute-dashboard-assets/images/Best area.gif"
import LOWAREA from "../../../../institute-dashboard-assets/images/Lowest area.gif"
import FASTAREA from "../../../../institute-dashboard-assets/images/Fastest area.gif"
import { toast } from "react-toastify";
import { GET_SUBJECT_BY_UUID } from "../../../../app/service/abhyas-subject.service";
import { CREATE_MNS_USER_ASSIGN } from "../../../../app/service/mns-service";
import { GET_MODULES_BY_USER_CODES } from "../../../../app/service/module.service";
import { GET_USER_USER_CODE } from "../../../../app/service/users.service";
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE } from "../../../../app/service/payment-service";
import { CREATE_CCAVENUE_PAYMENT } from "../../../../app/service/ccavenue.service";
import { UI_BASE_URL } from "../../../../config/constant";
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from "../../../../app/service/subscription.service";
import ParikshaTestAnalysisComponent from "../../../../components/pariksha/test-analysis";


const ParikshaTestAnalysis = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [resultInfo, setResultInfo] = useState<any>({});
    const [subjectData, setSubjectData] = useState<any>({});
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    const testInfo: any = JSON.parse(localStorage.getItem("pariksha_test_info") || '{}');
    const testData: any = JSON.parse(localStorage.getItem("result-test-data") || '{}');
    const testUuid = localStorage.getItem("testUuid");
    const selectTest = localStorage.getItem("select_test");
    const history = useHistory();
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [moduleData, setModuleData] = useState<any>({});
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState<boolean>(false);

    const testAreas: any = [
        {
            name: "weak",
            img_src: WEAKAREA,
            bg_color: "wek"
        },
        {
            name: "average",
            img_src: AVERAGEAREA,
            bg_color: "avg"
        },
        {
            name: "good",
            img_src: GOODAREA,
            bg_color: "gd"
        },
        {
            name: "best",
            img_src: BESTAREA,
            bg_color: "bst"
        },
        {
            name: "slowest",
            img_src: LOWAREA,
            bg_color: "sw"

        },
        {
            name: "fastest",
            img_src: FASTAREA,
            bg_color: "ft"

        }
    ];

    useEffect(() => {
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }
        getUserDetails();
        getModuleData();
        setLoading(true);
        getSubjectInfo();
        setResultInfo(testData);
        setTimeout(() => {
            setLoading(false);
        }, 500);

    }, []);
    function getModuleData() {
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {

            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.filter((el: any) => el.module_code === 'ABHYAAS');
                    setModuleData(data[0]);
                }
            }
        });
    }
    function getUserDetails() {
        GET_USER_USER_CODE(userCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.rows[0];
                localStorage.setItem("cc_student_info", JSON.stringify(data));
                setStudentInfo(data);
            }
        });
    }

    const getSubjectInfo = () => {
        GET_SUBJECT_BY_UUID(selectUuids?.subject_uuid).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res.rows.length > 0) {
                    setSubjectData(res.rows[0]);
                }
            }
        });
    }

    const onClickCancel = () => {
        setCanShowPaymentPopup(false);
    }
    const viewAbyhyaasModule = () => {
        history.push(`/student-dashboard/list/abhyas/progress-view/${moduleData?.uuid}/${userCode}/${schoolCode}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`)

        // if (moduleData?.amount_status === "COMPLETED") {
        //     history.push(`/student-dashboard/list/abhyas/progress-view/${moduleData?.uuid}/${userCode}/${schoolCode}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`)

        // } else {
        //     if (moduleData?.uuid) {
        //         getPaymentDetailsOfUser();
        //     }
        // }
    }
    function getPaymentDetailsOfUser() {
        const payLoad = {
            "institute_school_code": schoolCode,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${userCode}` }],
            "module_uuid": moduleData?.uuid
        }
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setCanShowPaymentPopup(true);
                    }
                }
            }
        });

    }
    const handlePayment = () => {
        createPayment(moduleData, paymentInfo);
        localStorage.setItem("cc_module_info", JSON.stringify(moduleData));
    }
    function createPayment(moduleInfo: any, paymentInfo: any) {
        const payload = {
            school_code: schoolCode,
            user_ids: paymentInfo?.user_code,
            sub_amount: paymentInfo?.total_amount
        }
        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }
    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)
        createCcavenuePayment(amount, txn_id);
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/student-dashboard/list/pariksha/test-analysis/${moduleUUID}/${userCode}/${schoolCode}`,
            cancel_url: `${UI_BASE_URL}/student-dashboard/list/pariksha/test-analysis/${moduleUUID}/${userCode}/${schoolCode}`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": paymentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getAssignModule(paymentInfo, moduleInfo, studentInfo);
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }

    function getAssignModule(paymentInfo: any, moduleData: any, studentInfo?: any) {
        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: studentInfo?.institute_school_code,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Successfully assigned  module!");
                getModuleData()
                removeItemsFromeLocalStorage();
            }
        });
    }

    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_module_info'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };


    return (
        <div>
            <div
                className={`modal fade ${canShowPaymentPopup ? "show" : "false"}`}
                style={{ display: canShowPaymentPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  pariksha-model-info">
                            <div className='pariksha-model-border ba bas-dashed'>
                                <div className=' mt-4 justify-content-center'>
                                    <h6 className='blue_text f16'>You do not have to access to <br />ABHYAAS initiative to solve worksheets </h6>
                                </div>
                                <p className='text-center blue_text fw-normal'>You need to pay  <i className="fa fa-rupee pt-3"></i> {paymentInfo?.total_amount}.00  to access <br /> ABHYAAS initiative </p>
                                <div className='start-test mt-4 justify-content-center'>
                                    <h6>Do you want to continue ? </h6>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={handlePayment}>Pay Now</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        {testUuid === "0" && <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-initiative/${moduleUUID}/${userCode}/${schoolCode}`}><span className='pe-2'>Pariksha</span></Link></li> /

                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Start Pariksha</span></Link></li> /
                            <li className="breadcrumb-item "><span className="active">{resultInfo?.exam_type?.split('_')
                                .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                .join(' ')}</span></li> /
                            {/* <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.test_name}</span></Link></li> / */}
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testInfo.test_no}`}><span>Submission</span></Link></li> /
                            <li className="breadcrumb-item "><span>Passed tests</span></li> /
                            <li className="breadcrumb-item "><span>Analysis</span></li>
                        </ol>}
                        {testUuid !== "0" && <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>Result Summary</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{subjectData?.name?.charAt(0).toUpperCase() + subjectData?.name?.slice(1).toLowerCase()}</span></Link></li> /
                            <li className="breadcrumb-item "><span>{selectTest} tests</span></li> /
                            <li className="breadcrumb-item "><span>Analysis</span></li>
                        </ol>}
                    </nav>
                    <div className='text-end me-5 mb-1'>
                        <button className="btn btn-primary btn-sm px-5 py-1 rounded-12 cursor-pointer" onClick={() => history.goBack()}>Back</button>
                    </div>
                    <h5 className="text-center mt-3">Test Analysis :{resultInfo?.exam_type && <span className='text-black'> {resultInfo?.exam_type?.split('_')
                        .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                        .join(' ')}   {(resultInfo?.exam_type === "CHAPTER_WISE_TEST") && <span>({resultInfo?.test_details?._chapter?.name})</span>}</span>}</h5>

                    <ParikshaTestAnalysisComponent testAreas={testAreas} resultInfo={resultInfo} viewAbyhyaasModule={viewAbyhyaasModule} dashboard="student" />

                </div>}
        </div>
    )
}
export default ParikshaTestAnalysis;