import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CASReportPage from '../pages/dashboard/cas-report';
import { DepartmentForm } from '../pages/dashboard/department/form';
import { DepartmentList } from '../pages/dashboard/department/list';
import InstitutePage from '../pages/dashboard/institute/index';
import { DashboardView } from '../pages/dashboard/main/view';
import MathsReportPage from '../pages/dashboard/maths-report';
import { MLATwoAbilityResultPage } from '../pages/dashboard/mla-two/ability-result';
import { MLATwoQuestionsForm } from '../pages/dashboard/mla-two/questions';
import { MLATwoLevelSuccessPage } from '../pages/dashboard/mla-two/success-level';
import { MLATwoTestLevelStatusPage } from '../pages/dashboard/mla-two/test-level-status';
import { MLAAbilityResultPage } from '../pages/dashboard/mla/ability-result';
import { MLAQuestionsForm } from '../pages/dashboard/mla/questions';
import { MLALevelSuccessPage } from '../pages/dashboard/mla/success-level';
import { MLATestLevelStatusPage } from '../pages/dashboard/mla/test-level-status';
import { MNSQuestionsForm } from '../pages/dashboard/mns/questions';
import { MNSLevelSuccessPage } from '../pages/dashboard/mns/success-level';
import { MNSTestLevelStatusPage } from '../pages/dashboard/mns/test-level-status';
import { MNSDashboardView } from '../pages/dashboard/mns/view';
import ModulesInfoPage from '../pages/dashboard/modules';
import { AssignSchool } from '../pages/dashboard/modules/assign-school';
import { ModuleFormPage } from '../pages/dashboard/modules/form';
import { PaymentsList } from '../pages/dashboard/payment/list';
import { AccesObjectForm } from '../pages/dashboard/access-objects/form';
import { AccessObjectList } from '../pages/dashboard/access-objects/list';
import { QuestionsForm } from '../pages/dashboard/questions/form';
import { MNSQuestionForm } from '../pages/dashboard/questions/mns-form';
import { FamilyReport } from '../pages/dashboard/reports/family';
import { StudentMathsReport } from '../pages/dashboard/reports/maths/student';
import { ParentReport } from '../pages/dashboard/reports/parent';
import { StudentReport } from '../pages/dashboard/reports/stundent';
import { ReportsView } from '../pages/dashboard/reports/view';
import { RoleForm } from '../pages/dashboard/roles/form';
import { RolesList } from '../pages/dashboard/roles/list';
import SchoolPage from '../pages/dashboard/school/index';
import ParentsPage from '../pages/dashboard/school/parent';
import IndividualStudentPage from '../pages/dashboard/student';
import { StudentDashboardView } from '../pages/dashboard/student/view';
import SubscriptionReportPage from '../pages/dashboard/subscription-report';
import { UserFormPage } from '../pages/dashboard/users/form';
import { UsersList } from '../pages/dashboard/users/list';
import { UsersInfo } from '../pages/dashboard/users/usersinfo';
import InstitutesList from '../pages/dashboard/main/institutelist';
import Dashboard from '../pages/institute-dashboard/dashboard/dashboard';
import SchoolsList from '../pages/dashboard/main/schoolslist';
import SchoolDashboard from '../pages/school-dashboard/dashboard/view';
import RegisteredSchools from '../pages/institute-dashboard/dashboard/registered-schools/list';
import RegisteredStudents from '../pages/institute-dashboard/dashboard/registered-students/list';
import ActiveStudentsSchools from '../pages/institute-dashboard/dashboard/active-students/list';
import InitiativeOptedSchools from '../pages/institute-dashboard/dashboard/initiatives-opted/list';
import InitiativesList from '../pages/institute-dashboard/dashboard/initiatives-opted/initiativeslist';
import RegisteredStudentsList from '../pages/institute-dashboard/dashboard/registered-students/studentslist';
import ActiveStudentsList from '../pages/institute-dashboard/dashboard/active-students/studentslist';
import SchoolRegisteredStudents from '../pages/school-dashboard/dashboard/registered-students/list';
import SchoolActiveStudents from '../pages/school-dashboard/dashboard/active-students/list';
import CasClassWiseAnalysys from '../pages/school-dashboard/dashboard/cas-analysys/classwise';
import CasAnalysys from '../pages/school-dashboard/dashboard/cas-analysys/view';
import MathsClassWiseAnalysys from '../pages/school-dashboard/dashboard/maths-analysys/classwise';
import MathsAnalysys from '../pages/school-dashboard/dashboard/maths-analysys/view';
import { SopnsorList } from '../pages/dashboard/sponser/list';
import SponsorForm from '../pages/dashboard/sponser/form';
import PromotionPage from '../pages/dashboard/promotion';
import { PaymentDetailsView } from '../pages/dashboard/sponser/payment-details';
import SponsorReportPage from '../pages/dashboard/sponsor-report';
import CouponReportPage from '../pages/dashboard/coupon-report';
import SpecialDiscountPage from '../pages/dashboard/special-discount';
import SubscriptionPage from '../pages/dashboard/subscription';


const DashbordRoutes = () => {
    return (
        <Switch>
            <Route path="/dashboard/institute" component={InstitutePage}></Route>
            <Route path="/dashboard/school" component={SchoolPage}></Route>
            <Route exact path="/dashboard/users/info/:id" component={UsersInfo}></Route>
            <Route exact path="/dashboard/users" component={UsersList}></Route>
            <Route exact path="/dashboard/users/form" component={UserFormPage}></Route>
            <Route exact path="/dashboard/users/form/:id" component={UserFormPage}></Route>
            <Route exact path="/dashboard/department" component={DepartmentList}></Route>
            <Route exact path="/dashboard/department/form" component={DepartmentForm}></Route>
            <Route exact path="/dashboard/department/form/:id" component={DepartmentForm}></Route>
            <Route exact path="/dashboard/module/form/:id" component={ModuleFormPage}></Route>
            <Route exact path="/dashboard/module/assign-school" component={AssignSchool}></Route>
            <Route path="/dashboard/module" component={ModulesInfoPage}></Route>
            <Route exact path="/dashboard/reports" component={ReportsView}></Route>
            <Route exact path="/dashboard/reports/:id/:userCode/:studentCode" component={ReportsView}></Route>
            <Route exact path="/dashboard/reports/student" component={StudentReport}></Route>
            <Route exact path="/dashboard/reports/student/:id/:userCode/:studentCode" component={StudentReport}></Route>
            <Route exact path="/dashboard/reports/family" component={FamilyReport}></Route>
            <Route exact path="/dashboard/reports/family/:id/:userCode/:studentCode" component={FamilyReport}></Route>
            <Route exact path="/dashboard/reports/parent" component={ParentReport}></Route>
            <Route exact path="/dashboard/maths/reports/student/:userCode" component={StudentMathsReport}></Route>
            <Route exact path="/dashboard/reports/parent/:id/:userCode/:studentCode" component={ParentReport}></Route>
            <Route exact path="/dashboard/view" component={DashboardView}></Route>

            {/* Sponsor payment  */}
            <Route exact path="/dashboard/sponsor" component={SopnsorList}></Route>
            <Route exact path="/dashboard/sponsor/form/:id" component={SponsorForm}></Route>
            <Route exact path="/dashboard/sponsor/payment-details/:Uuid" component={PaymentDetailsView}></Route>


            <Route exact path="/dashboard/student/view/:usercode/:studentCode" component={StudentDashboardView}></Route>
            <Route exact path="/dashboard/questions/:id/:moduleCode" component={QuestionsForm}></Route>
            <Route exact path="/dashboard/roles" component={RolesList}></Route>
            <Route exact path="/dashboard/roles/form" component={RoleForm}></Route>
            <Route exact path="/dashboard/roles/form/:id" component={RoleForm}></Route>

            <Route exact path="/dashboard/access-objects" component={AccessObjectList} ></Route>
            <Route exact path="/dashboard/access-objects/form" component={AccesObjectForm}></Route>
            <Route exact path="/dashboard/access-objects/form/:id" component={AccesObjectForm}></Route>


            <Route exact path="/dashboard/payments" component={PaymentsList}></Route>
            <Route path="/dashboard/individual-student" component={IndividualStudentPage}></Route>
            <Route path="/dashboard/parent" component={ParentsPage}></Route>
            <Route path="/dashboard/cas-report" component={CASReportPage}></Route>
            <Route path="/dashboard/subscription-report" component={SubscriptionReportPage}></Route>
            <Route path="/dashboard/maths-report" component={MathsReportPage}></Route>

            {/* /* MNS Routing*/}
            <Route exact path="/dashboard/mns-questions/:id/:moduleCode" component={MNSQuestionForm}></Route>
            <Route exact path="/dashboard/mns/dashboard/:moduleUUID/:userCode/:schoolCode" component={MNSDashboardView}></Route>
            <Route exact path="/dashboard/mns/success-level/:level/:mode/:moduleUUID/:userCode/:schoolCode" component={MNSLevelSuccessPage}></Route>
            <Route exact path="/dashboard/mns/questions/list/:moduleUUID/:userCode/:schoolCode" component={MNSQuestionsForm}></Route>
            <Route exact path="/dashboard/mns/test-level-status/:moduleUUID/:userCode/:schoolCode" component={MNSTestLevelStatusPage}></Route>

            {/* /* MLA  One Routing*/}
            <Route exact path="/dashboard/mla/test-level-status/:moduleUUID/:userCode/:schoolCode" component={MLATestLevelStatusPage}></Route>
            <Route exact path="/dashboard/mla/ability-result/:moduleUUID/:userCode/:schoolCode" component={MLAAbilityResultPage}></Route>
            <Route exact path="/dashboard/mla/questions/:moduleUUID/:userCode/:schoolCode" component={MLAQuestionsForm}></Route>
            <Route exact path="/dashboard/mla/success-page/:moduleUUID/:userCode/:schoolCode" component={MLALevelSuccessPage}></Route>


            {/* /* MLA Two Routing*/}

            <Route exact path="/dashboard/mla-two/test-level-status/:moduleUUID/:userCode/:schoolCode" component={MLATwoTestLevelStatusPage}></Route>
            <Route exact path="/dashboard/mla-two/questions/:moduleUUID/:userCode/:schoolCode" component={MLATwoQuestionsForm}></Route>
            <Route exact path="/dashboard/mla-two/ability-result/:moduleUUID/:userCode/:schoolCode" component={MLATwoAbilityResultPage}></Route>
            <Route exact path="/dashboard/mla-two/success-page/:moduleUUID/:userCode/:schoolCode" component={MLATwoLevelSuccessPage}></Route>


            {/* institute-Dashboard */}

            <Route exact path="/dashboard/view/instituteslist" component={InstitutesList}></Route>
            <Route path="/dashboard/view/instituteinfo" component={Dashboard}></Route>
            <Route exact path="/dashboard/view/schoolslist" component={SchoolsList}></Route>
            <Route exact path="/dashboard/view/registered-schools" component={RegisteredSchools}></Route>
            <Route exact path="/dashboard/view/registered-students" component={RegisteredStudents}></Route>
            <Route exact path="/dashboard/view/active-students" component={ActiveStudentsSchools}></Route>
            <Route exact path="/dashboard/view/initiatives-opted" component={InitiativeOptedSchools}></Route>
            <Route exact path="/dashboard/view/initiativeslist/:id/:schoolCode" component={InitiativesList}></Route>
            <Route exact path="/dashboard/view/registered-studentslist/:id/:schoolCode" component={RegisteredStudentsList}></Route>
            <Route exact path="/dashboard/view/active-studentslist/:id/:schoolCode" component={ActiveStudentsList}></Route>

            {/* School-Dashboard */}

            <Route path="/dashboard/view/schoolinfo" component={SchoolDashboard}></Route>
            <Route exact path="/dashboard/view/registered-studentslist/list" component={SchoolRegisteredStudents}></Route>
            <Route exact path="/dashboard/view/active-studentslist/list" component={SchoolActiveStudents}></Route>
            <Route exact path="/dashboard/view/cas-analysys/classwise/:className" component={CasClassWiseAnalysys}></Route>
            <Route exact path="/dashboard/view/cas-analysys/view" component={CasAnalysys}></Route>
            <Route exact path="/dashboard/view/maths-analysys/classwise/:className" component={MathsClassWiseAnalysys}></Route>
            <Route exact path="/dashboard/view/maths-analysys/view" component={MathsAnalysys}></Route>

            {/* PromotionPage */}
            <Route path="/dashboard/promotion" component={PromotionPage}></Route>

            {/* Special Discount */}
            <Route path="/dashboard/special-discount" component={SpecialDiscountPage}></Route>


            {/* {sponsor-report} */}
            <Route path="/dashboard/sponsor-report" component={SponsorReportPage}></Route>

            {/* {coupon-report} */}
            <Route path="/dashboard/coupon-report" component={CouponReportPage}></Route>

            {/* subscription */}

            {/* <Route exact path="/dashboard/subscription/list" component={SubScription}></Route> */}

            <Route path="/dashboard/subscription" component={SubscriptionPage}></Route>

        </Switch>
    )
}

export default DashbordRoutes;
