import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FormBuilder from "../form-builder";
import { DataTableCol } from "./types";
import { toast } from "react-toastify";
import {
  FormControlError,
  FormField,
  FormValidators,
} from "../form-builder/model/form-field";
import { FormValidator, GetControlIsValid } from "../form-builder/validations";
import { CHANGE_PASSWORD } from "../../app/service/login.service";
import "react-datepicker/dist/react-datepicker.css";
import { GET_MODULES_BY_USER_CODES, GET_RESET_MODULE_BY_STUDENT } from "../../app/service/module.service";
import { returnPaginationRange } from "../../app/utility/appUtils";
import VIEWICONS from '../../institute-dashboard-assets/images/view-icons.svg'
import COPY from '../../assets/images/copy_icon.png'
import ENTER from '../../assets/images/enter_icon.png'
import VIEWDATA from '../../assets/images/view-data.png'
import TXN_URL from '../../config/constant';
import { MultiSelect } from "react-multi-select-component";

type RowClickHandler = (rowData: any) => void;

interface Props {
  onActions?: RowClickHandler
  tableData: any;
  TableCols: DataTableCol[];
  searchText?: any;
  pageNumbers?: any;
  pageNumber: (value: any) => void;
  activePageNumber?: number;
  editInfo?: any;
  deleteInfo?: any;
  onChange?: (value: any) => void;
  onQuestionUpload?: (value: any) => void;
  type?: 'default' | 'classic';
  onUpdateItem?: (value: any, status: number) => void;
  requiredPagination?: any
}

export const DataTable: React.FC<Props> = (props) => {
  const [activePage, setActivePage] = useState(getActivePage());
  const [canShowPopup, setCanShowPopup] = useState(false);
  const [canShowResetPopup, setCanShowResetPopup] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FormControlError[]>(
    []
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState<any>();
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loginData, setLoginData] = useState<any>({});
  const [usersName, setUsersName] = useState("");
  const [canShowDeletePopup, setCanShowDeletePopup] = useState(false);
  const [selectedData, setSelectedData] = useState<any>({});
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [canShowDefaultArrow, setCanShowDefaultArrow] = useState(true);
  const [resetData, setResetData] = useState<any>({});
  const history = useHistory();
  const formValidations = [
    new FormField("user_name", []),
    new FormField("new_password", [FormValidators.REQUIRED]),
    new FormField("confirm_password", [FormValidators.REQUIRED]),
  ];
  const [moduleList, setModuleList] = useState<any[]>([]);
  const [moduleSelected, setModuleSelected] = useState<any>([]);
  let totalPage = props.pageNumbers.length
  let limit = 10;
  let page: any = props.activePageNumber;
  const arrayOfPages = returnPaginationRange(props.pageNumbers.length, props.activePageNumber, 10, 1)


  useEffect(() => {
    // setActivePage(props.activePageNumber);
    const userRole = localStorage.getItem("user_role");
    setUserRole(userRole);

  }, []);


  function getActivePage() {
    return props.activePageNumber ? props.activePageNumber : 1;
  }

  function handleRowClick(el: any, item: any) {

    if (el.control === "name_of_school" && (userRole === "CHAIRMAN" || userRole === "INSTITUTE_ADMIN" || userRole === "GENERAL_SECRETARY")) {
      localStorage.setItem("openSchoolId", item.id);
      history.push(
        `/institute-dashboard/manage-school/list/info/${item.id}/teacherslist/${item.school_code}`
      );
      // history.push(`/institute-dashboard/school/info/${item.id}/principallist/${item.school_code}`)
    }
    else if (el.control === "name_of_school") {
      history.push(
        `/dashboard/school/info/${item.id}/principallist/${item.school_code}`
      );
    }

    else if (el.control === "individual_student") {
      history.push(
        `/dashboard/individual-student/info/${item.id}/parentlist/${item.user_code}/${item.institute_school_code}`
      );
    } else if (el.control === "name_of_organization") {
      history.push(
        `/dashboard/institute/info/${item.id}/chairmanlist/${item.organization_code}`
      );
    } else if (el.title === "Student Name" && (userRole === "CHAIRMAN" || userRole === "INSTITUTE_ADMIN" || userRole === "GENERAL_SECRETARY")) {
      history.push(
        `/institute-dashboard/school/student/info/${item.id}/parentlist/${item.institute_school_code}/${item.user_code}`
      );
    }
    else if (el.title === "Student Name") {
      history.push(
        `/dashboard/school/student/info/${item.id}/parentlist/${item.institute_school_code}/${item.user_code}`
      );
    }
    else if (el.control === 'promotion_name') {
      history.push(
        `/dashboard/coupon-report/coupon-code/${item.promotion_uuid}`
      );
    }
    // else if (el.isCoustomControl === "board_name") {
    //   history.push(
    //     `/dashboard/module/info/ABHYAS/${item.modulecode}/${item.moduleid}/worksheet/${item.uuid}/medium`
    //   );
    // }

  }

  function onClickPage(number: number) {
    let dots: any = "..."

    if (activePage !== 1 && number === dots) {
      props.pageNumber(1);
      setActivePage(1);
    } else if (activePage !== props.pageNumbers.length && number === dots) {
      props.pageNumber(totalPage - 1);
      setActivePage(totalPage - 1);
    }

    else {
      props.pageNumber(number);
      setActivePage(number);
    }

  }
  const onClickDoubbleArrows = (arrow: any) => {
    if (arrow === "&raquo;") {
      props.pageNumber(totalPage);
      setActivePage(totalPage);
    } else {
      props.pageNumber(1);
      setActivePage(1);
    }
  }


  function onClickEdit(item: any) {
    if (props.editInfo) {
      props.editInfo(item);
    }
  }
  function viewDetails(item: any) {
    history.push(`/dashboard/sponsor/payment-details/${item.uuid}`);
  }

  function onClickModulesEdit(item: any) {
    history.push(`/dashboard/module/info/form/${item.module_code}/${item.id}`);
  }

  function onClickDelete(item: any) {
    setCanShowDeletePopup(true);
    setSelectedData(item);
  }

  const onDeleteConformation = () => {
    if (props.deleteInfo) {
      props.deleteInfo(selectedData);
      handleDeleteClose();
    }
  };

  function onChangePassword(item: any) {
    setUsersName(item.user_name);
    setCanShowPopup(true);
  }

  function onChangeResetModules(item: any) {
    setModuleSelected([]);
    setModuleList([]);
    setResetData(item);
    getModulesByUserCode(item);
    setCanShowResetPopup(true);
  }
  const getModulesByUserCode = (data: any) => {
    GET_MODULES_BY_USER_CODES(data?.user_code).then((res: any) => {
      if (res) {
        if (res.status === 'fail') {
          toast.error(res.message);
        } else {
          const data = res?.filter((el: any) => el.module_code !== 'CAS-PARENT');
          let preData: any = [];
          let mathData = ["MNS", "MLA-1", "MLA-2"];
          let mathPreElemets: any = [];
          for (let x = 0; x < data.length; x++) {
            const element = data[x];
            if (data[x].module_code !== "MATHS") {
              preData.push({
                label: data[x].module_name.charAt(0).toUpperCase() + data[x].module_name.slice(1).toLowerCase(),
                value: data[x].module_code
              })
            }
            if (data[x].module_code === "MATHS") {
              for (let z = 0; z < mathData.length; z++) {
                preData.push({
                  label: `Maths - ${mathData[z]}`,
                  value: `${mathData[z]}`
                })
              }
            }
          }
          let preElements: any = [...mathPreElemets, ...preData];
          setModuleList(preElements);
        }
      }
    });
  };

  const handleReset = () => {
    getResetStudentModules();
  };

  function getResetStudentModules() {
    let modules = moduleSelected.map((item: any) => item.value).join(',');
    GET_RESET_MODULE_BY_STUDENT(
      resetData.user_code,
      resetData.institute_school_code,
      modules
    ).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.message);
      } else {
        toast.success(` ${modules} ${moduleSelected.length > 1 ? "Modules " : " Module"} reset successfully.`);
        setCanShowResetPopup(false);
      }
    });
  }

  const handleResetclose = () => {
    setResetData({});
    setCanShowResetPopup(false);
  };

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  };

  const handleInput = (data: any) => {
    setLoginData(data);
    const errors: any = FormValidator(formValidations, data.value);
    setValidationErrors(errors);
  };

  const handleclose = () => {
    setCanShowPopup(false);
  };

  function onClickLogin() {
    setIsFormSubmitted(true);
    const loginsData = { ...loginData.value };

    const errors: FormControlError[] = FormValidator(
      formValidations,
      loginsData
    );
    setValidationErrors(errors);
    if (errors.length < 1) {
      setLoading(true);
      if (loginsData.new_password === loginsData.confirm_password) {
        const payload = {
          user_name: usersName,
          password: loginsData.new_password,
        };

        CHANGE_PASSWORD(payload).then((res: any) => {
          if (res.status === "fail") {
            setErrorMessage(res.message);
            setLoading(false);
          } else {
            setLoading(false);
            toast.success("Password updated successfully");
            setCanShowPopup(false);
          }
        });
      } else {
        setPasswordErrorMessage(true);
        setLoading(false);
      }
    }
  }

  function onClickQuestionsDetail(item: any) {
    if (props.onChange) {
      props.onChange(item);
    }
  }
  function onClickSponsorPayment(item: any) {
    let webUrl = item.payment_link;
    window.open(webUrl, '_blank', 'noopener,noreferrer');
  }
  const unsecuredCopyToClipboard = (text: any) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };
  function onCopy(item: any) {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(item?.payment_link)
    }
    else {
      unsecuredCopyToClipboard(item?.payment_link);
    }
  }

  function onCopyCopunCode(item: any) {

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(item?.promo_code)
    }
    else {
      unsecuredCopyToClipboard(item?.promo_code);
    }
  }
  function onClickQuestionsUpload(item: any) {
    if (props.onQuestionUpload) {
      props.onQuestionUpload(item);
    }
  }
  function onClickPromoCode(item: any) {
    history.push(
      `/dashboard/promotion/promocode/list/${item.uuid}`
    );
  }
  function onClickParentModuleView(item: any) {
    history.push(
      `/dashboard/reports/${item.user_module_id}/${item.user_code}/${item.student_code}`
    );
  }

  function onClickModuleGridView(item: any) {
    if (
      userRole === "STUDENT" &&
      (item.module_code === "CAS" || item.module_code === "CAS-PARENT")
    ) {
      // history.push(`/dashboard/questions/${item.user_code}/${item.module_code}`);
      history.push(
        `/dashboard/questions/${item?.user_details?.user_code}/${item.module_code}`
      );
    } else if (item.module_code === "MATHS") {
      if (item?.parent_details.father || item?.parent_details.mother) {
        history.push(
          `/dashboard/mns/test-level-status/${item.uuid}/${item.user_details.user_code}/${item.schoolCode}`
        );
      } else {
        toast.error("Please add parent or guardian details");
      }
    } else {
      history.push(
        `/dashboard/questions/${item?.user_details?.user_code}/${item.module_code}`
      );
    }
  }

  function onClickParentCardsView(item: any) {
    if (userRole === "PARENT") {
      history.push(
        `/dashboard/student/view/${item.student_code}/${item.user_code}`
      );
    }
    else if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
      history.push(`/institute-dashboard/dashboard/active-studentsmoduleslist/${item.user_code}`);

    } else {
      history.push(`/dashboard/student/view/${item.user_code}/${"0"}`);
    }

    // else {
    //   history.push(`/institute-dashboard/student/view/${item.user_code}/${"0"}`);
    // }
  }

  function onClickNewPasswordEyeIcon() {
    setNewPassword(!newPassword);
  }

  function onClickConfirmPasswordEyeIcon() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  function onClickSchoolDetails(data: any) {
    if (props.onChange) {
      props.onChange(data);
    }
  }

  function onClickRoleDetails(data: any) {
    if (props.onChange) {
      props.onChange(data);
    }
  }
  function onClickCouponDetails(data: any) {
    if (props.onChange) {
      props.onChange(data);
    }
  }
  function showStudentsReports(data: any) {
    if (props.onChange) {
      props.onChange(data);
    }
  }

  function onClickPaymentView(item: any) {
    if (props.onChange) {
      props.onChange(item);
    }
  }

  function onClickNextPage() {
    props.pageNumber(activePage + 1);
    // setActivePage(activePage);
    setActivePage(activePage + 1);
  }

  function onClickPreviousPage() {
    props.pageNumber(activePage - 1);
    // setActivePage(activePage);
    setActivePage(activePage - 1);
  }

  function onClickReportView(item: any, type: any) {
    if (type === "CAS") {
      history.push(
        `/dashboard/reports/${item.id}/${item.module_uuid}/${item.user_code}`
      );
    } else if (type === "MATHS") {
      history.push(`/dashboard/maths/reports/student/${item.user_code}`);
    }
  }

  function onClickSchoolCASReportView(item: any, columnName: any) {
    if (columnName === "cas_report-complete_count") {
      history.push(
        `/dashboard/cas-report/school/info/${item.school_code}/studentlist`
      );
    } else if (columnName === "subscription_report-complete_count") {
      history.push(
        `/dashboard/subscription-report/school/info/${item.school_code}/studentlist`
      );
    } else if (columnName === "maths_report") {
      history.push(
        `/dashboard/maths-report/school/info/${item.school_code}/studentlist`
      );
    }
  }

  const handleDeleteClose = () => {
    setCanShowDeletePopup(false);
  };

  const onChangePasswordValidation = (event: any) => {
    setPasswordError("");
    const reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (event) {
      if (!reg.test(event.trim())) {
        setPasswordError(
          "Password minimum of 6 character, at least one capital letter, at least one special character, at least one number"
        );
      } else {
        return true;
      }
    } else {
      setPasswordError("Please enter password");
    }
  };

  const onChangeConfirmPasswordValidation = (event: any) => {
    setConfirmPasswordError("");
    const reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (event) {
      if (!reg.test(event.trim())) {
        setConfirmPasswordError(
          "Confirm password minimum of 6 character, at least one capital letter, at least one special character, at least one number"
        );
      } else {
        return true;
      }
    } else {
      setConfirmPasswordError("Please enter confirm password");
    }
  };

  const onSortingField = (data: any) => {
    setCanShowDefaultArrow(false);
    const order =
      data === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(data);
    setSortingOrder(order);
    if (data) {
      const reversed = order === "asc" ? 1 : -1;
      const preparedData = props.tableData.sort((a: any, b: any) => {
        const aValue = a[data] ?? ""; // If a[data] is null, use an empty string as a default value
        const bValue = b[data] ?? ""; // If b[data] is null, use an empty string as a default value
        return reversed * aValue.toString().localeCompare(bValue.toString());
      });
      // const preparedData = props.tableData.sort(
      //   (a: any, b: any) => reversed * a[data].toString().localeCompare(b[data] ?? 0)
      // );
    }
  };

  function getPaymentInfo(item: any, el: any) {
    if (
      item.amount_status === "COMPLETED" &&
      item.module_status !== "completed"
    ) {
      return true;
    }
  }
  const onAction = (el: any, data: any, type: string) => {
    if (el.isLink) {
      const actionData: any = { data, type };
      props.onActions?.(actionData);
    }

  };
  const onSelectedModule = (e: any) => {
    if (e) {
      setModuleSelected(e);
    }
  }
  const handleUserStatusChange = (e: any, item: any) => {
    if (props?.onUpdateItem) {
      const status = e.target.checked === true ? 1 : 0;
      props?.onUpdateItem(item, status);
    }
  }
  return (
    <div>
      <div className={`modal fade parent-modal-fade ${canShowPopup ? "show" : "false"}`} style={{ display: canShowPopup ? "block" : "none" }}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"> Set Password </h5>
              <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close"
                onClick={handleclose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row  justify-content-md-center login-row">
                  {loading && (
                    <div className="text-center p-5">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  {!loading && (
                    <div
                      className="col-md-12 login-fileds"
                      style={{ border: "none" }}
                    >
                      <FormBuilder onUpdate={handleInput}>
                        <form>
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control fw-light"
                              disabled
                              defaultValue={usersName}
                              name="user_name"
                              placeholder="Please enter user Id"
                            />
                            <label htmlFor="floatingInput">User Name</label>
                          </div>
                          <div className="form-floating">
                            <input
                              type={newPassword ? "text" : "password"}
                              className="form-control fw-light"
                              name="new_password"
                              placeholder="New password"
                              onChange={(e) =>
                                onChangePasswordValidation(e.target.value)
                              }
                            />
                            <span
                              className="eye-icon cursor-pointer"
                              onClick={() => onClickNewPasswordEyeIcon()}
                            >
                              <i
                                className={
                                  newPassword
                                    ? "bi bi-eye-fill"
                                    : "bi bi-eye-slash"
                                }
                              ></i>
                            </span>
                            <label htmlFor="floatingPassword">
                              New Password
                            </label>
                            {passwordError && (
                              <p className="text-danger">{passwordError}</p>
                            )}

                            {/* {isFormSubmitted && !getInputValid('new_password') && <p className="text-danger">Please Enter Password</p>} */}
                          </div>
                          <div className="form-floating">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className="form-control fw-light"
                              onChange={(e) =>
                                onChangeConfirmPasswordValidation(
                                  e.target.value
                                )
                              }
                              name="confirm_password"
                              placeholder="Confirm password"
                            />
                            <span
                              className="eye-icon cursor-pointer"
                              onClick={() => onClickConfirmPasswordEyeIcon()}
                            >
                              <i
                                className={
                                  showConfirmPassword
                                    ? "bi bi-eye-fill"
                                    : "bi bi-eye-slash"
                                }
                              ></i>
                            </span>
                            <label htmlFor="floatingPassword">
                              Confirm Password
                            </label>
                            {confirmPasswordError && (
                              <p className="text-danger">
                                {confirmPasswordError}
                              </p>
                            )}

                            {/* {isFormSubmitted && !getInputValid('confirm_password') && <p className="text-danger">Please Enter Confirm Password</p>} */}
                          </div>
                        </form>
                      </FormBuilder>
                      <div
                        className="text-center mb-1 mt-2"
                        style={{ color: "red" }}
                      >
                        {errorMessage}
                      </div>
                      {passwordErrorMessage && (
                        <div
                          className="text-center mb-1 mt-2"
                          style={{ color: "red" }}
                        >
                          Confirm password not matching.
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="text-center text-lg-start">
                <button
                  className="btn btn-primary px-5 fw-light cursor-pointer"
                  onClick={() => onClickLogin()}
                >
                  Set Password
                </button>
                <br />
              </div>
              <button
                type="button"
                className="btn btn-secondary cursor-pointer"
                data-dismiss="modal"
                onClick={handleclose}
              >
                Close
              </button>
              &nbsp;
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${canShowDeletePopup ? "show" : "false"}`}
        style={{ display: canShowDeletePopup ? "block" : "none" }}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete
              </h5>
              <button
                type="button"
                className="close popup-close cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleDeleteClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row py-3">
              <div className="col-md-12 text-center m">
                <h5>
                  Do you want to delete this{" "}
                  {selectedData?.name ? selectedData?.name : ""} record?
                </h5>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary fw-light cursor-pointer"
                onClick={() => onDeleteConformation()}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary cursor-pointer"
                data-dismiss="modal"
                onClick={handleDeleteClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${canShowResetPopup ? "show" : "false"}`}
        style={{ display: canShowResetPopup ? "block" : "none" }}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Are You Sure Want To Reset Progress
              </h5>
              <button
                type="button"
                className="close popup-close cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleResetclose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-5 mt-3">
                <label className="form-label mb-2 f16 fw-bold">Choose Modules</label>
                <MultiSelect
                  options={moduleList}
                  value={moduleSelected}
                  onChange={(e: any) => onSelectedModule(e)}
                  labelledBy="Select"
                />
              </div>
            </div>
            <div className="modal-footer">
              {moduleSelected?.length > 0 && <button
                type="button"
                className="btn btn-primary cursor-pointer"
                onClick={handleReset}
              >
                Reset
              </button>}
              <button
                type="button"
                className="btn btn-secondary cursor-pointer"
                data-dismiss="modal"
                onClick={handleResetclose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`${props.type === 'classic' ? "table-responsive" : "mar-t-20 custom-scroll table-responsive"}`}>
        <table className={`table  ${props.type === 'classic' ? "table_new_ui" : "fixed-table-header mb-0"}`}>
          <thead>
            <tr>
              {props.TableCols.map((el, index) => (
                <React.Fragment key={index}>
                  {el.canShowColumn && <th onClick={() => el.sortable ? onSortingField(el.control) : null}>
                    {<span>
                      {el.title}
                    </span>}
                    {canShowDefaultArrow && index === 0 && el.sortable && (
                      <i className="bi bi-arrow-down cursor-pointer p-2"></i>
                    )}
                    {sortingField && sortingField === el.control && (
                      <i className={`${sortingOrder === "asc" ? "bi bi-arrow-down cursor-pointer p-2"
                        : "bi bi-arrow-up cursor-pointer p-2"}`}>
                      </i>
                    )}
                  </th>}
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {props?.tableData?.map((item: any, i: number) => (
              <tr key={i}>
                {props.TableCols.map((el, index) => (
                  <React.Fragment key={index}>
                    {el.canShowColumn && <td
                      className={el.control === "name_of_organization" || el.control === "name_of_school" || el.title === "Student Name" || el.control === 'promotion_name' || el.isLink == true ||
                        el.control === "individual_student" ? userRole === "PARENT" ? "" : "active-control" : ""}
                      onClick={() => { handleRowClick(el, item); onAction(el, item, "link") }}
                    >
                      {(el.title === "Students" && item.sponsorship_name) && (
                        <img className='eye-icons ms-1 cursor-pointer ms-2' src={VIEWICONS} onClick={() => showStudentsReports(item)} />
                      )}
                      {el.title !== "Reports Info" ? item[el.control] : ""}
                      {el.isViewData && <span className="" onClick={() => onClickCouponDetails(item)}> <img src={VIEWDATA} alt="data" height={20}></img></span>}
                      {el.title === "Actions" && (
                        <div className="actions-view">
                          <span>
                            {el.editModule && <i title="Edit"
                              className="bi bi-pencil-square cursor-pointer edit-icon p-2 "
                              onClick={() => onClickModulesEdit(item)}
                            ></i>}

                            {(el.isEdit && item.payment_status !== "completed" && item.status !== 2) && <i title="Edit" className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onClickEdit(item)}>
                            </i>}
                            {(el.isViewDetails && item.payment_status === "completed") && <img title="View" className='eye-icons ms-1 cursor-pointer ms-2' src={VIEWICONS} onClick={() => viewDetails(item)} />
                            }
                            {(el.isDelete && item.payment_status !== "completed") && <i title="Delete" className="bi bi-trash cursor-pointer p-2" onClick={() => onClickDelete(item)} >
                            </i>}
                            {el.isView &&
                              <span>
                                <i title="View"
                                  className="bi bi-eye-fill cursor-pointer p-2"
                                  onClick={() => onClickSchoolDetails(item)}
                                ></i>
                              </span>
                            }
                          </span>
                          {el.isPrivileges &&
                            <span title="Privileges">
                              <svg onClick={() => onClickRoleDetails(item)} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-person-lock cursor-pointer ms-1" viewBox="0 0 16 16">
                                <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 5.996V14H3s-1 0-1-1 1-4 6-4c.564 0 1.077.038 1.544.107a4.524 4.524 0 0 0-.803.918A10.46 10.46 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h5ZM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z" />
                              </svg>
                            </span>
                          }

                          {el.setPassword &&
                            <i title="Password"
                              className="bi bi-unlock cursor-pointer p-2"
                              onClick={() => onChangePassword(item)}
                            ></i>
                          }

                          {el.resetProgress &&
                            <i
                              className="bi bi-arrow-repeat cursor-pointer p-2"
                              onClick={() => onChangeResetModules(item)}
                            ></i>
                          }

                          {el.viewMore &&
                            <div>
                              <button
                                className="px-3 mb-2 cursor-pointer msb-btn"
                                onClick={() => onClickPaymentView(item)}
                              >
                                View More
                              </button>
                              &nbsp;
                            </div>
                          }
                        </div>
                      )}

                      {(el.title === "Coupon Code" && item.status != 2) && (
                        <span className="ms-3" onClick={() => onCopyCopunCode(item)} title="Copy">
                          <img src={COPY} alt="copy" height={20} className="cursor-pointer" />
                        </span>
                      )}

                      {(el.title === "Status" && item.promo_code) && (
                        item.status == 1 ? <p className="f16 mb-0 text-completed" style={{ fontWeight: 'bold' }}>Active</p> :
                          (item.status == 2 ? <p className="f16 mb-0 active" style={{ fontWeight: 'bold' }}> Applied </p> : <p className="f16 mb-0 text-inprogess" style={{ fontWeight: 'bold' }}>InActive</p>)
                      )}
                      {(el.title === "Status" && item.promo_code && (item.status == 1 || item.status == 0)) && (
                        <p className="mb-1">   <label className="switch_table">
                          <input type="checkbox" name="active" value={item?.status} checked={item?.status === 1}
                            onChange={(e) => { handleUserStatusChange(e, item) }}
                          />
                          <span className="slider_table round"></span>
                        </label></p>
                      )}
                      {el.title === 'Coupon Codes' &&
                        <span className="ms-3" onClick={() => onClickPromoCode(item)} title="view">
                          <img src={ENTER} alt="view" height={20} className="cursor-pointer" />
                        </span>

                      }
                      {el.control === "payments_link" && (
                        item.payment_status === "pending" ? (item.linkExpired === "expired" ? <p className="f16 mb-0 text-inprogess" style={{ fontWeight: 'bold' }}>Expired</p> : <><button
                          className="px-2 f14 mb-2 cursor-pointer msb-btn"
                          onClick={() => onClickSponsorPayment(item)}
                        >
                          View Payment Page
                        </button> <span onClick={() => onCopy(item)} title="Copy">
                            <img src={COPY} alt="copy" height={20} className="cursor-pointer" />
                          </span></>) :
                          <p className="f16 mb-0 text-completed" style={{ fontWeight: 'bold' }}>Completed</p>
                      )}
                      {el.control === "questions_info" && (
                        <div>
                          <button
                            className="px-3 mb-2 cursor-pointer msb-btn"
                            onClick={() => onClickQuestionsDetail(item)}
                          >
                            Details
                          </button>
                          &nbsp;
                          <button
                            className="px-3 mb-2 cursor-pointer msb-btn"
                            onClick={() => onClickQuestionsUpload(item)}
                          >
                            Upload
                          </button>
                          &nbsp;
                        </div>
                      )}
                      {el.control === "cas_report_info" && (
                        <div>
                          <button
                            className="px-3 mb-2 cursor-pointer msb-btn"
                            onClick={() => onClickParentModuleView(item)}
                          >
                            Details
                          </button>
                          &nbsp;
                        </div>
                      )}

                      {el.title === "Reports Info" &&
                        el.control === "module_status" && (
                          <div>
                            {getPaymentInfo(item, el) && (
                              <div>
                                <button
                                  className="px-3 mb-2 cursor-pointer msb-btn"
                                  onClick={() => onClickModuleGridView(item)}
                                >
                                  Take Survey
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      {el.control === "show_cards" && (
                        <div>
                          <button
                            className="px-3 mb-2 cursor-pointer msb-btn"
                            onClick={() => onClickParentCardsView(item)}
                          >
                            Module List
                          </button>
                          &nbsp;
                        </div>
                      )}
                      {el.control === "student_cas_view_report" && (
                        <div>
                          <button
                            className="px-3 mb-2 cursor-pointer msb-btn"
                            onClick={() => onClickReportView(item, "CAS")}
                          >
                            View CAS Report
                          </button>
                          &nbsp;
                        </div>
                      )}
                      {el.control === "maths_view_report" && (
                        <div>
                          <button
                            className="px-3 mb-2 cursor-pointer msb-btn"
                            onClick={() => onClickReportView(item, "MATHS")}
                          >
                            View Maths Report
                          </button>
                          &nbsp;
                        </div>
                      )}
                      {(el.control === "cas_report-complete_count" ||
                        el.control === "subscription_report-complete_count" ||
                        el.control === "maths_report") && (
                          <div>
                            <button
                              className="px-3 mb-2 cursor-pointer msb-btn"
                              onClick={() =>
                                onClickSchoolCASReportView(item, el.control)
                              }
                            >
                              View Report
                            </button>
                            &nbsp;
                          </div>
                        )}
                    </td>}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {
        props.tableData.length === 0 && (
          <div className="mt-3 text-center">No records found.</div>
        )
      }
      {
        (props.tableData.length > 0 && !props?.requiredPagination) && (
          <nav className="mt-3" style={{ float: "right" }}>
            {props.pageNumbers && (
              <ul className="pagination">
                <li className="page-item" onClick={() => onClickDoubbleArrows("&laquo;")}><span className={`page-link prev ${activePage == 1 ? "disabled" : ""}`}>&laquo;</span></li>
                <li className="page-item">
                  <button className={`page-link prev ${activePage == 1 ? "disabled" : ""}`} onClick={() => onClickPreviousPage()}>
                    &lsaquo;
                  </button>
                </li>

                {props.pageNumbers.length > 5 &&
                  arrayOfPages.map((value: any, i: number) => (
                    <li key={i} className={activePage == value ? "active page-item" : "page-item"} onClick={() => onClickPage(value)}><span className="page-link">{value}</span></li>

                  ))
                }

                {props.pageNumbers.length <= 5 &&
                  props.pageNumbers.map((number: number, i: number) => (
                    <li className={activePage == number ? "active page-item" : "page-item"} key={i}>
                      <button className="page-link" onClick={() => onClickPage(number)}>
                        {number}
                      </button>
                    </li>
                  ))}
                <li className="page-item">
                  <button className={`page-link next ${activePage == props.pageNumbers.length ? "disabled" : ""}`} onClick={() => onClickNextPage()}>
                    &rsaquo;
                  </button>

                </li>
                <li className="page-item" onClick={() => onClickDoubbleArrows("&raquo;")}><span className={`page-link next ${activePage == props.pageNumbers.length ? "disabled" : ""}`}>&raquo;</span></li>

              </ul>
            )}
          </nav>
        )
      }
    </div >
  );
};
