import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_SPONSOR_SCHOOL_INSTITUTE_ID, GET_STUDNETS_DATA_BY_SCHOOL_CODE } from "../../../app/service/sponsor";
import { GET_STUDENTS_BY_STANDARD_DIVISION } from "../../../app/service/users.service";
import { MultiSelect } from "react-multi-select-component";
import FormBuilder from "../../../components/form-builder";
import { CREATE_PROMO_CODES, GET_PROMO_CODES_BY_UUID, UPDATE_PROMO_CODES } from "../../../app/service/promotions";
import { Link, useHistory } from "react-router-dom";
import { GET_STANDARD_DIVISION_BY_SCHOOL_CODE } from "../../../app/service/school.service";
import { values } from "lodash";

interface Props {
    create: number;
    promotionData: any
    promotionUuid?: any;
    promoCodeUuid?: any;
    currentData?: any;
    institutesData: any;
}

const SpecialDiscountCoupon: React.FC<Props> = (props: Props) => {
    const promotionUuid = props?.promotionUuid;
    const create: number = props?.create;
    const promotionData: any = props?.promotionData;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [instituteData, setInstituteData] = useState<any>(props?.institutesData || []);
    const [schoolsData, setSchoolsData] = useState<any>([]);
    const [studentList, setStudentList] = useState<any>([]);
    const [studentsData, setStudentsData] = useState<any>([]);
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [divisionList, setDivisionList] = useState<any>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<string>('');
    const [selectedNameOfSchool, setSelectedNameOfSchool] = useState<string>('');
    const [selectedStandard, setSelectedStandard] = useState<string>('');
    const [selectedDivision, setSelectedDivision] = useState<string>('');
    const [selectedStudents, setSelectedStudents] = useState<any>([]);
    const [scholLoading, setSchoolLoading] = useState(false);
    const [standardLoading, setStandardLoading] = useState(false);
    const [divisionLoading, setDivisionLoading] = useState(false);
    const [studentLoading, setStudentLoading] = useState(false);
    const currentUrl = window.location.href.split('#')[0];
    const [studentPoupData, setStudentPoupData] = useState([]);
    // const [formData, setFormData] = useState<any>({});
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [checkedSms, setCheckedSms] = useState<any>(1);
    const [checkedStatus, setCheckedStatus] = useState<any>(1);
    const [checkedEmail, setCheckedEmail] = useState<any>(1);
    const [checkedNotification, setCheckedNotification] = useState<any>(1);
    const [discountValue, setDiscountValue] = useState<any>("");
    const [maxYears, setMaxYears] = useState<string>('');
    const [minYears, setMinYears] = useState<string>('');
    const history = useHistory();
    const numbers = [];
    for (let i = 1; i <= 100; i++) {
        numbers.push(i);
    }

    useEffect(() => {
        fecthAllData();
    }, [create, props?.promoCodeUuid]);
    const fecthAllData = async () => {
        setLoading(true);
        try {
            if (create == 1) {
                const responseData: any = await getUpdatedPromoCodeData();
                await handleChange(responseData?.organization_code, true);
                await handleSchoolChange(responseData?.school_code, true);
                if (responseData?.standard) {
                    await handleStandardChange(responseData?.standard, true);
                }
                if (responseData?.division) {
                    setDivisionList([{ division: responseData?.division, values: responseData?.division }]);
                    // await handleDivisionChange(responseData?.division, true);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const getUpdatedPromoCodeData = async () => {
        try {
            const res: any = await GET_PROMO_CODES_BY_UUID(props?.promoCodeUuid)
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                const data = res.rows[0];
                data.algorithm = JSON.parse(data?.algorithm);
                setCurrentFormData(data);
                setCheckedSms(data?.send_sms);
                setCheckedEmail(data?.send_email);
                setCheckedStatus(data?.status);
                setCheckedNotification(data?.send_push_notifications);
                if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
                    setDiscountValue(data?.algorithm[0].discount_amount);
                } else {
                    setDiscountValue(data?.algorithm[0].discount_percentage);
                }
                setMaxYears(data?.algorithm[0].max);
                setMinYears(data?.algorithm[0].min);
                setSelectedOrganization(data?.organization_code);
                setSelectedNameOfSchool(data?.school_code);
                setSelectedStandard(data?.standard);
                setSelectedDivision(data?.division);
                if (data?.students?.length >= 0) {
                    setSelectedStudents(data?.students);
                    const newPreparedData = data.students.map((student: any) => ({
                        label: student.name,
                        value: student.user_code
                    }));
                    setStandardList(newPreparedData);
                    setStudentsSelected(newPreparedData);
                }
                return data;
            }
        } catch (error) {

        }
    }
    const handleChange = async (eventValue: any, updateValue?: boolean) => {
        if (!updateValue) {
            setSchoolsData([]);
            setStandardList([]);
            setDivisionList([]);
            setStudentList([]);
            setStudentsSelected([]);
            setSelectedNameOfSchool('');
            setSelectedStandard('');
            setSelectedDivision('');
            setSelectedStudents([]);
        }
        if (eventValue) {
            setSelectedOrganization(eventValue)
            await getSchoolsByInstituteCode(eventValue);
        } else {
            setSelectedOrganization('');
        }
    }
    const getSchoolsByInstituteCode = async (data: any) => {
        setSchoolLoading(true);
        const res: any = await GET_SPONSOR_SCHOOL_INSTITUTE_ID(data, 0, {});
        if (res.status === 'fail') {
            toast.error(res.message);
            setSchoolLoading(false);
        } else {
            const data = res.rows;
            setSchoolsData(data);
            setSchoolLoading(false);
        }
    }
    const handleSchoolChange = async (eventValue: any, updateValue?: boolean) => {
        if (!updateValue) {
            setStandardList([]);
            setDivisionList([]);
            setStudentList([]);
            setStudentsSelected([]);
            setSelectedStandard('');
            setSelectedDivision('');
            setSelectedStudents([]);
        }
        if (eventValue) {
            getStudentsBySchoolCode(eventValue);
            setSelectedNameOfSchool(eventValue);
            // await getStandardDivisionBySchoolCode(eventValue);
        }
        else {
            setSelectedNameOfSchool('')
        }
    }
    // const getStandardDivisionBySchoolCode = async (schoolsCode: any) => {
    //     try {
    //         const res: any = await GET_STANDARD_DIVISION_BY_SCHOOL_CODE(schoolsCode);
    //         if (res.status === "fail") {
    //             toast.error(res?.err);
    //         } else {
    //             if (res) {
    //                 let data = res;
    //                 if (Array.isArray(data)) {
    //                     const { classes, sections } = data.reduce(removeDuplicatesList, { classes: [], sections: [] });
    //                     sections?.sort((a: string, b: string) => a > b ? 1 : -1);
    //                     console.log(classes, sections, "sections");

    //                     setStandardList(classes);
    //                     setDivisionList(sections);
    //                 }
    //             }
    //         }
    //     } catch (error) {

    //     }

    // }
    // const removeDuplicatesList = (accumulator: any, currentValue: any) => {
    //     if (!accumulator.classes.includes(currentValue.class)) {
    //         accumulator.classes.push(currentValue.class);
    //     }
    //     if (!accumulator.sections.includes(currentValue.section)) {
    //         accumulator.sections.push(currentValue.section);
    //     }
    //     return accumulator;
    // };
    function getStudentsBySchoolCode(data: any) {
        setStandardLoading(true);
        GET_STUDNETS_DATA_BY_SCHOOL_CODE(data, 'STUDENT', 0, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setStandardLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.school_student = element.name;
                    });
                    setStudentsData(data);
                    let preparedStandard: any[] = [];
                    data.filter(function (item: any) {
                        let i = preparedStandard.findIndex((x: any) => (x.standard == item.standard));
                        if (i <= -1) {
                            preparedStandard.push({ standard: item.standard, value: item.standard });
                        }
                        return null;
                    });
                    preparedStandard.sort((a, b) => a.standard - b.standard)
                    setStandardList(preparedStandard);
                    setStandardLoading(false);
                }
            }
        });
    }
    const handleStandardChange = async (eventValue: any, updateValue?: boolean) => {
        if (!updateValue) {
            setDivisionList([]);
            setSelectedDivision('');
            setStudentList([]);
            setStudentsSelected([]);
            setSelectedStudents([]);
        }
        if (eventValue) {
            setDivisionLoading(true);
            getDivisionData(eventValue);

            // setTimeout(() => {
            //     setDivisionLoading(false);
            // }, 1000);
            setSelectedStandard(eventValue);
            let preparedData = {
                standard: eventValue,
            }
            await getStudentsByStandardDivision(preparedData);

        } else {
            setSelectedStandard('');
        }
    }
    function getDivisionData(e: any) {
        let preparedDivison: any[] = [];
        let element = studentsData.filter((ele: any) => {
            if (ele.standard == e) {

                return ele.division;
            }
        });
        element.filter(function (item: any) {
            let i = preparedDivison.findIndex((x: any) => (x.division == item.division));
            if (i <= -1) {
                preparedDivison.push({ division: item.division, value: item.division });
            }
            return null;
        });
        preparedDivison.sort((a, b) => a.division.localeCompare(b.division));
        setDivisionList(JSON.parse(JSON.stringify(preparedDivison)));
        setDivisionLoading(false);

    }
    const getStudentsByStandardDivision = async (data: any) => {
        try {
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            const res: any = await GET_STUDENTS_BY_STANDARD_DIVISION(selectedNameOfSchool, data);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setStudentLoading(false);
                } else {
                    const data = res.rows;
                    const preparedData = [];
                    if (data.length > 0) {
                        for (let k = 0; k < data.length; k++) {
                            preparedData.push({
                                label: data[k].name,
                                value: data[k].user_code
                            });
                        }
                        setStudentList(preparedData);
                        setStudentPoupData(data);
                        setStudentLoading(false);

                    } else {
                        setStudentList([]);
                        setStudentLoading(false);
                    }
                    setStudentLoading(false);
                }
            }
        } catch (error) {

        }
    }
    const handleDivisionChange = async (eventValue: any) => {
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedStudents([]);
        if (eventValue) {
            setStudentLoading(true);
            let preparedData = {
                standard: selectedStandard,
                division: eventValue,
            }
            await getStudentsByStandardDivision(preparedData);
            setSelectedDivision(eventValue);

        } else {
            setSelectedDivision('');
            let preparedData = {
                standard: selectedStandard
            }
            await getStudentsByStandardDivision(preparedData);
        }
    }
    function onChangeMultiSelectStudents(e: any) {
        setStudentsSelected(e);
        let preparedStudents: any = [];
        studentPoupData?.filter((ele: any, i: number) => {
            for (let x = 0; x < e.length; x++) {
                if (e[x].value === ele.user_code) {
                    preparedStudents.push({
                        name: ele.name,
                        user_code: ele.user_code,
                        mobile_number: ele.mobile_number,
                        email_id: ele.email_id,
                        institute_school_code: ele.institute_school_code
                    })
                }
            }
        })
        setSelectedStudents(preparedStudents);

        // sponsorDataName.students = preparedStudents;
    }
    const handleInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        // setFormData(data);
    };

    const handleUserStatusChange = (e: any) => {
        if (currentFormData) {
            const status = e.target.checked === true ? 1 : 0;
            currentFormData.status = e.target.checked === true ? 1 : 0;
            setCheckedStatus(status);
            setCheckedSms(status);
            setCheckedEmail(status);
            setCheckedNotification(status);
        }
    }
    const handleUserSmsChange = (e: any) => {
        const status = e.target.checked === true ? 1 : 0;
        setCheckedSms(status);
    }
    const handleUserEmailChange = (e: any) => {
        const status = e.target.checked === true ? 1 : 0;
        setCheckedEmail(status);
    }
    const handleUserNotificationChange = (e: any) => {
        const status = e.target.checked === true ? 1 : 0;
        setCheckedNotification(status);
    }
    function createPromoCode() {
        setIsFormSubmitted(true);
        let preparedData: any = []
        if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
            preparedData.push({
                min: parseInt(minYears),
                max: parseInt(maxYears),
                discount_amount: parseInt(discountValue)
            })
        } else {
            preparedData.push({
                min: parseInt(minYears),
                max: parseInt(maxYears),
                discount_percentage: parseInt(discountValue)
            })
        }
        const payload = {
            promotion_uuid: props?.promotionUuid,
            algorithm: preparedData,
            organization_code: selectedOrganization,
            school_code: selectedNameOfSchool,
            txn_id: "",
            standard: selectedStandard,
            division: selectedDivision,
            send_sms: checkedSms,
            send_email: checkedEmail,
            send_push_notifications: checkedNotification,
            payment_url: `${currentUrl}#/student-dashboard/list/user/payments`,
            students: selectedStudents,
            status: checkedStatus
        }
        if (validateForm()) {
            setLoading(true);
            CREATE_PROMO_CODES(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success(res.message);
                    history.push(`/dashboard/promotion/promocode/list/${promotionUuid}`);
                    setLoading(false);
                }
            });
        }
    }
    function updatePromoCode() {
        setIsFormSubmitted(true);
        let preparedData: any = []
        if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
            preparedData.push({
                min: parseInt(minYears),
                max: parseInt(maxYears),
                discount_amount: parseInt(discountValue)
            })
        } else {
            preparedData.push({
                min: parseInt(minYears),
                max: parseInt(maxYears),
                discount_percentage: parseInt(discountValue)
            })
        }
        const payload = {
            promotion_uuid: props?.promotionUuid,
            algorithm: preparedData,
            organization_code: selectedOrganization,
            school_code: selectedNameOfSchool,
            txn_id: "",
            standard: selectedStandard,
            division: selectedDivision,
            send_sms: checkedSms,
            send_email: checkedEmail,
            send_push_notifications: checkedNotification,
            payment_url: `${currentUrl}#/student-dashboard/list/user/payments`,
            students: selectedStudents,
            status: checkedStatus
        }
        if (validateForm()) {
            setLoading(true);
            UPDATE_PROMO_CODES(currentFormData?.uuid, payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    toast.success(res.message);
                    history.push(`/dashboard/promotion/promocode/list/${promotionUuid}`);
                    setLoading(false);
                }
            });
        }
    }
    function validateForm() {
        let isValid = true;
        if (!discountValue || !selectedOrganization || !selectedNameOfSchool || !maxYears || !minYears) {
            isValid = false;
        }
        return isValid;
    }
    const handleMaxYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Ensure only numbers are entered
        setMaxYears(value);
    }
    const handleMinYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Ensure only numbers are entered
        setMinYears(value);
    }
    const handleFlateValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Ensure only numbers are entered
        setDiscountValue(value);
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div style={{ borderBottom: 'solid 1px black', paddingLeft: '10px' }} className="mb-4">
                    <h5 className="form-label mb-2 d-block">Coupon Code Details</h5>
                </div>
                <div>
                    <div className="row custom-form">
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label className="form-label">Coupon Status</label><br />
                                <label className="switch">
                                    <input type="checkbox" name="active" value={checkedStatus} checked={checkedStatus === 1} onChange={(e) => { handleUserStatusChange(e) }} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label className="form-label">Send Sms</label><br />
                                <label className="switch">
                                    <input type="checkbox" name="active" value={checkedSms} checked={checkedSms === 1} onChange={(e) => { handleUserSmsChange(e) }} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label className="form-label">Send Email</label><br />
                                <label className="switch">
                                    <input type="checkbox" name="active" value={checkedEmail} checked={checkedEmail === 1} onChange={(e) => { handleUserEmailChange(e) }} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="mb-4">
                                <label className="form-label">Send Notification</label><br />
                                <label className="switch">
                                    <input type="checkbox" name="active" value={checkedNotification} checked={checkedNotification === 1} onChange={(e) => { handleUserNotificationChange(e) }} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 custom-form">
                            <div className="mb-4">
                                <label className="form-label mb-0 f16">Min Years</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                <input className="form-control form-control-lg f16" type="text" name="min years" placeholder="Enter min years" value={minYears} onChange={handleMinYearChange} />
                                {(isFormSubmitted && !minYears) && <p className="text-danger">Please enter min years</p>}
                            </div>
                        </div>
                        <div className="col-md-4 custom-form">
                            <div className="mb-4">
                                <label className="form-label mb-0 f16">Max Years</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                <input className="form-control form-control-lg f16" type="text" name="max years" placeholder="Enter max years" value={maxYears} onChange={handleMaxYearChange} />
                                {(isFormSubmitted && !maxYears) && <p className="text-danger">Please enter max years</p>}
                            </div>
                        </div>
                        {promotionData.discount_type === "FLAT_VALUE_DISCOUNT" && <div className="col-md-4 custom-form">
                            <div className="mb-4">
                                <label className="form-label mb-0 f16"> Discount Flat Amount</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                <input className="form-control form-control-lg f16" type="text" name="discount_amount" placeholder="Enter discount amount" value={discountValue} onChange={handleFlateValueChange} />
                                {(isFormSubmitted && !discountValue) && <p className="text-danger">Please enter discount amount</p>}
                            </div>
                        </div>}
                        {promotionData.discount_type !== "FLAT_VALUE_DISCOUNT" && <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0 f16">Discount Percentage %</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                <select name="discount_percentage" className="form-select form-select-lg f16" value={discountValue} onChange={(e) => setDiscountValue(e.target.value)}>
                                    <option value="">Select the percentage</option>
                                    {numbers?.map((name: any, i: number) => (
                                        <option key={name} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                                {(isFormSubmitted && !discountValue) && <p className="text-danger">Please select percentage</p>}
                            </div>
                        </div>}
                    </div>
                </div>
                <div>
                    <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
                        <h5 className="form-label mb-2 d-block">Add Schools / Students</h5>
                    </div>
                    {/* {sponsorForm?.map((name: any, i: number) => ( */}
                    <FormBuilder onUpdate={handleInput}>
                        <form>
                            <div className={`row custom-form `}>
                                <div className="col-12">
                                    <div className="row f14">
                                        <div className="col-md-6 col-lg-3">
                                            <div className="mb-4">
                                                <label className="form-label mb-0 mt-2">Name of Institute</label>
                                                <span style={{ color: 'red', fontSize: '20px', paddingLeft: '10px' }}>*</span>
                                                <select name="organization_code" className="form-select form-select-lg mb-3 f14" value={selectedOrganization} onChange={(e) => { handleChange(e.target.value) }}>
                                                    <option value={""}>Select the institute</option>
                                                    {instituteData?.map((name: any, i: number) => (
                                                        <option key={i} value={name.organization_code}>
                                                            {name.name_of_organization}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(isFormSubmitted && !selectedOrganization) && <p className="text-danger">Please select institution</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <div className="mb-4 position-relative">
                                                {scholLoading &&
                                                    <div className="ms-5 ps-5 mt-4 pt-3 position-absolute  align-items-end">
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                <label className="form-label mb-0 mt-2">Name of School </label>
                                                <span style={{ color: 'red', fontSize: '20px', paddingLeft: '10px' }}>*</span>
                                                <select name="school_code" className="form-select form-select-lg mb-3 f16" value={selectedNameOfSchool} onChange={(e) => { handleSchoolChange(e.target.value) }}>
                                                    <option value={""}>Select the school</option>
                                                    {schoolsData?.map((name: any, i: number) => (
                                                        <option key={i} value={name.school_code}>
                                                            {name.name_of_school}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(isFormSubmitted && !selectedNameOfSchool) && <p className="text-danger">Please select school</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-2">
                                            <div className="mb-4 position-relative">
                                                {standardLoading &&
                                                    <div className="ms-5 ps-4 mt-4 pt-3 position-absolute  align-items-end">
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                <label className="form-label mb-0 mt-2">Standard</label>
                                                <select className="form-select form-select-lg mb-3 f16" name="standard" value={selectedStandard} onChange={(e) => { handleStandardChange(e.target.value) }}>
                                                    <option value="">Select standard</option>
                                                    {standardList?.map((data: any, i: number) => {
                                                        return (
                                                            <option value={data.value} key={i}>{data.standard}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-2">
                                            <div className="mb-4 position-relative">
                                                {divisionLoading &&
                                                    <div className="ms-5 ps-4 mt-4 pt-3 position-absolute  align-items-end">
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                <label className="form-label mb-0 mt-2">Division</label>
                                                <select className="form-select form-select-lg mb-3 f16" name="division" value={selectedDivision} onChange={(e) => { handleDivisionChange(e.target.value) }}>
                                                    <option value="">Select division</option>
                                                    {divisionList?.map((data: any, i: number) => {
                                                        return (
                                                            <option value={data.value} key={i}>{data.division}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-2">
                                            <div className="mb-4 mt-md-1 position-relative">
                                                {studentLoading &&
                                                    <div className="ms-5 ps-3 mt-4 pt-3 position-absolute  align-items-end">
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                <label className="form-label mb-2">Students</label>
                                                <MultiSelect className=""
                                                    options={studentList}
                                                    value={studentsSelected}
                                                    // onChange={setStudentsSelected}
                                                    onChange={(e: any) => onChangeMultiSelectStudents(e)}
                                                    labelledBy="Select"

                                                />
                                            </div>
                                            {/* } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    <div className="form-footer border-top border-primary py-3 text-end mt-4">
                        {create == 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createPromoCode()}>Create</a>}
                        {create == 1 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updatePromoCode()}>Update</a>}
                        <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/promotion/promocode/list/${promotionUuid}`}>Cancel</Link>
                    </div>
                </div>
            </div>}
        </div>
    )

}
export default SpecialDiscountCoupon;