import React, { useEffect, useState, useRef } from 'react'
import { PieChart, Pie, Cell, Legend, Tooltip, Sector } from 'recharts';
import { GET_INSTITUTE_DASHBOARD } from '../../../app/service/institute-dashboard.service';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { GET_RUN_CRON_JOB } from '../../../app/service/cron-job';



function Dashboard() {
    const organizationName = localStorage.getItem("organization_name");
    const instituteName = localStorage.getItem("name_of_organizations");
    const userRole = localStorage.getItem("user_role");
    const [dashboardData, setDashboardData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const history = useHistory();
    const [pieData, setPieData] = useState<any>([]);
    const [careerData, setCareerData] = useState<any>();
    const [avgStudents, setAvgStudents] = useState<any>();
    let firstAttemptData: any;
    let secondAttemptData: any;
    const [attemptData, setAttemptData] = useState<any>();
    const [activeData, setActiveData] = useState<any>(1);
    const pieChartColors = ['#0000FF', '#8A2BE', '#A52A2A', '#DC143C', '#006400', '#00CED1', '#FF1493', '#C71585', '#FF4500', '#FF0000', '#EE82EE', '#9ACD32'];
    const pieChartColorsOne = ['#0088FE', '#00C49F', '#FFBB28', '#33691E', '#9768D1', '#BDC6FF', '#FC7D49', '#CD0402', '#BEDB39',
        '#FFF176', '#FA5B0F', '#FE4D98', '#4A148C', '#8C8C8C', '#A5FFD2'];
    const [pieSecondData, setPieSecondData] = useState<any>()
    const [activeStudentValues, setActiveStudentValues] = useState(false);
    const [registerdValues, setRegisterdValues] = useState(false);
    const [schoolValues, setSchoolValues] = useState(false);
    const registerednotificationref = useRef<any>(null);
    const orgnizationCode: any = localStorage.getItem('organization_code');

    useEffect(() => {
        localStorage.setItem("orgnizationCode", orgnizationCode);
        // instituteDashboardDetails();
        fetchAllData();
        cronJob();

    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            await instituteDashboardDetails();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    const cronJob = async () => {
        const payLoad = {
            institute_code: orgnizationCode
        }
        try {
            const res: any = await GET_RUN_CRON_JOB(payLoad);
            if (res) {
                if (res?.status === 'fail') {

                } else {
                    await instituteDashboardDetails();
                }
            }
        } catch (error) {

        }

    }

    const instituteDashboardDetails = async () => {
        const data: any = localStorage.getItem('organization_code');
        // const data= "org335453427";
        const res: any = await GET_INSTITUTE_DASHBOARD(data);
        if (res) {
            if (res.status === 'fail') {
                toast.error(res?.message);
            } else {
                if (res) {
                    const data = res;
                    if (data.cas_student_careers.length > 0) {
                        for (let index = 0; index < data.cas_student_careers.length; index++) {
                            const element = data.cas_student_careers[index];
                            element.percent = (element.count / data.cas_total_registered) * 100
                        }
                    }
                    setDashboardData(data);
                    careerStudents(data.cas_student_careers);
                    averageStudents(data.cas_averages_latest);
                    firstAttempt(data.cas_averages_attempt_1);
                    setPieSecondData(data.cas_averages_attempt_2)
                }
            }
        }
    }

    function careerStudents(data: any) {
        let piedata: any[] = [];
        for (let x = 0; x < data.length; x++) {
            piedata.push({
                name: data[x].values[0],
                students: data[x].percent
            })

        }
        setCareerData(piedata);

    }
    function averageStudents(data: any) {
        let pieData: any[] = [];
        for (const property in data) {
            pieData.push({
                name: property.split(/_/g).map((word: any) =>
                    `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                    .join(" "),
                students: data[property]
            })
        }
        setAvgStudents(pieData);

    }
    function firstAttempt(data: any) {
        let pieData: any[] = [];
        for (const property in data) {
            pieData.push({
                name: property.split(/_/g).map((word: any) =>
                    `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                    .join(" "),
                students: data[property]
            })
        }
        firstAttemptData = pieData;
        setAttemptData(pieData);
    }
    function secondAttempt(pieSecondData: any) {
        let pieData: any[] = [];
        for (const property in pieSecondData) {
            pieData.push({
                name: property.split(/_/g).map((word: any) =>
                    `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                    .join(" "),
                students: pieSecondData[property]
            })
        }
        secondAttemptData = pieData;
        setAttemptData(pieData);

    }

    const RADIAN = Math.PI / 180;

    const handleclose = () => {
        setCanShowPopup(false);
    };

    const naviGations = (urls: any) => {
        if (urls === "schools") {
            if (userRole === "SUPER_ADMIN") {
                history.push("/dashboard/view/registered-schools")
            } else {
                history.push('/institute-dashboard/dashboard/registered-schools')
            }
        } else if (urls === "regitered-students") {
            if (userRole === "SUPER_ADMIN") {
                history.push("/dashboard/view/registered-students")
            } else {
                history.push('/institute-dashboard/dashboard/registered-students')
            }
        }

        else if (urls === "active-students") {
            if (userRole === "SUPER_ADMIN") {
                history.push("/dashboard/view/active-students")
            } else {
                history.push('/institute-dashboard/dashboard/active-students')
            }
        } else if (urls === "initiatives-opted") {
            if (userRole === "SUPER_ADMIN") {
                history.push("/dashboard/view/initiatives-opted")
            } else {
                history.push('/institute-dashboard/dashboard/initiatives-opted')
            }
        }
    }

    const careerChoosenPieData = (data: any) => {
        let piedata: any = [];
        for (let i = 0; i < data.length; i++) {
            piedata.push({
                name: data.cas_student_careers[i].values[0],
                students: data.cas_student_careers[i].percent
            })
        }
        setPieData(pieData);
    }


    const renderColorfulLegendText = (value: number, entry: any) => {
        return (
            <span style={{ color: "black", fontWeight: 400, padding: "10px", fontSize: "12px" }}>
                {value}
            </span>
        );
    };



    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            payload,
            percent,
            value
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <path
                    d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                    stroke={fill}
                    fill="none"
                />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    textAnchor={textAnchor}
                    fill="#333"
                >{"Pie Chart data "}</text>
            </g>
        );
    };


    const showAttempts = (type: any) => {
        setActiveData(type)
        if (type === 1) {
            firstAttempt(dashboardData.cas_averages_attempt_1);
        }
        if (type === 2) {
            secondAttempt(dashboardData.cas_averages_attempt_2);
        }
    }



    const showRegisteredValues = () => {
        setRegisterdValues(true)

    }
    const showActiveStudentValues = () => {
        setActiveStudentValues(true)

    }
    const showSchoolValues = () => {
        setSchoolValues(true)

    }


    function kFormatter(num: any) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(0).replace(rx, "$1") + item.symbol : "0";

    }
    return (
        <>

            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#e3e3e3" }}>
                            <h5 className="modal-title" id="exampleModalLabel">
                                Registered Schools
                            </h5>
                            <button
                                type="button"
                                className=" btn-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleclose}
                            >
                            </button>
                        </div>

                        <div className="modal-body parent-info " style={{ maxHeight: "300px", overflowY: "auto" }}>

                        </div>

                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    {/* {showClickHereBtn && <div>
                        <button className='btn btn-light mt-2 border border-2 rounded-3 px-2 py-1 f14' onClick={cronJob}>Click here</button>
                    </div>} */}
                </div>
            }
            {!loading &&
                <div>

                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between ">
                        <h3 className="d-lg-none" >Dashboard</h3>
                    </div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                            {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"> <Link to='/dashboard/view/instituteslist' style={{ color: '#0d6efd' }}>{instituteName}</Link> / <span>Dashboard</span></li>}
                            {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><a style={{ color: '#0d6efd' }}>{organizationName}</a> / <span>Dashboard</span></li>}
                        </ol>
                    </nav>

                    <div className="row">

                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block" onClick={() => naviGations("schools")}>
                            <span className="dkpi-block-content cursor_pointer institute-registered-schools-bg-one  h-100" style={{ backgroundColor: "#dff9fb" }} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <h3 className="dkpi-hd mb-0"> Total Schools<small>&nbsp;</small></h3>
                                <p className="mt-1 mb-0 dkpi-num" onMouseLeave={() => setSchoolValues(false)} onMouseEnter={showSchoolValues}>{kFormatter(dashboardData.registered_schools)}</p>
                                <div className='position-absolute' style={{
                                    bottom: "3px"
                                }}>
                                    {(schoolValues && dashboardData.registered_schools > 999) &&
                                        <p className='m-0 f14' onMouseEnter={showSchoolValues} ref={registerednotificationref} onMouseLeave={() => setSchoolValues(false)} > {dashboardData.registered_schools}</p>
                                    }
                                </div>
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block reduceNum " onClick={() => naviGations("regitered-students")}>
                            <span className="dkpi-block-content cursor_pointer institute-registered-schools-bg-three h-100">
                                <h3 className="dkpi-hd mb-0">Registered / Total Students<small>&nbsp;</small></h3>
                                <p className="mt-1 mb-0 dkpi-num" onMouseLeave={() => setRegisterdValues(false)} onMouseEnter={showRegisteredValues}>{kFormatter(dashboardData.registered_students)}/{kFormatter(dashboardData.total_students)}</p>
                                <div className='position-absolute' style={{
                                    bottom: "3px"
                                }}>
                                    {(registerdValues && (dashboardData.registered_students > 999 || dashboardData.total_students > 999)) &&
                                        <p className='m-0 f14' onMouseEnter={showRegisteredValues} ref={registerednotificationref} onMouseLeave={() => setRegisterdValues(false)} > {(dashboardData.registered_students)}/{dashboardData.total_students}</p>
                                    }
                                </div>
                            </span> </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block" onClick={() => naviGations("active-students")}>
                            <span className="dkpi-block-content cursor_pointer institute-registered-schools-bg-two  h-100">
                                <h3 className="dkpi-hd mb-0">Active/Inactive Students<small>&nbsp;</small></h3>
                                <p className="mt-1 mb-0 dkpi-num" onMouseLeave={() => setActiveStudentValues(false)} onMouseEnter={showActiveStudentValues}>{kFormatter(dashboardData.active_students)}/{kFormatter(dashboardData.inactive_students)}</p>
                                <div className='position-absolute' style={{
                                    bottom: "3px"
                                }}>
                                    {(activeStudentValues && (dashboardData.active_students > 999 || dashboardData.inactive_students > 999)) &&
                                        <p className='m-0 f14' onMouseEnter={showActiveStudentValues} ref={registerednotificationref} onMouseLeave={() => setActiveStudentValues(false)} > {dashboardData.active_students}/{dashboardData.inactive_students}</p>
                                    }
                                </div>
                            </span> </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block " onClick={() => naviGations("initiatives-opted")}>
                            <span className="dkpi-block-content cursor_pointer institute-registered-schools-bg-four h-100">
                                <h3 className="dkpi-hd mb-0">Initiatives Opted<small>&nbsp;</small></h3>
                                <p className="mt-1 mb-0 dkpi-num">{dashboardData.registered_modules}/{dashboardData.total_modules}</p>
                            </span> </div>
                    </div>
                    <h3 className="mt-4 mb-3 section-hd d_none">CAS Initiative (Module)</h3>
                    <div className="row">
                        <div className="col-md-10 col-lg-6 mb-4 dkpi-block d_none">
                            <div className="dkpi-block-content no-hover d-md-flex h-100">
                                <div className="flex185 mb-4 mb-md-0">
                                    <h3 className="dkpi-hd mb-0">Total Registered<small>&nbsp;</small></h3>
                                    <p className="mt-1 mb-0 dkpi-num-lg">{kFormatter(dashboardData?.registered_students)}</p>
                                </div>
                                <table className="table table-small mb-0">
                                    <tr className='border-bottom'>
                                        <th></th>
                                        <th>1<sup className='ps-0'>st</sup> Attempt</th>
                                        <th>2<sup className='ps-0'>nd</sup> Attempt</th>
                                    </tr>
                                    <tr className='border-bottom'>
                                        <td>Student</td>
                                        <td>{dashboardData?.cas_student_attempt_1}</td>
                                        <td>{dashboardData?.cas_student_attempt_2}</td>
                                    </tr>
                                    <tr className='border-bottom'>
                                        <td>Father</td>
                                        <td>{dashboardData?.cas_father_attempt_1}</td>
                                        <td>{dashboardData?.cas_father_attempt_2}</td>
                                    </tr>
                                    <tr className='border-bottom'>
                                        <td>Mother</td>
                                        <td>{dashboardData?.cas_mother_attempt_1}</td>
                                        <td>{dashboardData?.cas_mother_attempt_2}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block d_none"> <div className="dkpi-block-content no-hover h-100">
                            <h3 className="dkpi-hd mb-0">CAS Initiative Analysis<small>Number of students choose career</small></h3>
                            <p className="mt-1 mb-0 dkpi-num mt-3">0/10</p>
                        </div> </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block d_none">
                            <div className="dkpi-block-content no-hover h-100">
                                <h3 className="dkpi-hd mb-0">Average Career Prospective Index<small>&nbsp;</small></h3>
                                <p className="mt-1 mb-0 dkpi-num">{(dashboardData?.cas_averages_latest) && (Object.keys(dashboardData?.cas_averages_latest).length > 0) ? Object.keys(dashboardData?.cas_averages_latest).map((data: any, index: number) => {
                                    if (data === "career_prospective_index") {
                                        return dashboardData?.cas_averages_latest[data]
                                    }

                                }) : 0}%</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block d_none"> <div className="dkpi-block-content no-hover h-100">
                            <h3 className="dkpi-hd mb-0 dkpi-hd-md">Average Career Aspiration Index <small>&nbsp;</small></h3>
                            <p className="mt-3 mb-0 dkpi-num">{(dashboardData?.cas_averages_latest) && (Object.keys(dashboardData?.cas_averages_latest).length > 0) ? Object.keys(dashboardData?.cas_averages_latest).map((data: any, index: number) => {
                                if (data === "can_see_the_perspectives_in_career") {
                                    return dashboardData?.cas_averages_latest[data]
                                }

                            }) : 0}%</p>
                        </div> </div>
                        <div className="col-md-10 col-lg-6 mb-4 dkpi-block d_none"> <a className="dkpi-block-content" >
                            <h3 className="dkpi-hd mb-0">Careers chosen by all Students</h3>
                            {careerData?.length > 0 ? <div className='pie-charts-background text-center'>
                                <PieChart style={{ width: "80%", height: "80%" }} width={380} height={150}>
                                    <Legend className='pb-4 mb-5'
                                        height={36}
                                        iconType="circle"
                                        layout="vertical"
                                        verticalAlign="middle"
                                        align="right"
                                        iconSize={10}
                                        formatter={renderColorfulLegendText}
                                    />
                                    <Tooltip />
                                    <Pie
                                        data={careerData}
                                        dataKey="students"
                                        fill="green"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={50}
                                        paddingAngle={0}
                                    >
                                        {careerData?.map((entry: any, index: number) => (
                                            <Cell key={`cell-${index}`} fill={pieChartColorsOne[index % pieChartColorsOne.length]} stroke={pieChartColorsOne[index % pieChartColorsOne.length]} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </div> : <p className='ps-3 f14 mt-3'>No Data Available</p>}
                        </a>
                        </div>
                    </div>

                    <div className="row">


                        <div className="col-md-10 col-lg-6 mb-4 dkpi-block piecharthieght"> <a className="dkpi-block-content" style={{ height: '350px' }}>
                            <h3 className="dkpi-hd mb-0">Average of Students</h3>

                            {avgStudents?.length > 0 ? <div className='pie-charts-background text-center '>
                                <PieChart className="piechart-responsive" style={{ width: "80%", height: "80%" }} width={380} height={150}>
                                    <Legend className='pb-4 mb-5'
                                        height={36}
                                        iconType="circle"
                                        layout="vertical"
                                        verticalAlign="middle"
                                        align="right"
                                        iconSize={10}
                                        formatter={renderColorfulLegendText}
                                    />
                                    <Pie

                                        data={avgStudents} dataKey="students" fill="green"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={50}
                                        paddingAngle={0}
                                    >
                                        {avgStudents?.map((entry: any, index: number) => (
                                            <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} stroke={pieChartColors[index % pieChartColors.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </div> : <p className='ps-3 f14 mt-3'>No Data Available</p>}
                        </a>
                        </div>
                        <div className="col-md-10 col-lg-6 mb-4 dkpi-block"> <span className="dkpi-block-content" style={{ height: '350px' }}>
                            <h3 className="dkpi-hd mb-0">Comparison of 1 & 2 attempts</h3>
                            {attemptData?.length > 0 ? <div className='pie-charts-background text-center '>
                                <PieChart style={{ width: "80%", height: "80%" }} width={380} height={150}>
                                    <Legend className='pb-4 mb-5'
                                        height={36}
                                        iconType="circle"
                                        layout="vertical"
                                        verticalAlign="middle"
                                        align="right"
                                        iconSize={10}
                                        formatter={renderColorfulLegendText}
                                    />
                                    <Pie
                                        data={attemptData} dataKey="students" fill="green"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={50}
                                        paddingAngle={0}
                                    >
                                        {attemptData?.map((entry: any, index: number) => (
                                            <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} stroke={pieChartColors[index % pieChartColors.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip labelStyle={{ border: '0px solid transparent' }}></Tooltip>
                                </PieChart>
                            </div> : <p className='ps-3 f14 mt-3 mb-5 pb-3'>No Data Available</p>}
                            <div className={`ms-md-5 ${attemptData?.length == 0 && "mt-5 pt-5"}`}>
                                <span className={`me-2 cursor-pointer f14 ${activeData === 1 && "ft_weight"}`} onClick={() => showAttempts(1)}>1st Attempt</span> | <span className={`ms-2 cursor-pointer f14 ${activeData === 2 && "ft_weight"}`} onClick={() => showAttempts(2)}>2nd Attempt</span>
                            </div>
                        </span>
                        </div>
                    </div>
                </div>}
        </>
    )
}
export default Dashboard;
