// import React from "react";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import FormBuilder from "../../../../components/form-builder";
import { CREATE_PROMO_CODES, GET_PROMOTIONS_BY_UUID, GET_PROMO_CODES_BY_UUID, UPDATE_PROMO_CODES } from "../../../../app/service/promotions";
import { toast } from "react-toastify";
import { GET_INSTITUTE } from "../../../../app/service/institute.service";
import moment from "moment";
import { GET_SPONSOR_SCHOOL_INSTITUTE_ID } from "../../../../app/service/sponsor";
import { Breadcum } from "../../../../components/breadcum";
import SpecialDiscountCoupon from "../special-discount";



export const PromoCodeForm = () => {
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');
    const [promoCodeData, setPromoCodeData] = useState<any>({});
    const [checkedStatus, setCheckedStatus] = useState<any>(1);
    const [instituteData, setInstituteData] = useState<any>([]);
    const [currentPromoCodeData, setCurrentPromoCodeData] = useState<any>({});
    const [schoolsData, setSchoolsData] = useState<any>([]);
    const search = useLocation().search;
    const [minError, setMinError] = useState('');
    const [maxError, setMaxError] = useState('');
    const [percentageError, setPercentageError] = useState('');
    const [minStudentValue, setMinStudentValue] = useState<any>("");
    const [maxStudentValue, setMaxStudentValue] = useState<any>("");
    const [discountValue, setDiscountValue] = useState<any>("");
    const [promotionData, setPromotionData] = useState<any>({});
    const create: any = new URLSearchParams(search).get('create')
    let { promotionUuid, Uuid } = useParams<{ promotionUuid: string, Uuid: string }>();
    const [discountPercentageForm, setDiscountPercentageForm] = useState<any>([]);
    const [organizationError, setOrganizationError] = useState(false);
    const [schoolError, setSchoolError] = useState(false);
    const [schoolCode, setSchoolCode] = useState<any>("");
    const [organizationCode, setOrganizationCode] = useState<any>("");
    const [flatValue, setFlatValue] = useState(false);
    const promoCodeUuid = Uuid;
    const promotionUuids = promotionUuid;
    const history = useHistory();
    const numbers = [];
    for (let i = 1; i <= 100; i++) {
        numbers.push(i);
    }
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Promotions",
            value: "Promotions",
            routerLink: "/dashboard/promotion/list",
            isActive: false,
        },
        {
            label: "Coupon Codes",
            value: "Coupon Codes",
            routerLink: `/dashboard/promotion/promocode/list/${promotionUuid}`,
            isActive: false,
        },
        {
            label: "Coupon Code",
            value: "",
            routerLink: "",
            isActive: false,
        },
    ]);
    useEffect(() => {
        fecthAllData();
        // getPromotionData();
        if (create != 1) {
            getBreadcumList(0);
        }
        // getInstitutes();
    }, []);
    const fecthAllData = async () => {
        setLoading(true);
        try {
            await getInstitutes();
            const responseData = await getPromotionData();
            if (create == 1) {
                await getUpdatedPromoCodeData(responseData?.discount_type);
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);

        }
    }

    const getPromotionData = async () => {
        try {
            const res: any = await GET_PROMOTIONS_BY_UUID(promotionUuid)
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                const data = res.rows[0];
                if (data?.discount_type === "FLAT_VALUE_DISCOUNT") {
                    setFlatValue(true);
                }
                setPromotionData(data);
                const breadcumInfoData = breadcumInfo;
                breadcumInfoData[1].label = data.name;
                breadcumInfoData[1].value = data.name;
                breadcumInfoData[1].isActive = false;
                setBreadcumInfo(breadcumInfoData);
                return data;
            }
        } catch (error) {

        }

    }
    const getUpdatedPromoCodeData = async (discount_type: any) => {
        if (create == 1) {
            try {
                const res: any = await GET_PROMO_CODES_BY_UUID(promoCodeUuid)
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.rows[0];
                    setCurrentPromoCodeData(data);
                    setCheckedStatus(data?.status);
                    if (data.organization_code) {
                        getSchoolsByInstituteCode(data.organization_code);
                        setOrganizationCode(data.organization_code);
                    }
                    setSchoolCode(data.school_code);
                    getBreadcumList(data);
                    data.algorithm = JSON.parse(data?.algorithm);
                    if (discount_type === "FLAT_VALUE_DISCOUNT") {
                        setDiscountValue(data?.algorithm[0].discount_amount);
                    } else {
                        setDiscountValue(data?.algorithm[0].discount_percentage);
                    }
                    setMaxStudentValue(data?.algorithm[0].max);
                    setMinStudentValue(data?.algorithm[0].min);
                }
            } catch (error) {

            }

        }
    }

    function getBreadcumList(currentPromoCodeData: any) {
        if (create == 1) {
            const breadcumInfoData = breadcumInfo;
            breadcumInfoData[2].label = currentPromoCodeData?.promo_code;
            breadcumInfoData[2].value = currentPromoCodeData?.promo_code;
            breadcumInfoData[2].isActive = true;
            setBreadcumInfo(breadcumInfoData);
        } else {
            breadcumInfo.splice(2, breadcumInfo.length - 1,
                {
                    label: "New",
                    value: "New",
                    isActive: true,
                });
            setBreadcumInfo(breadcumInfo);
        }

    }
    const getInstitutes = async () => {
        try {
            const res: any = await GET_INSTITUTE();
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                const data = res.rows;
                setInstituteData(data);
            }
        } catch (error) {

        }

    }
    const handlePromoCodeInput = (data: any) => {
        data.value = { ...currentPromoCodeData, ...data.value };
        setPromoCodeData(data);

    };

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter code');
        }
    }
    const handleOrganizationChange = (e: any) => {
        const data = { ...promoCodeData.value };
        data.organization_code = e.target.value;
        if (currentPromoCodeData) {
            currentPromoCodeData.organization_code = e.target.value;
        }
        if (e.target.value) {
            setOrganizationCode(e.target.value);
            setOrganizationError(false);
        } else {
            setOrganizationCode("");
            if (promotionData?.promotion_type === "INSTITUTIONAL" || promotionData?.promotion_type === "SCHOOL") {
                setOrganizationError(true);

            }
        }
        getSchoolsByInstituteCode(e.target.value);
    }
    function getSchoolsByInstituteCode(Code: any) {
        GET_SPONSOR_SCHOOL_INSTITUTE_ID(Code, 0, {}).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                const data = res.rows;
                setSchoolsData(data);
            }
        });
    }
    const handleSchoolChange = (e: any) => {
        const data = { ...promoCodeData.value };
        data.school_code = e.target.value;
        if (currentPromoCodeData) {
            currentPromoCodeData.school_code = e.target.value;
        }
        if (e.target.value) {
            setSchoolCode(e.target.value);
            setSchoolError(false);
        }
        else {
            setSchoolCode("");
            if (schoolCode === "" && promotionData?.promotion_type === "SCHOOL") {
                setSchoolError(true);
            }

        }
    }
    const handleMinChange = (event: any) => {
        let e = event.trim();
        setMinError('');
        const reg = /^[0-9]*$/
        setMinStudentValue(e);
        if (e) {
            if (!reg?.test(e)) {
                setMinError('Enter only Numbers ');
            } else {
                // setMinStudentValue(e);
                return true;
            }
        } else {
            setMinStudentValue("");
            if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
                setMinError('Please enter Min amount');
            }
            else {
                setMinError('Please enter Min students');
            }
        }
    }
    const handleMaxChange = (event: any) => {
        let e = event.trim()
        setMaxError('');
        const reg = /^[0-9]*$/
        setMaxStudentValue(e);
        if (e) {
            if (!reg?.test(e)) {
                setMaxError('Enter only   Numbers ');
            } else {
                // setMaxStudentValue(e);
                return true;
            }
        } else {
            if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
                setMaxError('Please enter max amount');
            } else {
                setMaxError('Please enter Max students');
            }
        }
    }
    const handleDiscountChange = (event: any) => {
        let e = event.trim()
        setPercentageError('');
        setDiscountValue(e);
        if (promotionData.discount_type === "FLAT_VALUE_DISCOUNT") {
            const reg = /^[0-9]*$/
            if (e) {
                if (!reg?.test(e)) {
                    setPercentageError('Enter only Numbers');
                } else {
                    // setDiscountValue(e);
                    return true;
                }
            } else {
                setPercentageError(' Please enter discount amount');
            }
        }
        if (promotionData.discount_type === "PERCENTAGE_BASED" || promotionData.discount_type === "VOLUME_BASED") {
            if (e) {
                // setDiscountValue(e);
                return true
            }
            else {
                setPercentageError('Please select percentage %');
            }

        }

    }
    function validateForm() {

        let isValid = true;
        const reg = /^[0-9]*$/
        if (!minStudentValue) {
            if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
                setMinError('Please enter Min amount');
            } else {
                setMinError('Please enter Min students');
            }
            isValid = false;
        }
        if (!reg.test(minStudentValue)) {
            setMinError('Enter only numbers ');
            isValid = false;
        }

        if (!maxStudentValue) {
            if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
                setMaxError('Please enter max amount');
            } else {
                setMaxError('Please enter Max students');
            }
            isValid = false;
        }

        if (!reg.test(maxStudentValue)) {
            setMaxError('Enter only numbers ');
            isValid = false;
        }
        if (!discountValue) {
            if (promotionData.discount_type === "FLAT_VALUE_DISCOUNT") {
                setPercentageError('Please enter discount amount');
            } else {
                setPercentageError('Please select percentage %');

            }
            isValid = false;
        }
        if (parseInt(discountValue) > parseInt(minStudentValue) && promotionData.discount_type === "FLAT_VALUE_DISCOUNT") {
            setPercentageError('Discount amount should be less than min purchase amount');
            isValid = false;
        }

        if (!reg.test(discountValue)) {
            setPercentageError('Enter only numbers ');
            isValid = false;
        }

        if (organizationCode === "" && (promotionData?.promotion_type === "INSTITUTIONAL" || promotionData?.promotion_type === "SCHOOL")) {
            setOrganizationError(true);
            isValid = false;
        }
        if (schoolCode === "" && promotionData?.promotion_type === "SCHOOL") {
            setSchoolError(true);
            isValid = false;
        }
        if (parseInt(maxStudentValue) < parseInt(minStudentValue) && (promotionData.discount_type === "FLAT_VALUE_DISCOUNT" || promotionData.discount_type === "PERCENTAGE_BASED")) {
            setMaxError("Max purchase amount should be greater than min purchase amount");
            isValid = false;
        }
        if (parseInt(maxStudentValue) < parseInt(minStudentValue) && promotionData.discount_type === "VOLUME_BASED") {
            setMaxError("Max number of students should be greater than min number of students");
            isValid = false;
        }

        return isValid;
    }
    function addForms() {
        if (validateForm()) {
            let addPreviousData = [...discountPercentageForm];
            addPreviousData.push({
                min: minStudentValue,
                max: maxStudentValue,
                discount_percentage: discountValue
            });
            setDiscountPercentageForm(addPreviousData);
            setMinStudentValue("");
            setMaxStudentValue("");
            setDiscountValue("");
        }
    }
    function onDeleteForm(i: number) {
        let newSponsorForm = [...discountPercentageForm];
        newSponsorForm.splice(i, 1);
        setDiscountPercentageForm(newSponsorForm);
    }

    function createPromoCode() {
        let preparedData: any = []
        if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
            preparedData.push({
                min: parseInt(minStudentValue),
                max: parseInt(maxStudentValue),
                discount_amount: parseInt(discountValue)
            })
        } else {
            preparedData.push({
                min: parseInt(minStudentValue),
                max: parseInt(maxStudentValue),
                discount_percentage: parseInt(discountValue)
            })
        }
        const promoCodesData = promoCodeData.value ? { ...promoCodeData.value } : { ...currentPromoCodeData };
        const payload = {
            promotion_uuid: promotionUuid,
            algorithm: preparedData,
            organization_code: promoCodesData.organization_code ? promoCodesData.organization_code : "",
            school_code: promoCodesData.school_code ? promoCodesData.school_code : "",
            txn_id: "",
            status: checkedStatus
        }

        if ((discountPercentageForm.length > 0 || validateForm())) {

            setLoading(true);
            CREATE_PROMO_CODES(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("Promo Code Created Successfully");
                    history.push(`/dashboard/promotion/promocode/list/${promotionUuid}`);
                    setLoading(false);
                }
            });
        }
        else {
            if (!nameError && !onChangeName(promoCodesData.promo_code)) {
                setNameError('Please enter code');
            }
            if (discountPercentageForm.length < 1) {
                validateForm()
            }
        }
    }
    function updatePromoCode() {
        let preparedData: any = []
        if (promotionData?.discount_type === "FLAT_VALUE_DISCOUNT") {
            preparedData.push({
                min: parseInt(minStudentValue),
                max: parseInt(maxStudentValue),
                discount_amount: parseInt(discountValue)
            })
        } else {
            preparedData.push({
                min: parseInt(minStudentValue),
                max: parseInt(maxStudentValue),
                discount_percentage: parseInt(discountValue)
            })
        }
        const promoCodesData = promoCodeData.value ? { ...promoCodeData.value } : { ...currentPromoCodeData };
        const payload = {
            promotion_uuid: promotionUuid,
            algorithm: preparedData,
            organization_code: promoCodesData.organization_code ? promoCodesData.organization_code : "",
            school_code: promoCodesData.school_code ? promoCodesData.school_code : "",
            txn_id: "",
            status: checkedStatus
        }
        if ((discountPercentageForm.length > 0 || validateForm())) {
            setLoading(true);
            UPDATE_PROMO_CODES(promoCodeUuid, payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    toast.success("Promo Code Updated Successfully");
                    history.push(`/dashboard/promotion/promocode/list/${promotionUuid}`);
                    setLoading(false);
                }
            });
        }
        else {
            if (!nameError && !onChangeName(promoCodesData.promo_code)) {
                setNameError('Please enter code');
            }
            if (discountPercentageForm.length < 1) {
                validateForm()
            }
        }
    }
    const handleUserStatusChange = (e: any) => {
        if (currentPromoCodeData) {
            const status = e.target.checked === true ? 1 : 0;
            currentPromoCodeData.status = e.target.checked === true ? 1 : 0;
            setCheckedStatus(status);
        }
    }
    return (
        <div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className="mt-3">
                    <Breadcum breadcumList={breadcumInfo} />
                </div>
                <div style={{ borderBottom: 'solid 1px black', paddingLeft: '10px' }} className="mb-4">
                    <h5 className="form-label mb-2 d-block">Promotion Details</h5>
                </div>

                <div className="row custom-form">
                    <div className="col-md-4 custom-form">
                        <div className="mb-4">
                            <label className="form-label mb-0">Promotion Name
                            </label>
                            <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}></span>
                            <input className="form-control form-control-lg f16" type="text" name="name" defaultValue={promotionData.name} disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                        </div>
                    </div>
                    <div className="col-md-4 custom-form">
                        <div className="mb-4">
                            <label className="form-label mb-0">Promotion Type
                            </label>
                            <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}></span>
                            <input className="form-control form-control-lg f16" type="text" name="promotion_type" defaultValue={promotionData?.promotion_type?.replace(/_/g, " ")} disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                        </div>
                    </div>
                    <div className="col-md-4 custom-form">
                        <div className="mb-4">
                            <label className="form-label mb-0">Discount Type
                            </label>
                            <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}></span>
                            <input className="form-control form-control-lg f16" type="text" name="discount_type" defaultValue={promotionData?.discount_type?.replace(/_/g, " ")} disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                        </div>
                    </div>
                </div>
                {promotionData?.promotion_type !== "SPECIAL_DISCOUNT" && <div>
                    <div style={{ borderBottom: 'solid 1px black', paddingLeft: '10px' }} className="mb-4">
                        <h5 className="form-label mb-2 d-block">Coupon Code Details</h5>
                    </div>
                    <FormBuilder onUpdate={handlePromoCodeInput}>
                        <form>
                            <div className="row custom-form">
                                <div className="col-md-4 custom-form">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 ">Name of  Institute</label>
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>{(promotionData?.promotion_type === "INSTITUTIONAL" || promotionData?.promotion_type === "SCHOOL") && <span>*</span>}</span>
                                        <select name="organization_code" className="form-select form-select-lg " value={currentPromoCodeData?.organization_code} onChange={(e) => { handleOrganizationChange(e) }}>
                                            <option value="">Select the Institute</option>
                                            {instituteData.map((name: any, i: number) => (
                                                <option key={i} value={name.organization_code}>
                                                    {name.name_of_organization}
                                                </option>
                                            ))}
                                        </select>
                                        {organizationError && <p className="text-danger">please select the institution</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 custom-form">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 mt-2">Name of School </label>
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>{promotionData?.promotion_type === "SCHOOL" && <span>*</span>}</span>
                                        <select name="school_code" className="form-select form-select-lg  " value={currentPromoCodeData?.school_code} onChange={(e) => { handleSchoolChange(e) }}>
                                            <option value="">Select the school</option>
                                            {schoolsData?.map((name: any, i: number) => (
                                                <option key={i} value={name.school_code}>
                                                    {name.name_of_school}
                                                </option>
                                            ))}
                                        </select>
                                        {schoolError && <p className="text-danger">please select the school</p>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label">Coupon Status</label><br />
                                        <label className="switch">
                                            <input type="checkbox" name="active" value={currentPromoCodeData?.status} checked={checkedStatus === 1} onChange={(e) => { handleUserStatusChange(e) }} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </FormBuilder>
                    <div className={`row custom-form`}>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label">{promotionData?.discount_type !== "VOLUME_BASED" && <span>Min Purchase Amount</span>}{promotionData?.discount_type === "VOLUME_BASED" && <span> Min Number of Students</span>}</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                <input className="form-control form-control-lg f16 no-spinner" type="text" name="min" value={minStudentValue} onChange={(e) => handleMinChange(e.target.value)} placeholder="Please enter min value" />
                                {minError && <p className="text-danger">{minError}</p>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label className="form-label">{promotionData?.discount_type !== "VOLUME_BASED" && <span>Max Purchase Amount</span>}{promotionData?.discount_type === "VOLUME_BASED" && <span>Max Number of Students</span>}</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                <input className="form-control form-control-lg f16 no-spinner" type="text" name="max" value={maxStudentValue} onChange={(e) => handleMaxChange(e.target.value)} placeholder="Please enter  max value" />
                                {maxError && <p className="text-danger">{maxError}</p>}
                            </div>
                        </div>
                        {promotionData.discount_type === "FLAT_VALUE_DISCOUNT" && <div className="col-md-4">
                            <div className="mb-4 ">
                                <label className="form-label">Discount Flat Amount</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                <input className="form-control form-control-lg f16 no-spinner" type="text" name="discount_amount" value={discountValue} onChange={(e) => handleDiscountChange(e.target.value)} placeholder="Please enter discount Amount" />
                                {percentageError && <p className="text-danger">{percentageError}</p>}
                            </div>
                        </div>}
                        {promotionData.discount_type !== "FLAT_VALUE_DISCOUNT" && <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Discount Percentage %</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                <select name="discount_percentage" className="form-select form-select-lg" value={discountValue} onChange={(e) => handleDiscountChange(e.target.value)}>
                                    <option value="">Select the percentage</option>
                                    {numbers?.map((name: any, i: number) => (
                                        <option key={name} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                                {percentageError && <p className="text-danger">{percentageError}</p>}

                            </div>
                        </div>}

                    </div>
                    {discountPercentageForm?.map((data: any, i: number) => (
                        <div className={`row custom-form ${i && 'border_top_forms pt-2 pt-md-0 '}`} key={i}>
                            <div className="col-md-3">
                                <div className="mb-4">
                                    <label className="form-label">Min Students</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input className="form-control form-control-lg f16 no-spinner" type="text" name="min" defaultValue={data?.min} placeholder="Please enter min students" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-4">
                                    <label className="form-label">Max Students</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input className="form-control form-control-lg f16 no-spinner" type="text" name="max" defaultValue={data?.max} placeholder="Please enter  max students" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-4">
                                    <label className="form-label">Discount Percentage %</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input className="form-control form-control-lg f16 no-spinner" type="text" name="discount_percentage" defaultValue={data?.discount_percentage} placeholder="Please enter discount percentage" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                </div>
                            </div>
                            <div className="col-12 col-md-1 pb-3 pb-md-0 mt-2 mt-md-0">
                                <div className="mt-md-4 pt-md-2 text-md-center text-md-start text-end ">
                                    <i className="bi bi-trash cursor-pointer f24" onClick={() => onDeleteForm(i)}></i>
                                </div>
                            </div>
                        </div>
                    )
                    )}
                    <div className="form-footer border-top border-primary py-3 text-end mt-4">
                        {create == 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createPromoCode()}>Create</a>}
                        {create == 1 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updatePromoCode()}>Update</a>}
                        <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/promotion/promocode/list/${promotionUuid}`}>Cancel</Link>
                    </div>
                </div>}
                {(Object.keys(promotionUuids).length > 0 && promotionData?.promotion_type === "SPECIAL_DISCOUNT") && <div>
                    <SpecialDiscountCoupon create={create} promotionData={promotionData} promotionUuid={promotionUuids} promoCodeUuid={promoCodeUuid} currentData={currentPromoCodeData} institutesData={instituteData} />
                </div>}
            </div>}
        </div>
    )
}