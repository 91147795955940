import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { FECTH_FILTER_PROMOTION_WITH_COUPON_REPORT_LIST, GET_PROMOTION_WITH_COUPON_REPORT_LIST } from "../../../app/service/promotions";
import { returnPaginationRange } from "../../../app/utility/appUtils";

export const PromotionsReportList = () => {
    const [promotionList, setPromotionList] = useState<any>([]);
    const [promotionData, setPromotionData] = useState<any>({});
    const [viewAllPromotions, setViewAllPromotions] = useState<any>([]);
    const [defaultLoading, setDefaultLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState<any>();
    const history = useHistory();
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");
    const [promotionName, setPromotionName] = useState<string>("");
    const [pageNumbers, setPageNumbers] = useState<any>([]);
    let totalPage = pageNumbers.length
    const arrayOfPages = returnPaginationRange(pageNumbers.length, activePage, 10, 1);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [totalAmountValue, setTotalAmountValue] = useState(false);
    const [discountValue, setDiscountValue] = useState(false);
    const [paidAmountValue, setPaidAmountValue] = useState(false);
    const [couponValue, setCouponValue] = useState(false);
    const [usedCouponValue, setUsedCouponValue] = useState(false);
    const [activeCouponValue, setActiveCouponValue] = useState(false);
    const registerednotificationref = useRef<any>(null);
    const [showListPopup, setShowListPopup] = useState<boolean>(false);
    useEffect(() => {
        getPromotionsrEPORTList();
    }, []);

    function getPromotionsrEPORTList() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setDefaultLoading(true);
        setLoading(true);
        GET_PROMOTION_WITH_COUPON_REPORT_LIST(activePageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setDefaultLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    setPromotionData(res);
                    const preparedData = res.promotions;
                    const data = [...preparedData].reverse();
                    setViewAllPromotions(data);
                    data?.map((ele: any, index: number) => (
                        ele.index = index + 1
                    ))
                    let array: any = [];
                    for (let i = (page - 1) * limit; i < (page * limit); i++) {
                        if (data[i]) {
                            array.push(data[i]);
                        }
                    }

                    setPromotionList(array);
                    const pageNumbers = Math.ceil(data.length / limit);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                    setDefaultLoading(false);
                }
            }
        });
    }
    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data;
        setLoading(true);
        GET_PROMOTION_WITH_COUPON_REPORT_LIST(pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const preparedData = res.promotions;
                    const data = [...preparedData].reverse();
                    data?.map((ele: any, index: number) => (
                        ele.index = index + 1
                    ))
                    let array: any = [];
                    for (let i = (pageNumber - 1) * limit; i < (pageNumber * limit); i++) {
                        if (data[i]) {
                            array.push(data[i]);
                        }
                    }
                    setPromotionList(array);
                    const pageNumbers = Math.ceil(data.length / limit);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });

    }
    function handleSearch() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        const payload = {
            promotion_search: promotionName,
            from_dt: fromDate ? `${fromDate} 00:00:00` : "",
            to_dt: toDate ? `${toDate} 23:59:59` : ""
        }
        if (promotionName || fromDate || toDate) {
            setLoading(true);
            FECTH_FILTER_PROMOTION_WITH_COUPON_REPORT_LIST(activePageNumber, payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res.promotions;

                        data?.map((ele: any, index: number) => (
                            ele.index = index + 1
                        ))
                        let array: any = [];
                        for (let i = (page - 1) * limit; i < (page * limit); i++) {
                            if (data[i]) {
                                array.push(data[i]);
                            }
                        }
                        setPromotionList(array);
                        const pageNumbers = Math.ceil(data.length / limit);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }

    }
    function handleRefresh() {
        getPromotionsrEPORTList();
        setPromotionName("");
        setFromDate("");
        setToDate("");
    }
    function handlePromotion(item: any) {
        history.push(
            `/dashboard/coupon-report/coupon-code/${item.promotion_uuid}`
        );
    }
    function kFormatter(num: any) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(0).replace(rx, "$1") + item.symbol : "0";

    }
    const onClickDoubbleArrows = (arrow: any) => {
        if (arrow === "&raquo;") {
            setActivePage(totalPage);
            setPage(totalPage);
            onPageChange(totalPage);

        } else {
            setActivePage(1);
            setPage(1);
            onPageChange(1);

        }
    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        setPage(page - 1);
        onPageChange(page - 1);

    }
    function onClickPage(number: number) {
        let dots: any = "..."

        if (page !== 1 && number === dots) {
            setActivePage(1);
            setPage(1);
            onPageChange(1);

        } else if (activePage !== pageNumbers.length && number === dots) {
            setActivePage(totalPage - 1);
            setPage(totalPage - 1);
            onPageChange(totalPage - 1);
        }
        else {
            setActivePage(number);
            setPage(number);
            onPageChange(number);
        }
    }
    function onClickNextPage() {
        setActivePage(activePage + 1);
        setPage(page + 1);
        onPageChange(page + 1);

    }
    const showTotalAmountValues = () => {
        setTotalAmountValue(true)

    }
    const showDiscountAmountValues = () => {
        setDiscountValue(true)
    }
    const showPaidAmountValues = () => {
        setPaidAmountValue(true);
    }
    const showCouponValues = () => {
        setCouponValue(true);
    }
    const showUsedCoupons = () => {
        setUsedCouponValue(true);
    }
    const showActiveCoupons = () => {
        setActiveCouponValue(true);
    }
    return (
        <div>
            <div
                className={`modal fade ${showListPopup ? "show" : "false"}`}
                style={{ display: showListPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Membership Reports
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setShowListPopup(false)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            <div className="mb-4">
                                <div className='table-responsive mar-t-20 custom-scroll  '>
                                    <table className='table table  fixed-table-header mb-0'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>S.No</th>
                                                <th style={{ width: "150px" }}>Promotions</th>
                                                <th style={{ width: '100px' }}>Total Coupons</th>
                                                <th style={{ width: '100px' }}> Used Coupons</th>
                                                <th style={{ width: '90px' }}>Active Coupons</th>
                                                <th style={{ width: '100px' }}>Total Amount</th>
                                                <th style={{ width: '100px' }}> Discount Amount</th>
                                                <th style={{ width: '100px' }}>Paid Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {viewAllPromotions?.map((data: any, i: number) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td><span className="active cursor-pointer" onClick={() => handlePromotion(data)}>{data?.promotion_name}</span></td>
                                                        <td>{data?.total_coupons}</td>
                                                        <td> {data?.total_used}</td>
                                                        <td>{data?.total_active}</td>
                                                        <td>{data?.total_amount}</td>
                                                        <td>{data?.discount_amount} </td>
                                                        <td>{data?.paid_amount}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>


                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={() => setShowListPopup(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {defaultLoading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!defaultLoading &&
                <div className=" border-top border-primary py-3">
                    <div className="mb-3">
                        <h2 className='mt-1 mt-md-0'>Coupon Reports</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block  "> <div className="dkpi-block-content no-hover h-100" style={{ backgroundColor: "#f5fcff" }}>
                            <h3 className="dkpi-hd mb-0 f16">Total Coupons<small></small></h3>
                            <p className="mt-1 mb-0 dkpi-num mt-3 f18 " onMouseLeave={() => setCouponValue(false)} onMouseEnter={showCouponValues}>{kFormatter(promotionData?.total_coupons)}</p>
                            <div className='position-absolute' style={{
                                bottom: "3px"
                            }}>
                                {(couponValue && promotionData?.total_coupons > 999) &&
                                    <p className='m-0 f14' onMouseEnter={showCouponValues} ref={registerednotificationref} onMouseLeave={() => setCouponValue(false)} > {promotionData?.total_coupons}</p>
                                }
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block"> <div className="dkpi-block-content no-hover h-100 " style={{ backgroundColor: "#fcf7fc" }}>
                            <h3 className="dkpi-hd mb-0">Used Coupons<small></small></h3>
                            <p className="mt-1 mb-0 dkpi-num mt-3" onMouseLeave={() => setUsedCouponValue(false)} onMouseEnter={showUsedCoupons}>{kFormatter(promotionData?.total_used)}</p>
                            <div className='position-absolute' style={{
                                bottom: "3px"
                            }}>
                                {(usedCouponValue && promotionData?.total_used > 999) &&
                                    <p className='m-0 f14' onMouseEnter={showUsedCoupons} ref={registerednotificationref} onMouseLeave={() => setUsedCouponValue(false)} > {promotionData?.total_used}</p>
                                }
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block  "> <div className="dkpi-block-content no-hover h-100" style={{ backgroundColor: "#f5fcff" }}>
                            <h3 className="dkpi-hd mb-0">Active Coupons<small></small></h3>
                            <p className="mt-1 mb-0 dkpi-num mt-3" onMouseLeave={() => setActiveCouponValue(false)} onMouseEnter={showActiveCoupons}>{kFormatter(promotionData?.total_active)}</p>
                            <div className='position-absolute' style={{
                                bottom: "3px"
                            }}>
                                {(activeCouponValue && promotionData?.total_active > 999) &&
                                    <p className='m-0 f14' onMouseEnter={showActiveCoupons} ref={registerednotificationref} onMouseLeave={() => setActiveCouponValue(false)} > {promotionData?.total_active}</p>
                                }
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block "> <div className="dkpi-block-content no-hover h-100 " style={{ backgroundColor: "#fcf7fc" }}>
                            <h3 className="dkpi-hd mb-0">Total Amount<small></small></h3>
                            <p className="mt-1 mb-0 dkpi-num mt-3" onMouseLeave={() => setTotalAmountValue(false)} onMouseEnter={showTotalAmountValues}>{kFormatter(promotionData?.total_amount)}</p>
                            <div className='position-absolute' style={{
                                bottom: "3px"
                            }}>
                                {(totalAmountValue && promotionData?.total_amount > 999) &&
                                    <p className='m-0 f14' onMouseEnter={showTotalAmountValues} ref={registerednotificationref} onMouseLeave={() => setTotalAmountValue(false)} > {promotionData?.total_amount}</p>
                                }
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block "> <div className="dkpi-block-content no-hover h-100 " style={{ backgroundColor: "#f5fcff" }}>
                            <h3 className="dkpi-hd mb-0">Total Discount Percentage<small></small></h3>
                            <p className="mt-1 mb-0 dkpi-num mt-3">{kFormatter(promotionData?.total_discount_percentage)}%</p>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block "> <div className="dkpi-block-content no-hover h-100" style={{ backgroundColor: "#fcf7fc" }}>
                            <h3 className="dkpi-hd mb-0">Total Discount Amount<small></small></h3>
                            <p className="mt-1 mb-0 dkpi-num mt-3" onMouseLeave={() => setDiscountValue(false)} onMouseEnter={showDiscountAmountValues}>{kFormatter(promotionData?.total_discount_amount)}</p>
                            <div className='position-absolute' style={{
                                bottom: "3px"
                            }}>
                                {(discountValue && promotionData?.total_discount_amount > 999) &&
                                    <p className='m-0 f14' onMouseEnter={showDiscountAmountValues} ref={registerednotificationref} onMouseLeave={() => setDiscountValue(false)} > {promotionData?.total_discount_amount}</p>
                                }
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4  dkpi-block"> <div className="dkpi-block-content no-hover h-100 dkpi-block " style={{ backgroundColor: "#f5fcff" }}>
                            <h3 className="dkpi-hd mb-0">Total Paid Amount<small></small></h3>
                            <p className="mt-1 mb-0 dkpi-num mt-3" onMouseLeave={() => setPaidAmountValue(false)} onMouseEnter={showPaidAmountValues}>{kFormatter(promotionData?.total_paid_amount)}</p>
                            <div className='position-absolute' style={{
                                bottom: "3px"
                            }}>
                                {(paidAmountValue && promotionData?.total_paid_amount > 999) &&
                                    <p className='m-0 f14' onMouseEnter={showPaidAmountValues} ref={registerednotificationref} onMouseLeave={() => setPaidAmountValue(false)} > {promotionData?.total_paid_amount}</p>
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                    {viewAllPromotions?.length > 0 &&
                        <div className=' text-end'><button className='btn btn-primary me-1 me-sm-4 f14' onClick={() => setShowListPopup(true)}>View All Promotions</button>
                        </div>}
                    <div className="row  mb-4 ">
                        <div className="col-md-3">
                            <div className="mt-3">
                                <label className="form-label mb-0">Promotion Name</label>
                                <input type="text" id="name" className="form-control ft-14" placeholder="Promotion name" name="promotion_name" onChange={(e) => setPromotionName(e.target.value)} value={promotionName} />

                            </div>
                        </div>
                        <div className="col-md-3 ">
                            <label className="form-label mb-0 mt-3">From Date</label>
                            <input type="date" className='form-control' name="expiry_dt" value={fromDate} onChange={(e) => setFromDate(e.target.value)} onKeyDown={(event) => {
                                event.preventDefault();
                            }} />
                        </div>
                        <div className="col-md-3">
                            <label className="form-label mb-0 mt-3">To Date</label>
                            <input type="date" className='form-control' name="expiry_dt" value={toDate} onChange={(e) => setToDate(e.target.value)} onKeyDown={(event) => {
                                event.preventDefault();
                            }} />
                        </div>
                        <div className="col-md-3  text-end text-md-start pt-4">
                            <button className="btn btn-primary px-3 cursor-pointer mt-3" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                            <button className="btn clear-btn cursor-pointer mt-3" type="button" onClick={() => handleRefresh()}>
                                Clear
                            </button>
                        </div>
                    </div>
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <div className="mb-4">
                            <div className='table-responsive mar-t-20 custom-scroll  '>
                                <table className='table table  fixed-table-header mb-0'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "50px" }}>S.No</th>
                                            <th style={{ width: "150px" }}>Promotions</th>
                                            <th style={{ width: '100px' }}>Total Coupons</th>
                                            <th style={{ width: '100px' }}> Used Coupons</th>
                                            <th style={{ width: '90px' }}>Active Coupons</th>
                                            <th style={{ width: '100px' }}>Total Amount</th>
                                            <th style={{ width: '100px' }}> Discount Amount</th>
                                            <th style={{ width: '100px' }}>Paid Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {promotionList?.map((data: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{data?.index}</td>
                                                    <td><span className="active cursor-pointer" onClick={() => handlePromotion(data)}>{data?.promotion_name}</span></td>
                                                    <td>{data?.total_coupons}</td>
                                                    <td> {data?.total_used}</td>
                                                    <td>{data?.total_active}</td>
                                                    <td>{data?.total_amount}</td>
                                                    <td>{data?.discount_amount} </td>
                                                    <td>{data?.paid_amount}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                            {promotionList.length === 0 && <p className='text-center f16 mt-2'>No Data Available</p>}

                            {
                                promotionList.length > 0 && (
                                    <nav className="mt-3" style={{ float: "right" }}>
                                        {pageNumbers && (
                                            <ul className="pagination">
                                                <li className="page-item" onClick={() => onClickDoubbleArrows("&laquo;")}><span className={`page-link prev ${page == 1 ? "disabled" : ""}`}>&laquo;</span></li>

                                                <li className="page-item">
                                                    <button className={`page-link prev ${page == 1 ? "disabled" : ""}`} onClick={() => onClickPreviousPage()}>
                                                        &lsaquo;
                                                    </button>
                                                </li>
                                                {pageNumbers.length > 5 &&
                                                    arrayOfPages.map((value: any, i: number) => (
                                                        <li key={i} className={page == value ? "active page-item" : "page-item"} onClick={() => onClickPage(value)}><span className="page-link">{value}</span></li>

                                                    ))}
                                                {pageNumbers.length <= 5 &&
                                                    pageNumbers.map((number: number, i: number) => (
                                                        <li className={page == number ? "active page-item" : "page-item"} key={i}>
                                                            <button className="page-link" onClick={() => onClickPage(number)}>
                                                                {number}
                                                            </button>
                                                        </li>
                                                    ))}
                                                <li className="page-item">
                                                    <button className={`page-link next ${page == pageNumbers.length ? "disabled" : ""}`} onClick={() => onClickNextPage()}>
                                                        &rsaquo;
                                                    </button>
                                                </li>
                                                <li className="page-item" onClick={() => onClickDoubbleArrows("&raquo;")}><span className={`page-link next ${page == pageNumbers.length ? "disabled" : ""}`}>&raquo;</span></li>
                                            </ul>
                                        )}
                                    </nav>
                                )
                            }

                        </div>}
                </div>
            }

        </div>
    )
}