import React, { useEffect, useState } from "react";
import { ADD_UPDATE_SUBSCRIPTION_PRICE, GET_SUBSCRIPTION_PRICE_BY_USER } from "../../app/service/subscription.service";
import { toast } from "react-toastify";
import { GET_USER_USER_CODE } from "../../app/service/users.service";
import moment from "moment";
import { CREATE_PAYMENT } from "../../app/service/payment-service";
import { CREATE_CCAVENUE_PAYMENT } from "../../app/service/ccavenue.service";
import { APPLY_COUPON_CODE, UPDATE_APPLIED_COUPON_CODE } from "../../app/service/promotions";
import { useHistory, useLocation } from "react-router-dom";
import { GET_SUBSCRIPTION_BY_USER_CODES } from "../../app/service/user-subscription";
import { format } from 'date-fns';

interface PaymetProps {
    UserCode: any;
    redirectUrl: any;
    cancelUrl: any;
    naviGateUrl?: any
}

const UserPaymentsSubscription: React.FC<PaymetProps> = (props: PaymetProps) => {
    const [loading, setLoading] = useState(false);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [moduleInfo, setModuleInfo] = useState<any>({});
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [couponData, setCouponData] = useState<any>({});
    const [couponCodeValue, setCouponCodeValue] = useState<any>("");
    const [couponLoading, setCouponLoading] = useState(false);
    const [applyDiscount, setApplyDiscount] = useState<boolean>(false);
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [showModules, setShowModules] = useState<boolean>(true);
    const [selectedYears, setSelectedYears] = useState<number>(1);
    const [subscriptionAmount, setSubscriptionAmount] = useState<any>();
    const [actualSubscriptionAmount, setActualSubscriptionAmount] = useState<any>();
    const [disabledYears, setDisabledYears] = useState<boolean>(false);
    const [couponAmount, setCouponAmount] = useState<any>();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const coupon = searchParams.get('coupon');
    const years = searchParams.get('years');
    const [subscription, setSubscription] = useState<any>({});

    useEffect(() => {
        const isUserLoggedIn: string = "true";
        sessionStorage.setItem('userLoggedIn', isUserLoggedIn);
        const hashUrl = window.location.hash;
        const paymentUrl = hashUrl.startsWith("#") ? hashUrl.substring(1) : hashUrl;
        sessionStorage.setItem('user_payment_path', paymentUrl);

        // ccavenue //
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const selectedYears = JSON.parse(localStorage.getItem('cc_user_payment_years') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const couponsData = JSON.parse(localStorage.getItem("cc_coupons_data") || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, selectedYears, studentInfo, couponsData);
                } else {
                    removeItemsFromeLocalStorage();
                    fetchAllData();
                }
            } else {
                fetchAllData();
            }
        } else {

        }
        fetchAllData();

    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            const userDetails = await getUserDetails();
            await getPaymentDetailsOfUser(userDetails);
            await getSubscriptionDetails(props?.UserCode);
            setLoading(false);
            paymentLinkData();


        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };
    const paymentLinkData = () => {
        if (coupon && years) {
            setCouponCodeValue(coupon);
            applyCoupon();
        }

    }
    const getUserDetails = async () => {
        try {
            const response: any = await GET_USER_USER_CODE(props?.UserCode);
            if (response.status === 'fail') {
                // toast.error(response.message);
                // throw new Error(response.message);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                sessionStorage.removeItem('userLoggedIn');
                return data;
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            // throw error;
        }
    };
    const getPaymentDetailsOfUser = async (studentInfo: any, discountApplied?: any) => {
        try {
            const payLoad = {
                user_code: studentInfo?.user_code,
                discount_applied: discountApplied ? discountApplied : false
            }
            const res: any = await GET_SUBSCRIPTION_PRICE_BY_USER(payLoad);
            if (res.status === "fail") {
                toast.error(res?.message);
            } else {
                if (res) {
                    let data = res;
                    const preActualSubscriptionValue: number = years ? parseInt(data?.actual_subscription_price) * parseInt(years) : data.actual_subscription_price;
                    const preSubscriptionValue: number = years ? parseInt(data?.subscription_price) * parseInt(years) : data.subscription_price;
                    setActualSubscriptionAmount(preActualSubscriptionValue);
                    setSubscriptionAmount(preSubscriptionValue);
                    if (discountApplied) {
                        data.subscription_price = years ? parseInt(data?.subscription_price) * parseInt(years) : parseInt(data?.subscription_price) * selectedYears;
                        data.actual_subscription_price = years ? parseInt(data?.actual_subscription_price) * parseInt(years) : parseInt(data?.actual_subscription_price) * selectedYears;
                        setApplyDiscount(true);
                        setCouponCodeValue("");
                    }
                    if (coupon && years) {
                        setDisabledYears(true);
                        const selectedYear = parseInt(years);
                        setSelectedYears(selectedYear);
                        data.subscription_price = preSubscriptionValue;
                        data.actual_subscription_price = preActualSubscriptionValue;
                    }
                    setPaymentInfo(data);
                }
            }
        } catch (error) {

        }
    }
    const getSubscriptionDetails = async (userCode: any) => {
        try {
            const response: any = await GET_SUBSCRIPTION_BY_USER_CODES(userCode);
            if (response.status === 'fail') {
                toast.error(response.message);
                throw new Error(response.message);
            } else {
                const data = response?.data[0];
                if (new Date(data?.subscription_to) < new Date()) {
                }
                setSubscription(data);
            }
        } catch (error) {
            console.error('Error fetching subscription details:', error);
            throw error;
        }
    };
    const handlePayment = () => {
        localStorage.setItem("cc_student_info", JSON.stringify(studentInfo));
        createPayment(paymentInfo);

    }
    function createPayment(paymentInfo: any) {
        const payload = {
            school_code: studentInfo?.institute_school_code,
            user_ids: paymentInfo?.user_code,
            sub_amount: couponData?.final_amount ? Math.round(couponData?.final_amount) : paymentInfo?.subscription_price
        }
        // if (parseInt(paymentInfo?.subscription_price) > 0) {
        CREATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (couponData?.final_amount == 0 && Object.keys(couponData?.rule_applied).length > 0 || (payload?.sub_amount == 0)) {
                        updateSubscription(res.txn_id, { "message": "SUM AMOUNT ZERO" }, paymentInfo, "success", selectedYears, studentInfo, couponData);
                    } else {
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            }
        });
    }

    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_years", JSON.stringify(selectedYears));
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)
        createCcavenuePayment(amount, txn_id);
    }


    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, selectedYears: any, studentInfo?: any, couponData?: any) {
        // if (paymentInfo?.subscription_payment_status === 'pending') {
        const payload = {
            data: [
                {
                    "uuid": studentInfo?.subscription_details?.length >= 1 ? studentInfo?.subscription_details[studentInfo?.subscription_details?.length - 1]?.uuid : "",
                    "school_code": studentInfo?.institute_school_code,
                    "user_code": studentInfo?.user_code,
                    "transaction_id": transactionId,
                    "amount": (couponData?.final_amount == 0 || couponData?.final_amount) ? couponData?.final_amount : parseInt(paymentInfo?.subscription_price),
                    // "transaction_details": JSON.stringify(transactionData),
                    "transaction_details": "",
                    "subscription_years": parseInt(selectedYears),
                    "payment_status": "completed"
                }
            ]
        }
        if (paymentStatus === "success") {
            ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                } else {
                    // getAssignModule(paymentInfo, moduleInfo, studentInfo);
                    toast.success(res?.message);
                    if (Object.keys(couponData).length > 0) {
                        updateAppliedCoupon(transactionId, couponData);
                    } else {
                        removeItemsFromeLocalStorage();
                        console.log(props?.naviGateUrl, "props?.naviGateUrl-subscrption");

                        history.push(props?.naviGateUrl);
                        // fetchAllData();
                    }

                }
            });
        } else {
            removeItemsFromeLocalStorage();
            fetchAllData();
        }
    }

    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: props?.redirectUrl,
            cancel_url: props?.cancelUrl,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function onApplyCouponValue(e: any) {
        if (e) {
            setCouponCodeValue(e.target.value);
        } else {
            setCouponData({});
            setCouponCodeValue("");
        }

    }
    function applyCoupon() {
        const payload = {
            promo_code: couponCodeValue?.replace(/\s/g, ''),
            students_count: 1,
            payable_amount: parseInt(subscriptionAmount),
            institute_code: studentInfo?.organization_code,
            school_code: studentInfo?.institute_school_code
        }
        if (payload?.promo_code) {
            setCouponData({});
            setCouponLoading(true);
            APPLY_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setCouponLoading(false);
                } else {
                    let data = res;
                    if (Object.keys(data.rule_applied).length > 0) {
                        // data.final_amount = parseInt(data?.final_amount) * selectedYears;
                        localStorage.setItem("cc_coupons_data", JSON.stringify(data));
                        data.coupon_code = payload?.promo_code;
                        setCouponAmount(data?.final_amount);
                        setSelectedYears(1);
                        setCouponData(data);
                        let updatedPaymentInfo = { ...paymentInfo };
                        updatedPaymentInfo.actual_subscription_price = parseInt(actualSubscriptionAmount)
                        updatedPaymentInfo.subscription_price = parseInt(subscriptionAmount);
                        setPaymentInfo(updatedPaymentInfo);
                    }
                    setCouponLoading(false);
                }
            });
        }

    }
    function updateAppliedCoupon(txnId: any, couponData?: any) {
        const payload = {
            promotion_uuid: couponData?.promotion_uuid ? couponData?.promotion_uuid : "",
            promo_code_uuid: couponData?.promocode_uuid ? couponData?.promocode_uuid : "",
            applied_for_user_code: props?.UserCode,
            applied_sponsor_uuid: "",
            applied_dt: todayDate,
            applied_rules: couponData,
            status: 2
        }
        if ((couponData?.final_amount > 0 || couponData?.final_amount == 0) && Object.keys(couponData?.rule_applied).length > 0) {
            setLoading(true);
            UPDATE_APPLIED_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    removeItemsFromeLocalStorage();
                    console.log(props?.naviGateUrl, "props?.naviGateUrl");
                    history.push(props?.naviGateUrl);
                    // fetchAllData();
                    setLoading(false);

                }
            });
        }
    }
    const handleApplyDiscount = () => {
        getPaymentDetailsOfUser(studentInfo, true);
    }
    const removeCouponData = (removeType?: string) => {
        setApplyDiscount(false);
        setCouponData({});
        setCouponCodeValue("");
        localStorage.removeItem("cc_coupons_data");
        // if (removeType === "discount") {
        setSelectedYears(1);
        getPaymentDetailsOfUser(studentInfo);
        // }
        // handleYearChange("", selectedYears);
    }
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAcceptTerms(event.target.checked);
        // props?.setAcceptTerms(event.target.checked);
    };

    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_coupons_data', "cc_user_payment_years"];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    const capitalizeWords = (name: string): string => {
        return name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };
    const getDiscountStatus = () => {
        if (paymentInfo?.discount_applied === "true" || (couponData?.final_amount == 0 || couponData?.final_amount)) {
            return true
        } else {
            return false
        }
    }
    const handleYearChange = (value: any) => {
        const selectedYear = parseInt(value);
        setSelectedYears(selectedYear);
        let updatedPaymentInfo = { ...paymentInfo };
        if (Object.keys(couponData).length > 0) {
            // const updatedCouponData = { ...couponData };
            // updatedCouponData.final_amount = parseInt(couponAmount) * selectedYear;
            // setCouponData(updatedCouponData);

            setCouponData((prevCouponData: any) => ({
                ...prevCouponData,
                final_amount: parseFloat(couponAmount) * selectedYear
            }));
        }
        updatedPaymentInfo.actual_subscription_price = parseInt(actualSubscriptionAmount) * selectedYear
        updatedPaymentInfo.subscription_price = parseInt(subscriptionAmount) * selectedYear;
        setPaymentInfo(updatedPaymentInfo);
    };
    const formatDates = (dateString: string): any => {
        const date = new Date(dateString);
        const formattedDate = format(date, "dd-MMM-yyyy"); // Format date component
        return (
            <span>{formattedDate}</span>
        );
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {
                !loading &&
                <div className="row justify-content-center mb-2">
                    <div className="col-12 col-md-9 col-lg-7 border border-2 pb-3 px-0 rounded-3">
                        <div className="std_payment_bx p-3 rounded-3">
                            <div className=" border-bottom border-3 pb-1 d-sm-flex justify-content-sm-between">
                                <div>
                                    <h4 className="mb-1 f18">{studentInfo?.name && capitalizeWords(studentInfo?.name)}
                                        {subscription?.subscription_to && <span className="f14 text-inprogess ms-2">( Mem exp : {subscription?.subscription_to && formatDates(subscription?.subscription_to)} )</span>}

                                    </h4>
                                    <h5 className="mb-1 f16">Class - {studentInfo?.standard}</h5>
                                </div>
                                {paymentInfo?.no_of_years > 0 && <div>
                                    <label className='fw-bold'>Membership years: </label>
                                    <select className=" ms-1 payment_year-select " value={selectedYears} onChange={(e) => handleYearChange(e.target.value)} disabled={disabledYears}>
                                        {Array.from({
                                            length: paymentInfo?.no_of_years
                                        }, (_, i) => i + 1).map((year: number) => (
                                            <option value={year} key={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>}
                            </div>
                            <div className="px-2  py_bx border-2 ">
                                <div className="mt-3 mx-5  ">
                                    <div className={`d-flex justify-content-between ${showModules && " py_bx border-2 "} mb-2`}>
                                        <h5 className="f18 position-relative"><span className={`expand_icon cursor-pointer  ${showModules ? "" : "px-1 f16"}`} onClick={() => setShowModules(!showModules)}>{!showModules ? "+" : <span>&#x2015;</span>}</span>Membership</h5>
                                        <h5 className="f18"> {getDiscountStatus() && <del className="me-3">&#x20b9;{paymentInfo?.actual_subscription_price}</del>} <span className={`${getDiscountStatus() && "py_grn_cls"}`}>&#x20b9;{(couponData?.final_amount == 0 || couponData?.final_amount) ? couponData?.final_amount : paymentInfo?.subscription_price}</span></h5>
                                    </div>
                                    {showModules && paymentInfo?.modules?.map((data: any, i: number) => (
                                        <div className="d-flex justify-content-between " key={i}>
                                            {/* <h5 className="f16 fw-normal"> */}
                                            {/* <input className="me-2 mt-1 cursor-pointer module_checkbox" id="checked_module" type="checkbox" checked /> */}
                                            {/* {data?.module_code}</h5> */}
                                            <ul className="payment_points">
                                                <li>{data?.module_code}</li>
                                            </ul>
                                            <h5 className="f16 fw-normal py_grn_cls">Included</h5>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {parseInt(paymentInfo?.discount) > 0 && <div className="d-flex justify-content-center">
                                <div className="d-flex mt-3 me-2">
                                    {applyDiscount && <h5 className="f16 apl_text_cl d-inline-block mt-1">{paymentInfo?.discount}%  discount applied successfully!! </h5>}
                                    {!applyDiscount && <h5 className="f16 apl_text_cl d-inline-block mt-1">You can apply for an Instant discount of {paymentInfo?.discount}%</h5>}
                                    <div>
                                        <button className="btn btn-success px-4 py-2 ms-3 f14 fw-bold" onClick={handleApplyDiscount} disabled={Object.keys(couponData).length > 0 || applyDiscount}>Apply</button>
                                        {applyDiscount && <button type="button" className="btn btn-success cursor-pointer   px-3 py-2 ms-2" onClick={() => removeCouponData("discount")}>Remove</button>}
                                    </div>
                                </div>
                            </div>}
                            {parseInt(paymentInfo?.discount) > 0 && <p className="f16 text-center mb-0">OR</p>}
                            {parseInt(paymentInfo?.actual_subscription_price) > 0 &&
                                <div className={`row justify-content-center mt-4`} >
                                    <div className={`col-lg-${Object.keys(couponData).length > 0 ? "6" : "7"} col-md-10 col-11 d-flex position-relative`}>
                                        <input className='form-control form-control-lg f14 ' placeholder=' Coupon Code' type='text' value={couponCodeValue} onChange={(e) => onApplyCouponValue(e)} disabled={applyDiscount || couponData?.final_amount >= 0}></input>
                                        {couponLoading &&
                                            <div className="text-center mt-1 position-absolute" style={{ right: "109px" }}>
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only ">Loading...</span>
                                                </div>
                                            </div>
                                        }
                                        <button className="btn appl_cpn cursor-pointer pt-1 ms-2 px-4 py-1 f14 fw-bold text-white" onClick={applyCoupon} disabled={applyDiscount || couponData?.final_amount >= 0}>
                                            Apply</button>
                                        {Object.keys(couponData).length > 0 && <button type="button" className="btn btn-success cursor-pointer  px-3 py-1 ms-2" onClick={() => removeCouponData("coupon")}>Remove</button>}
                                    </div>
                                </div>
                            }
                            <div>
                                {paymentInfo?.notes?.length > 0 && <>
                                    <h5 className="mb-1 f16 mt-1">Notes</h5>
                                    <ol className="ps-3">
                                        {paymentInfo?.notes?.map((item: string, index: number) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ol>
                                </>}
                                <div className="d-flex mt-2">
                                    <input className="mt-1 cursor-pointer" type="checkbox" checked={acceptTerms} style={{ height: "16px", width: "16px" }} onChange={handleCheckboxChange} ></input><sup className="text-danger f18 mt-3">*</sup> <p className="mb-1 ms-2 f16 text-primary">Accept Terms & Conditions</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            {(Object.keys(paymentInfo).length > 0) && <button type="button" className="btn btn-primary cursor-pointer px-4 py-2 f18" onClick={handlePayment} disabled={!acceptTerms}>{((parseInt(paymentInfo?.subscription_price)) > 0 && couponData?.final_amount !== 0) ? 'Pay Now' : 'Assign'}</button>}
                        </div>
                    </div>
                </div>}
        </div>
    )
}
export default UserPaymentsSubscription;