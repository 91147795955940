import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import UserPaymentsSubscription from "../../../components/payments/user";
import { UI_BASE_URL } from "../../../config/constant";
import { Breadcum } from "../../../components/breadcum";


const IndividualStudentPaymentView: React.FC = () => {
    let { id, userCode, schoolName } = useParams<{ id: string, userCode: string, schoolName: string }>();
    const schoolId = parseInt(id);
    const usersCode = userCode;
    const schoolCode = schoolName;
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Individual Students',
            value: 'Individual Students',
            routerLink: '/dashboard/individual-student/list',
            isActive: false
        },
        {
            label: 'Payment',
            value: "Payment",
            routerLink: `/dashboard/individual-student/info/${schoolId}/paymentlist/${usersCode}/${schoolCode}`,
            isActive: false
        },
        {
            label: 'Membership',
            value: "Annual Membership Amount",
            routerLink: ``,
            isActive: true
        }
    ]);

    return (
        <div className="mt-3">
            <Breadcum breadcumList={breadcumInfo} />
            <div className="mt-2 mx-3 mx-md-0">
                <UserPaymentsSubscription UserCode={userCode} redirectUrl={`${UI_BASE_URL}/dashboard/individual-student/info/${schoolId}/payment/${usersCode}/${schoolCode}`} cancelUrl={`${UI_BASE_URL}/dashboard/individual-student/info/${schoolId}/payment/${usersCode}/${schoolCode}`} naviGateUrl={`/dashboard/individual-student/info/${schoolId}/paymentlist/${usersCode}/${schoolCode}`} />
            </div>
        </div>
    )

}
export default IndividualStudentPaymentView;