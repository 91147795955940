import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import { SchoolListItem } from '../../../app/model/school/school-list';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { DELETE_SCHOOL, GET_FTM_SCHOOLS_BY_SCHOOL_CODE, GET_SCHOOL, GET_SCHOOL_BY_ID, GET_SCHOOL_BY_SCHOOL_CODE, GET_School_Filter, GET_SCHOOL_INSTITUTE_ID } from '../../../app/service/school.service';
import { DataTable } from '../../../components/data-table'
import FormBuilder from '../../../components/form-builder';
import { SchoolDataGridCols } from './data-grid-cols';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from "react-datepicker";
import appConfig from '../../../config/constant';
import { MultiSelect } from 'react-multi-select-component';
import { DateComponent } from '../../../components/date';

export const SchoolList = () => {
    const [schoolData, setSchoolData] = useState<SchoolListItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [cityData, setCityData] = useState<any>([]);
    const [statesData, setStatesData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>({});
    const schoolForm = useRef<any>({});
    const [userRole, setUserRole] = useState<any>();
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    const [canShowSchoolPopup, setCanShowSchoolPopup] = useState(false);
    const [registrationDate, setRegistrationDate] = useState(moment().format('YYYY-MM-DD'));
    const [schoolLogo, setSchoolLogo] = useState<any>();
    const [mediumSelected, setMediumSelected] = useState([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [accessNew, setAccessNew] = useState<any>({});

    const filteredData = [
        { name: 'UDISE Number', value: 'udise_code' },
        { name: 'Email ID', value: 'email_id' },
        { name: 'Contact Number', value: 'contact_number' },
        { name: 'Category', value: 'category' },
        { name: 'Curriculum', value: 'curriculum' },
        { name: 'Date of Registration', value: 'date_of_registration' },
        { name: 'Taluka', value: 'taluka' },
        // { name: 'Pan Number', value: 'pan_number' },
        { name: 'Address', value: 'address' },
        { name: 'Website Url', value: 'website_url' },
        // { name: 'District', value: 'district' },
        { name: 'Pincode', value: 'pin_code' },
    ];

    const mediumType = [
        { label: "Assamese", value: "Assamese" },
        { label: "Bengali", value: "Bengali" },
        { label: "Bodo", value: "Bodo" },
        { label: "Dogri", value: "Dogri" },
        { label: "English", value: "English" },
        { label: "Gujarati ", value: "Gujarati" },
        { label: "Hindi", value: "Hindi" },
        { label: "Kannada", value: "Kannada" },
        { label: "Kashmiri", value: "Kashmiri" },
        { label: "Konkani", value: "Konkani" },
        { label: "Maithili", value: "Maithili" },
        { label: "Malayalam", value: "Malayalam" },
        { label: "Manipuri ", value: "Manipuri" },
        { label: "Marathi", value: "Marathi" },
        { label: "Nepali", value: "Nepali" },
        { label: "Odia", value: "Odia" },
        { label: "Punjabi", value: "Punjabi" },
        { label: "Sanskrit", value: "Sanskrit" },
        { label: "Santali", value: "Santali" },
        { label: "Sindhi ", value: "Sindhi" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu ", value: "Telugu" },
        { label: "Urdu", value: "Urdu" }
    ];
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page')
    const history = useHistory();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);

    useEffect(() => {
        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setStatesData(res.states);
            }

        });

        const userRole = localStorage.getItem("user_role");
        const institutecCode = localStorage.getItem("institute_code");
        const ftmSchoolCodes = localStorage.getItem("FTM_school_codes");
        getSchools(userRole, institutecCode, ftmSchoolCodes);
        setUserRole(userRole);
        getActionsInfo(userRole);
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleSchoolsCols(JSON.parse(accessObjectsData));
    }, []);


    function getActionsInfo(userRole: any) {
        for (let i = 0; i < SchoolDataGridCols.length; i++) {
            if (SchoolDataGridCols[i].title === "Actions") {
                if (userRole === 'INSTITUTE_ADMIN') {
                    SchoolDataGridCols[i].control = "one";
                }
            }
        }
    }


    function getSchools(userRole: any, institutecCode: any, ftmSchoolCodes: any) {
        let activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        if (userRole === 'CHAIRMAN' || userRole === 'GENERAL_SECRETARY' || userRole === 'INSTITUTE_ADMIN') {
            if (institutecCode) {
                setLoading(true);
                GET_SCHOOL_INSTITUTE_ID(institutecCode, activePageNumber, {}).then((res: any) => {
                    if (res) {
                        if (res.status === 'fail') {
                            toast.error(res.message);
                            setLoading(false);
                        } else {
                            setActivePage(currentPageNumber ? currentPageNumber : 1);
                            const data = res['rows'];
                            data.forEach((element: any) => {
                                element.created_at = element.created_at.split("T")[0];
                                element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                            });
                            setSchoolData(data);
                            const pageNumbers = Math.ceil(res.count / 10);
                            const pageArray: any = [];
                            for (let i = 1; i <= pageNumbers; i++) {
                                pageArray.push(i);
                            }
                            setPageNumbers(pageArray);
                            setLoading(false);
                        }
                    }
                });
            }

        } else if (userRole === 'FULL_TIME_MENTOR') {
            if (ftmSchoolCodes) {
                setLoading(true);
                const preparedData = [];
                const data = JSON.parse(ftmSchoolCodes);
                for (let i = 0; i < data.length; i++) {
                    preparedData.push(data[i].value);
                }

                GET_FTM_SCHOOLS_BY_SCHOOL_CODE(preparedData.toString(), activePageNumber, {}).then((res: any) => {
                    if (res) {
                        if (res.status === 'fail') {
                            toast.error(res.message);
                            setLoading(false);
                        } else {
                            setActivePage(currentPageNumber ? currentPageNumber : 1);
                            const data = res['rows'];
                            data.forEach((element: any) => {
                                element.created_at = element.created_at.split("T")[0];
                                element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                            });
                            setSchoolData(data);
                            const pageNumbers = Math.ceil(res.count / 10);
                            const pageArray: any = [];
                            for (let i = 1; i <= pageNumbers; i++) {
                                pageArray.push(i);
                            }
                            setPageNumbers(pageArray);
                            setLoading(false);
                        }
                    }
                });
            }

        } else {
            setLoading(true);
            GET_SCHOOL(activePageNumber, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res['rows'];
                        data.forEach((element: any) => {
                            element.created_at = element.created_at.split("T")[0];
                            element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                        });
                        setSchoolData(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }
    }

    const onSearchText = (data: any) => {
        if (data.value.search) {
            history.push(`/dashboard/school?search=${data.value.search}`);
        }
    };

    const onPageChange = (data: any) => {
        if (data) {
            if (userRole === 'CHAIRMAN' || userRole === 'GENERAL_SECRETARY' || userRole === 'INSTITUTE_ADMIN') {
                history.push(`/institute-dashboard/school/list?page=${data}`);
            } else {
                history.push(`/dashboard/school/list?page=${data}`);
            }
        }
        setActivePage(data);
        const pageNumber = data - 1;
        const institutecCode = localStorage.getItem("institute_code");

        if (userRole === 'CHAIRMAN' || userRole === 'GENERAL_SECRETARY' || userRole === 'INSTITUTE_ADMIN') {

            if (institutecCode) {
                setLoading(true);
                GET_SCHOOL_INSTITUTE_ID(institutecCode, pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
                    if (res) {
                        if (res.status === 'fail') {
                            toast.error(res.message);
                            setLoading(false);
                        } else {
                            const data = res.rows;
                            data.forEach((element: any) => {
                                element.created_at = element.created_at.split("T")[0];
                                element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                            });
                            setSchoolData(data);
                            setLoading(false);
                        }
                    }
                });
            }

        } else {
            setLoading(true);
            GET_SCHOOL(pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.created_at = element.created_at.split("T")[0];
                            element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                        });
                        setSchoolData(data);
                        setLoading(false);
                    }
                }
            });
        }
    }

    const onEditSchool = (data: any) => {
        if (data) {
            history.push(`/dashboard/school/form/${data.id}`);
        }
        if (data && (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN")) {
            history.push(`/institute-dashboard/school/form/${data.id}`);
        }
    }

    const onDeleteSchool = (data: any) => {
        const userRole = localStorage.getItem("user_role");
        const institutecCode = localStorage.getItem("institute_code");
        const ftmSchoolCodes = localStorage.getItem("FTM_school_codes");
        setLoading(true);
        const id = data.id;
        DELETE_SCHOOL(id).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("School Deleted Successfully");
                getSchools(userRole, institutecCode, ftmSchoolCodes);
            }
        });
    }

    const handleStateChange = (e: any) => {
        if (e.target.value) {
            const preparedData = statesData.filter((el: any) => el.state === e.target.value);
            setDistrictData(preparedData[0].districts);
        }
    }

    const handleDistrictChange = (e: any) => {
    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };

    function handleSearch() {
        const data = { ...searchData.value };
        if (data.searchFilter === 'udise_code') {
            data.udise_code = data.search_name;
        } else if (data.searchFilter === 'contact_number') {
            data.contact_number = data.search_name;
        } else if (data.searchFilter === 'category') {
            data.category = data.search_name;
        } else if (data.searchFilter === 'taluka') {
            data.taluka = data.search_name;
        } else if (data.searchFilter === 'curriculum') {
            data.curriculum = data.search_name;
        } else if (data.searchFilter === 'email_id') {
            data.email_id = data.search_name;
        } else if (data.searchFilter === 'date_of_registration') {
            // data.date_of_registration = data.search_name;
            const date_of_registration = data.search_name;
            data.date_of_registration = getPreapareDate(date_of_registration);
        }

        else if (data.searchFilter === 'address') {
            data.address = data.search_name;
        } else if (data.searchFilter === 'website_url') {
            data.website_url = data.search_name;
        }

        else if (data.searchFilter === 'pin_code') {
            data.pin_code = data.search_name;
        }
        delete data.search_name;
        delete data.searchFilter;
        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
                history.push(`/institute-dashboard/school/list?page=${1}`);
            } else {
                history.push(`/dashboard/school/list?page=${1}`);
            }
            setActivePage(1);
            GET_School_Filter(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setSchoolData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }

    function getPreapareDate(data: any) {
        if (data) {
            const preparedDate = data.split('-');
            const date = preparedDate[0];
            const month = preparedDate[1];
            const year = preparedDate[2];
            return year + '-' + month + '-' + date + 'T00:00:00Z';
        }
    }

    function handleRefresh() {
        const userRole = localStorage.getItem("user_role");
        const institutecCode = localStorage.getItem("institute_code");
        const ftmSchoolCodes = localStorage.getItem("FTM_school_codes");
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getSchools(userRole, institutecCode, ftmSchoolCodes);
        schoolForm.current.reset();
    }

    const handleFilterChange = (e: any) => {
        const data = { ...searchData.value };
        setSearchData(data);
    }

    const getSchoolDetails = (data: any) => {
        setCanShowSchoolPopup(true);
        setSchoolInfo(data);
        if (data.logo_url) {
            setSchoolLogo(data.logo_url);
        }
        if (data.medium) {
            setMediumSelected(data.medium);
        }
        if (data.date_of_registration) {

            const date_of_registration: any = new Date(data.date_of_registration);
            setRegistrationDate(date_of_registration);
        }
    }

    const handleSchoolClose = () => {
        setCanShowSchoolPopup(false);
    }

    const handleSchoolsCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "SCHOOLS");
        setAccessNew(preparedData);
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < SchoolDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "SCHOOLS-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (SchoolDataGridCols[j].title == "Actions") {
                            SchoolDataGridCols[j].isEdit = true;
                        }

                    }

                    if (preparedData[0].child[i].object_code === "SCHOOLS-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (SchoolDataGridCols[j].title == "Actions") {
                            SchoolDataGridCols[j].isDelete = true;
                        }
                    }
                    if (preparedData[0].child[i].object_code === "SCHOOLS-VIEW" && preparedData[0].child[i].p_read === 1) {
                        if (SchoolDataGridCols[j].title == "Actions") {
                            SchoolDataGridCols[j].isView = true;
                        }
                    }

                    if ((preparedData[0].child[i].object_code === "SCHOOLS-VIEW" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-DELETE" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-EDIT" && preparedData[0].child[i].p_read === 1)) {
                        if (SchoolDataGridCols[j].title == "Actions") {

                            SchoolDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }

    }

    function onHandleNew() {
        if (accessNew?.length > 0) {
            for (let i = 0; i < accessNew[0].child.length; i++) {
                if (accessNew[0].child[i].object_code === "SCHOOLS-NEW" && accessNew[0].child[i].p_read === 1) {
                    return true;
                }
            }
        }
    }

    return (
        <div>
            <div className={`modal fade ${canShowSchoolPopup ? 'show' : 'false'}`} style={{ display: (canShowSchoolPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">School  Details</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleSchoolClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info">
                            <div className="row custom-form">
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Name of Institute</label>
                                        <select name="organization_code" className="form-select form-select-lg mb-3" disabled  >
                                            <option value="">{schoolInfo?.organization_code}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Short form Name</label>
                                        <input className="form-control form-control-lg" type="text" name="short_name" disabled defaultValue={schoolInfo?.short_name} placeholder="Short form of name" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Name of School /College /Institution</label>
                                        <input className="form-control form-control-lg" type="text" name="name_of_school" disabled defaultValue={schoolInfo?.name_of_school} placeholder="Name of institution" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Address</label>
                                        <input className="form-control form-control-lg" type="text" name="address" disabled defaultValue={schoolInfo?.address} placeholder="Official address" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Contact Number ( Mobile / Landline)</label>
                                        <input className="form-control form-control-lg" type="number" name="contact_number" disabled defaultValue={schoolInfo?.contact_number} placeholder="Mobile/Landline" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Date of Registration</label>
                                        <DateComponent idDisable={true} defaultData={registrationDate} yearDiff={0}></DateComponent>


                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">State</label>
                                        <select name="state" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{schoolInfo?.state}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">District</label>
                                        <select name="district" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{schoolInfo?.district}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Block</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="taluka" defaultValue={schoolInfo?.taluka} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">City/Village (Optional)</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="city_village" defaultValue={schoolInfo?.city_village} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">PIN Code</label>
                                        <input className="form-control form-control-lg" type="number" disabled name="pin_code" defaultValue={schoolInfo?.pin_code} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Email ID</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="email_id" defaultValue={schoolInfo?.email_id} placeholder="Please enter email" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Fax Number</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="fax_number" defaultValue={schoolInfo?.fax_number} placeholder="Please enter fax number" />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">UDISE Code</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="udise_code" defaultValue={schoolInfo?.udise_code} placeholder="Please enter UDISE code" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">School Timings</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="timings" defaultValue={schoolInfo?.timings} placeholder="Please enter timings" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Cluster Code</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="cluster_code" defaultValue={schoolInfo?.cluster_code} placeholder="Please enter cluster code" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Curriculum </label>
                                        <select className="form-select form-select-lg mb-3" disabled name="curriculum">
                                            <option value="">{schoolInfo?.curriculum}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Category</label>
                                        <select className="form-select form-select-lg mb-3" disabled name="category">
                                            <option value="">{schoolInfo?.category}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Medium of Instruction</label>
                                        <MultiSelect
                                            disabled
                                            options={mediumType}
                                            value={mediumSelected}
                                            onChange={setMediumSelected}
                                            labelledBy="Select"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Registration Number (Optional)</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="registration_number" defaultValue={schoolInfo?.registration_number} placeholder="Please enter registration number" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Website Address (Optional)</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="website_url" defaultValue={schoolInfo?.website_url} placeholder="Please enter website" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">GST Number (Optional)</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="gst_number" defaultValue={schoolInfo?.gst_number} placeholder="Please enter GST number" />
                                    </div>
                                </div>
                                {schoolLogo && <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 d-block">Preview Logo</label>
                                        <img src={`${appConfig.FILE_UPLOAD_URL}${schoolLogo.charAt(0) === "/" ? schoolLogo.substring(1) : schoolLogo}`} style={{ width: '100px', height: '100px', objectFit: "contain" }} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleSchoolClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-top border-primary py-3">
                <div className="row">
                    <div className="col-md-10">
                        <h2>Schools</h2>
                    </div>
                    {onHandleNew() && <div className="border-primary py-3 text-end col-md-2">
                        <Link to="new" className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                    </div>}
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-10">
                        <FormBuilder onUpdate={handleInput}>
                            <form ref={schoolForm}>
                                <div className="row">
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <input type="text" id="name" className="form-control ft-14" placeholder="Search name" name="name_of_school" />
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="state" id="state" className="form-select ft-14" onChange={(e) => { handleStateChange(e) }}>
                                            <option value="">Select state</option>
                                            {statesData.map((name: any, i: number) => (
                                                <option key={i} value={name.state}>
                                                    {name.state}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="taluka" id="taluka" className="form-select ft-14" onChange={(e) => { handleDistrictChange(e) }}>
                                            <option value="">Select district</option>
                                            {districtData.map((name: any, i: number) => (
                                                <option key={i} value={name}>
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        {/* <input type="text" id="area" className="form-control ft-14" placeholder="Search location" name="area" /> */}
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <input type="text" className="form-control ft-14" placeholder="Custom search" name="search_name" />
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <select name="searchFilter" className="form-select ft-14" onChange={(e) => { handleFilterChange(e) }}>
                                            <option value="">Select filter</option>
                                            {filteredData.map((name: any, i: number) => (
                                                <option key={i} value={name.value}>
                                                    {name.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </FormBuilder>
                    </div>
                    <div className="col-md-2 mt-5">
                        <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                        <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                            Clear
                        </button>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <DataTable TableCols={SchoolDataGridCols} tableData={schoolData} searchText={onSearchText}
                    editInfo={onEditSchool} deleteInfo={onDeleteSchool} onChange={getSchoolDetails}
                    pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
            </div>
        </div >
    )
}
